import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import {
  IOfferItem,
  isOverHeartMealsProductsSelector,
  isOverProductsLimitSelector,
} from "@modules";
import { ProductItem, IImportationTypes } from "@atomic";
import { TestIDs } from "@utils";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { useDevices } from "@hooks";
import { OrderItem } from "@foodi/core";

interface IProps {
  familyItems: IOfferItem[];
  selectedOfferId?: string;
  selectedActiveOfferId?: string;
  familyName: string;
  updateOrders: Function;
  sameProductPerOrder: number;
  isHeartFamily: boolean;
  isBookingSelected?: boolean;
  selectedDay?: number;
  orderItems?: OrderItem[];
  menuType?: IImportationTypes;
}

const areEqual = (prevProps: IProps, nextProps: IProps) =>
  nextProps.isBookingSelected
    ? JSON.stringify(prevProps.familyItems) ===
      JSON.stringify(nextProps.familyItems)
    : prevProps.selectedActiveOfferId === nextProps.selectedActiveOfferId &&
      prevProps.selectedDay === nextProps.selectedDay &&
      prevProps.sameProductPerOrder === nextProps.sameProductPerOrder;

export const ProductsFamily: React.FC<IProps> = React.memo(
  ({
    isHeartFamily,
    familyItems,
    selectedOfferId,
    familyName,
    updateOrders,
    sameProductPerOrder,
    isBookingSelected,
    orderItems,
    menuType,
  }) => {
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const outOfStockItems = useSelector(
      (state: GlobalState) => state.offers?.outOfStockItems
    );
    const isOverProductsLimit = useSelector(isOverProductsLimitSelector);
    const isOverHeartMealsProducts = useSelector(
      isOverHeartMealsProductsSelector
    );
    const isMenu = menuType !== undefined;

    return (
      <View style={styles.groupList}>
        {familyItems.map((item: IOfferItem, index) => {
          const outOfStockItem = outOfStockItems?.some(
            (o) => o?.idOfferItem === item?.id
          );

          const orderItem = orderItems?.find?.(
            (o: OrderItem) => o?.id === item?.id
          );

          return (
            (isMenu || item.quantityRemaining > 0) && (
              <ProductItem
                forwardTestID={`${TestIDs.restaurantDetail.views.listProductItem}${familyName}_${index}`}
                key={item.id}
                item={item}
                style={styles.item}
                lastProduct={index + 1 === familyItems.length}
                selectedOfferId={selectedOfferId}
                updateOrders={updateOrders}
                outOfStockItem={outOfStockItem}
                sameProductPerOrder={sameProductPerOrder}
                isOverProductsLimit={
                  isOverProductsLimit ||
                  (isHeartFamily && isOverHeartMealsProducts)
                }
                hasQuantity={!isBookingSelected}
                orderItem={orderItem}
                menuType={menuType}
              />
            )
          );
        })}
      </View>
    );
  },
  areEqual
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    groupList: {
      flexDirection: isMobile ? "column" : "row",
      justifyContent: isMobile ? "center" : "space-between",
      maxWidth: 842,
      flexWrap: "wrap",
    },
    item: {
      marginTop: isMobile ? 0 : 12,
      marginRight: 12,
    },
  });
