import React, { useState, useMemo, useCallback, useEffect } from "react";
import { ScrollView, StyleSheet, TextInput, View } from "react-native";
import { unstable_batchedUpdates } from "react-dom";
import { Title13, Title16, Title22 } from "@stylesheets";
import { Button, FloatTextInput, HoldingRow } from "@atomic";
import { useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Colors, Spacing } from "@constants";
import { TestIDs } from "@utils";
import _ from "lodash";
import { Holding, HoldingView } from "@foodi/core";
import { useDevices } from "@hooks";
import { AccountThunks, HoldingThunks } from "@modules";

interface IProps {
  handleClose?: (update?: boolean) => void;
  currentHoldingView?: HoldingView;
}

export const HoldingViewModal: React.FC<IProps> = React.memo(
  ({ handleClose, currentHoldingView }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState<string | undefined>(
      currentHoldingView?.holding?.name
    );
    const [holdings, setHoldings] = useState<Holding[]>([]);
    const [isHoldingsListShown, setHoldingsListShown] = useState<boolean>(
      false
    );
    const [selectedHolding, setSelectedHolding] = useState<Holding | undefined>(
      currentHoldingView?.holding
    );
    const [guestLastName, setGuestLastName] = useState<string | undefined>(
      currentHoldingView?.guest?.lastName || ""
    );
    const [serialNumber, setSerialNumber] = useState<string | undefined>(
      currentHoldingView?.guest?.supportSerialNumber ||
        currentHoldingView?.guest?.serialNumber ||
        ""
    );
    const [aliasValue, setAliasValue] = useState<string | undefined>(
      currentHoldingView?.alias || ""
    );
    const [haveBadgeData, setHaveBadgeData] = useState<boolean>(
      !!currentHoldingView?.guest?.lastName &&
        !!currentHoldingView?.guest?.serialNumber
    );
    const [showError, setShowError] = useState<boolean>(false);
    const [isBadgeRequired, setBadgeRequired] = useState<boolean>(
      !!currentHoldingView?.holding?.isBadgeRequired
    );

    useEffect(() => {
      if (searchValue && searchValue.length > 1 && !selectedHolding)
        getNearHoldingsDebounce(searchValue);
      else setHoldings([]);
    }, [searchValue]);

    useEffect(() => {
      setHoldingsListShown(holdings.length > 0);
    }, [holdings]);

    const getNearHoldingsDebounce = useCallback(
      _.debounce(getNearHoldings, 500),
      []
    );

    async function getNearHoldings(searchText: string) {
      const { holdingInfo }: any = await dispatch(
        AccountThunks.getNearHoldings({
          searchText: `${searchText}%`,
          withDistance: false,
          order: "name",
        })
      );

      setHoldings(holdingInfo);
    }

    const onChangeAlias = (text: string) => {
      setAliasValue(text);
    };

    const onChangeHolding = (text: string) => {
      setSelectedHolding(undefined);
      setSearchValue(text);
      unstable_batchedUpdates(() => {
        setSelectedHolding(undefined);
        setSearchValue(text);
        setBadgeRequired(false);
        setHaveBadgeData(false);
        setSerialNumber("");
        setGuestLastName("");
      });
    };

    const onChangeBadgeNumber = (text: string) => {
      setShowError(false);
      if (text !== "" && guestLastName !== "") {
        setHaveBadgeData(true);
      } else {
        setHaveBadgeData(false);
      }
      setSerialNumber(text.trim());
    };

    const onChangeBadgeName = (text: string) => {
      setShowError(false);
      if (text !== "" && serialNumber !== "") {
        setHaveBadgeData(true);
      } else {
        setHaveBadgeData(false);
      }
      setGuestLastName(text);
    };

    const onSelectHolding = (_selectedHolding: Holding) => {
      unstable_batchedUpdates(() => {
        setSelectedHolding(_selectedHolding);
        setSearchValue(_selectedHolding.name?.trim() ?? "");
        setHoldingsListShown(false);
        setBadgeRequired(!!_selectedHolding?.isBadgeRequired);
      });
    };

    const handleHoldingView = async () => {
      setLoading(true);
      try {
        if (!!currentHoldingView) {
          await dispatch(
            HoldingThunks.updateUserHoldingView({
              alias: aliasValue,
              //@ts-ignore
              idHolding: selectedHolding?.id,
              guestLastName,
              serialNumber,
              idHoldingView: currentHoldingView?.id,
            })
          );
        } else {
          await dispatch(
            HoldingThunks.addUserHoldingView({
              alias: aliasValue,
              //@ts-ignore
              idHolding: selectedHolding?.id,
              guestLastName,
              serialNumber,
            })
          );
        }

        handleClose?.(true);
      } catch (e) {
        setShowError(true);
      } finally {
        setLoading(false);
      }
    };

    const checkBadgeInfo =
      (!serialNumber && !!guestLastName) || (!!serialNumber && !guestLastName);
    const buttonDisabled =
      checkBadgeInfo || !selectedHolding || showError || loading;

    return (
      <View testID={TestIDs.account.views.holdingViewModalContainer}>
        <View style={styles.container}>
          <View style={styles.topContainer}>
            <View style={styles.textContainer}>
              <Title22 isBlack>{I18n.t("account.alias")}</Title22>
            </View>
            <View style={styles.listContainer}>
              <View style={styles.textHoldingInputContainer}>
                <TextInput
                  testID={TestIDs.account.texts.aliasName}
                  style={styles.textInput}
                  onChangeText={onChangeAlias}
                  value={aliasValue}
                  placeholder={I18n.t("account.aliasPlaceholder")}
                />
              </View>
            </View>
          </View>
          <View style={styles.topContainer}>
            <View style={styles.textContainer}>
              <Title22 isBlack>{I18n.t("account.establishment")}*</Title22>
              <Title16>{I18n.t("account.consultServices")}</Title16>
            </View>
            <View style={styles.listContainer}>
              <View style={styles.textInputContainer1}>
                <FloatTextInput
                  forwardTestID={TestIDs.account.texts.holdingName}
                  value={searchValue || ""}
                  onChangeValue={(value) => onChangeHolding(value)}
                  textPlaceHolder={I18n.t("account.establishmentName")}
                  hasError={
                    searchValue === "" ||
                    (!!searchValue && selectedHolding === undefined)
                  }
                  isEdit={!!currentHoldingView?.holding?.name}
                  errorMessage={I18n.t("account.invalidHolding")}
                />
              </View>
              {isHoldingsListShown && (
                <ScrollView style={styles.holdingList}>
                  {holdings.map((holding, index) => (
                    <HoldingRow
                      forwardTestID={`${TestIDs.account.actions.holdingRow}_${index}`}
                      key={holding.id}
                      holding={holding}
                      onSelectHolding={onSelectHolding}
                    />
                  ))}
                </ScrollView>
              )}
            </View>
          </View>
          {isBadgeRequired ? (
            <View>
              <View style={styles.bottomContainer}>
                <View style={styles.textContainer}>
                  <Title22 isBlack>{I18n.t("account.badge")}</Title22>
                  <Title16>{I18n.t("account.refillsAndOrders")}</Title16>
                </View>
                <View>
                  <View style={styles.textInputContainer1}>
                    <FloatTextInput
                      forwardTestID={TestIDs.account.texts.badgeName}
                      value={guestLastName || ""}
                      onChangeValue={(value) => onChangeBadgeName(value)}
                      textPlaceHolder={I18n.t("account.badgeName")}
                      hasError={checkBadgeInfo || showError}
                      isEdit={!!currentHoldingView?.guest?.lastName}
                    />
                  </View>
                  <View style={styles.textInputContainer2}>
                    <FloatTextInput
                      forwardTestID={TestIDs.account.texts.badgeNumber}
                      errorForwardTestID={
                        TestIDs.account.views.badgeErrorMessage
                      }
                      value={serialNumber || ""}
                      onChangeValue={(value) => onChangeBadgeNumber(value)}
                      textPlaceHolder={I18n.t("account.badgeNumber")}
                      hasError={checkBadgeInfo || showError}
                      isEdit={
                        !!currentHoldingView?.guest?.supportSerialNumber ||
                        !!currentHoldingView?.guest?.serialNumber
                      }
                      errorMessage={I18n.t("account.wrongBadge")}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.bottomTextContainer}>
                <View style={styles.bottomRow}>
                  <Title13 style={styles.bottomText}>
                    {I18n.t("account.badgeAuthorization")}
                  </Title13>
                </View>
              </View>
            </View>
          ) : (
            <View style={styles.margin} />
          )}
        </View>
        <Button
          forwardTestID={TestIDs.account.actions.signUpWithBadge}
          onPress={handleHoldingView}
          disabled={buttonDisabled}
          label={I18n.t("refill.validate")}
          loading={loading}
          styleButton={styles.firstButton}
        />
        <Title13 style={styles.bottomText}>
          {I18n.t("account.requiredFields")}
        </Title13>
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      marginTop: 30,
      alignItems: "center",
      width: isMobile ? "100%" : "auto",
      zIndex: 10,
    },
    topContainer: {
      marginTop: 20,
      zIndex: 10,
    },
    bottomContainer: {
      marginTop: 20,
    },
    textContainer: {
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    bottomText: {
      color: Colors.foodiBlack,
      alignSelf: "center",
    },
    bottomTextContainer: {
      alignItems: "flex-start",
      marginBottom: 20,
      marginTop: 10,
    },
    bottomRow: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 15,
    },
    listContainer: {
      marginTop: 15,
      zIndex: 10,
      backgroundColor: Colors.white,
    },
    textHoldingInputContainer: {
      margin: 10,
      backgroundColor: Colors.white,
      height: 50,
      width: isMobile ? 320 : 350,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
      borderWidth: 1,
      borderColor: Colors.darkGrey,
    },
    textInputContainer1: {
      justifyContent: "center",
      alignItems: "center",
      zIndex: 15,
    },
    textInputContainer2: {
      justifyContent: "center",
      alignItems: "center",
    },
    textInput: {
      height: 50,
      width: isMobile ? 320 : 350,
      fontSize: 16,
      zIndex: 1,
      color: Colors.foodiBlack,
      fontWeight: "400",
      borderRadius: 8,
      paddingHorizontal: 17,
      paddingTop: 15,
      paddingBottom: 14,
    },
    holdingList: {
      backgroundColor: Colors.white,
      borderWidth: 1,
      borderColor: Colors.border1,
      borderRadius: 8,
      paddingVertical: Spacing.M,
      width: isMobile ? 320 : 350,
      alignSelf: "center",
      position: "absolute",
      top: 65,
      maxHeight: 260,
    },
    firstButton: {
      marginBottom: 10,
      alignSelf: "center",
    },
    margin: {
      marginTop: 16,
    },
  });
