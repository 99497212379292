import React from "react";
import { ViewStyle } from "react-native";

import { createIconSetFromIcoMoon } from "react-native-vector-icons";
import { IconMoon } from "@assets";

const IconSetFromIcoMoon = createIconSetFromIcoMoon(IconMoon);

export type IconName =
  | "Wheat"
  | "icon-Coming-next_white"
  | "Accordeon_arrow_default_white"
  | "Coming-next_white"
  | "warning_black_24dp"
  | "In-site"
  | "Take-away"
  | "Tout-Voir"
  | "Android_grey_24dp"
  | "Apple_grey_24dp"
  | "favorite_selected"
  | "favorite_unselected"
  | "Profile_black"
  | "Profile_white"
  | "Wallet_Balance_black"
  | "Wallet_Balance_white"
  | "Accordeon_arrow_open_white"
  | "Arrows_Right_White"
  | "check_circle_black_24dp"
  | "Arrow_carroussel_left_black"
  | "Arrow_carroussel_right_black"
  | "Profile_white"
  | "Container"
  | "Accordeon_arrow_open_black"
  | "Accordeon_arrow_default_black"
  | "Celery"
  | "Egg"
  | "Fish"
  | "Gluten"
  | "Lupin"
  | "Milk"
  | "Mollusc"
  | "Mustard"
  | "Nut"
  | "Peanut"
  | "Sesame"
  | "Sellfish"
  | "Soya"
  | "Sulfite"
  | "Language";

interface IconProps {
  name: IconName;
  color?: string;
  size?: number;
  style?: ViewStyle;
}

export const Icon: React.FC<IconProps> = React.memo(
  ({ name, color, size, style }) => (
    <IconSetFromIcoMoon style={style} name={name} size={size} color={color} />
  )
);

Icon.defaultProps = {
  color: "#000",
  size: 20,
};
