// @ts-nocheck
import {
  ICallback,
  SignUpCommand,
  SignUpResponse,
  Token,
  UserProfile,
} from "@foodi/core";
import { createReducer, ThunkResult } from "@redux";
import { Action } from "@redux/action";
import { displayToastError } from "@utils";
import jwtDecode from "jwt-decode";
import { getExpirationTokenInfoFromExp } from "@utils";
export interface AuthState {
  isLogin: boolean;
  authToken: Token | null;
  userInfo: UserProfile | null;
  isIDPConnexion: boolean;
}

const EXPIRATION_TIME_KEY = "exp";
/*************  Actions  ****************/

const ActionTypes = {
  SET_LOGIN_STATUS: "auth/SET_LOGIN_STATUS",
  LOGOUT_USER: "auth/LOGOUT_USER",
  SET_AUTH_TOKEN: "auth/SET_AUTH_TOKEN",
  SET_USER_INFO: "auth/SET_USER_INFO",
  SET_IDP_CONNEXION_STATUS: "auth/SET_IDP_CONNEXION_STATUS",
};

const ActionCreators = {
  setLoginStatus: (isLogin: boolean): Action<boolean> => ({
    type: ActionTypes.SET_LOGIN_STATUS,
    payload: isLogin,
  }),
  setAuthToken: (authToken: Token): Action<Token> => ({
    type: ActionTypes.SET_AUTH_TOKEN,
    payload: authToken,
  }),
  setUserInfo: (userInfo: UserProfile): Action<UserProfile> => ({
    type: ActionTypes.SET_USER_INFO,
    payload: userInfo,
  }),
  logOutUser: () => ({
    type: ActionTypes.LOGOUT_USER,
  }),
  setIDPConnexionStatus: (isIDPConnexion: boolean): Action<boolean> => ({
    type: ActionTypes.SET_IDP_CONNEXION_STATUS,
    payload: isIDPConnexion,
  }),
};

/*************  Side Effects, only if applicable ****************/
// e.g. thunks, epics, etc
const ThunkActionCreators = {
  initializeAuth0Client: (): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { initializeAuth0Client } = getDependencies();
    initializeAuth0Client.execute({
      config: {
        AUTH0_WEB_AUDIENCE: window.config.AUTH0_WEB_AUDIENCE,
        AUTH0_WEB_CLIENT_ID: window.config.AUTH0_WEB_CLIENT_ID,
        AUTH0_WEB_DOMAIN: window.config.AUTH0_WEB_DOMAIN,
        WEB_BASE_URL: window.config.WEB_BASE_URL,
        AUTH0_WEB_RESPONSE_TYPE: window.config.AUTH0_WEB_RESPONSE_TYPE,
      },
    });
  },
  login: (): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { login } = getDependencies();
    login.execute({
      config: {
        AUTH0_APP_PROMPT: window.config.AUTH0_APP_PROMPT,
        WEB_BASE_URL: window.config.WEB_BASE_URL,
      },
    });
  },
  retrieveTokens: (dispatchCallback: any): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { retrieveTokens } = getDependencies();
    retrieveTokens.execute({ dispatchCallback });
  },
  refreshToken: (): ThunkResult<Promise<Response>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { refreshToken } = getDependencies();
    return refreshToken.execute();
  },
  refreshIDPToken: (
    refreshToken: string
  ): ThunkResult<Promise<Response>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const response = await fetch(window.config.API_GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `mutation refreshToken($input: RefreshToken!) {
          refreshToken(input: $input) {
            accessToken
            refreshToken
          }
        }`,
        variables: { input: { refreshToken } },
      }),
    });
    const json = await response.json();
    return Promise.resolve(json);
  },
  getUserInfo: (callback?: ICallback): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getUserProfile } = getDependencies();
      const userInfo = await getUserProfile.execute(callback);
      dispatch(ActionCreators.setUserInfo(userInfo));
      return Promise.resolve(userInfo);
    } catch (e) {}
  },
  signUp: (
    params: SignUpCommand
  ): ThunkResult<Promise<SignUpResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { signUp } = getDependencies();
      return signUp.execute(params);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  loginSSO: (email: string): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { loginSSO } = getDependencies();
    loginSSO.execute({
      config: {
        AUTH0_APP_PROMPT: window.config.AUTH0_APP_PROMPT,
        WEB_BASE_URL: window.config.WEB_BASE_URL,
        login_hint: email,
      },
    });
  },
  loginIDP: (code: string): ThunkResult<Promise<any>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { loginIDP } = getDependencies();
      const response: Token = await loginIDP.execute({ code });
      const decodeToken = jwtDecode(response.accessToken) as Record<
        string,
        number
      >;
      const expirationTokenInfo = getExpirationTokenInfoFromExp(
        decodeToken[EXPIRATION_TIME_KEY]
      );
      dispatch(
        ActionCreators.setAuthToken({ ...expirationTokenInfo, ...response })
      );
      return Promise.resolve(response);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  deleteAccount: (userId: string): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { deleteAccount } = getDependencies();
    try {
      const response = await deleteAccount.execute({
        id: userId,
      });
      return Promise.resolve(response);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
};

/*************  Reducer  ****************/

const initialState: AuthState = {
  isLogin: false,
  authToken: null,
  userInfo: null,
  isIDPConnexion: false,
};

const Reduction = {
  setLoginStatus: (
    state: AuthState,
    { payload: isLogin }: Action<boolean>
  ): AuthState => ({
    ...state,
    isLogin,
  }),
  setAuthToken: (
    state: AuthState,
    { payload: authToken }: Action<Token>
  ): AuthState => ({
    ...state,
    authToken,
  }),
  setUserInfo: (
    state: AuthState,
    { payload: userInfo }: Action<UserProfile>
  ): AuthState => ({
    ...state,
    userInfo,
  }),
  logOutUser: (): AuthState => ({
    ...initialState,
  }),
  setIDPConnexionStatus: (
    state: AuthState,
    { payload: isIDPConnexion }: Action<boolean>
  ): AuthState => ({
    ...state,
    isIDPConnexion,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_LOGIN_STATUS]: Reduction.setLoginStatus,
  [ActionTypes.SET_AUTH_TOKEN]: Reduction.setAuthToken,
  [ActionTypes.SET_USER_INFO]: Reduction.setUserInfo,
  [ActionTypes.LOGOUT_USER]: Reduction.logOutUser,
  [ActionTypes.SET_IDP_CONNEXION_STATUS]: Reduction.setIDPConnexionStatus,
});

export default reducer;

export {
  reducer as AuthReducer,
  ActionTypes as AuthActionTypes,
  ActionCreators as AuthActions,
  ThunkActionCreators as AuthThunks,
};
