import { BlackBadge, WarningOrange } from "@assets";
import { RadioGroup } from "@atomic/molecules";
import { IPaymentTypes } from "@foodi/core";
import React from "react";
import { I18n } from "react-redux-i18n";
import {
  FlexDirectionRow,
  PaymentContainer,
  PaymentTextContainer,
  ProductDescription,
  SectionTitle,
  SmallDescription,
  WarningMessage,
  WarningMessageContainer,
} from "./Cart.style";
import { OnSiteMethodPayment } from "./OnSiteMethodPayment";
import { OtherCards } from "./OtherCards";
import { AddBadge } from "./AddBadge";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";

interface ICartPayment {
  paymentMethod: any;
  handleEdenredPayment: any;
  edenredBalance: string | undefined;
  setPaymentMethod: React.Dispatch<React.SetStateAction<string | undefined>>;
  balance: number;
  paymentTypes: IPaymentTypes;
  isOtherCardOpen: boolean;
  setIsOtherCardOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  handleQuotationValues: () => Promise<void>;
}
export const CartPayment = ({
  paymentMethod,
  handleEdenredPayment,
  edenredBalance,
  setPaymentMethod,
  balance,
  paymentTypes,
  setIsOtherCardOpen,
  isOtherCardOpen,
  loading,
  handleQuotationValues,
}: ICartPayment) => {
  const { badgeNumber: userHasBadge, isBadgeRequired, isSchool } = useSelector(
    (state: GlobalState) => state.auth?.userInfo || {}
  );

  const handleTriggerQuotationAndPaymentMethod = async (
    newPaymentMethod: string
  ) => {
    setPaymentMethod(newPaymentMethod);
  };

  const badgeContent = () => {
    if (!userHasBadge && !isBadgeRequired) {
      return null;
    }

    if (userHasBadge) {
      return (
        <PaymentContainer>
          <FlexDirectionRow>
            <BlackBadge />
            <PaymentTextContainer>
              <ProductDescription>
                {I18n.t("restaurantDetail.cart.badge")}
              </ProductDescription>
              <FlexDirectionRow>
                <SmallDescription>
                  {I18n.t("restaurantDetail.cart.pay")}
                </SmallDescription>
                <SmallDescription hasMargin bold>
                  {balance}€
                </SmallDescription>
              </FlexDirectionRow>
            </PaymentTextContainer>
          </FlexDirectionRow>
          <RadioGroup
            radioButtonsProps={[{ id: IPaymentTypes.Badge }]}
            value={paymentMethod}
            onPress={handleTriggerQuotationAndPaymentMethod}
          />
        </PaymentContainer>
      );
    } else if (isBadgeRequired) {
      return <AddBadge />;
    }
  };

  return (
    <>
      <SectionTitle>
        {I18n.t("restaurantDetail.cart.paymentMethod")}
      </SectionTitle>
      {paymentTypes.includes(IPaymentTypes.Badge) && badgeContent()}
      {!paymentTypes.includes(IPaymentTypes.Badge) && <PaymentContainer />}
      {paymentTypes.length === 1 &&
      paymentTypes.includes(IPaymentTypes.OnSite) ? (
        <>
          <OnSiteMethodPayment
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            handleTriggerQuotationAndPaymentMethod={
              handleTriggerQuotationAndPaymentMethod
            }
          />
          <WarningMessageContainer>
            <WarningOrange />
            <WarningMessage>
              {isSchool
                ? I18n.t("restaurantDetail.cart.payOnSiteSchoolInfo")
                : I18n.t("restaurantDetail.cart.payOnSiteInfo")}
            </WarningMessage>
          </WarningMessageContainer>
        </>
      ) : (
        (paymentTypes.includes(IPaymentTypes.Edenred) ||
          paymentTypes.includes(IPaymentTypes.OnSite) ||
          paymentTypes.includes(IPaymentTypes.CreditCard)) && (
          <OtherCards
            setPaymentMethod={setPaymentMethod}
            paymentMethod={paymentMethod}
            handleEdenredPayment={handleEdenredPayment}
            edenredBalance={edenredBalance}
            setIsOtherCardOpen={setIsOtherCardOpen}
            isOtherCardOpen={isOtherCardOpen}
            paymentTypes={paymentTypes}
            handleTriggerQuotationAndPaymentMethod={
              handleTriggerQuotationAndPaymentMethod
            }
          />
        )
      )}
    </>
  );
};
