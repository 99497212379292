import React from "react";
import {
  View,
  ViewStyle,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
} from "react-native";

import { Order, BookingOrder } from "@foodi/core";
import { Scroll, CloseBlack } from "@assets";
import {
  Container,
  ItemTitle,
  Separator,
  LastItemContainer,
  IconContainer,
  ScrollContainer,
} from "./ComingSoonModal.style";
import OrderItem from "./OrderItem";
import ReservationItem from "./ReservationItem";
import { Spacing } from "@constants";
import { I18n } from "react-redux-i18n";
import { TestIDs } from "@utils";
import { ComingSoonViewModel, HomeScreens } from "@modules";
import { NavigationProp } from "@react-navigation/native";
import { CloseClickOutsideTemplate } from "@atomic";

interface IProps {
  style?: ViewStyle;
  orders: Order[];
  bookingOrders: BookingOrder[];
  navigation?: NavigationProp<any>;
  isMobile?: boolean;
  handleCloseModal: () => void;
}

type AnyOrder = Order | BookingOrder;

const MAX_HEIGHT_MODAL = 342;

const AnyOrderItem: React.FC<{
  order: AnyOrder;
  navigation?: NavigationProp<any>;
  handleCloseModal: () => void;
}> = ({ order, navigation, handleCloseModal }) =>
  order?.__typename === "Order" ? (
    <OrderItem
      navigation={navigation}
      order={order}
      handleCloseModal={handleCloseModal}
    />
  ) : (
    <ReservationItem
      navigation={navigation}
      reservation={order}
      handleCloseModal={handleCloseModal}
    />
  );

export const ComingSoonModal: React.FC<IProps> = React.memo(
  ({
    style,
    orders,
    bookingOrders,
    navigation,
    isMobile,
    handleCloseModal,
  }) => {
    const [allOrders, setAllOrders] = React.useState<
      (Order | BookingOrder)[] | undefined
    >(undefined);
    const comingSoonViewModel = new ComingSoonViewModel();

    React.useEffect(() => {
      const anyOrders = [...orders, ...bookingOrders];
      const allResults = comingSoonViewModel.getSortedOrders(anyOrders);

      setAllOrders(allResults);
    }, []);

    const handleTerminatedActivities = () => {
      navigation?.navigate(HomeScreens.BALANCE_SCREEN);
    };

    const CloseButtonView = React.memo(() => (
      <TouchableOpacity
        testID={TestIDs.home.comingSoon.actions.closeModal}
        onPress={handleCloseModal}
        style={styles.closeBtn}
      >
        <CloseBlack />
      </TouchableOpacity>
    ));

    return (
      <Container
        testID={TestIDs.home.comingSoon.views.modal}
        style={style}
        isMobile={isMobile}
      >
        <CloseClickOutsideTemplate handleClose={handleCloseModal}>
          {isMobile && <CloseButtonView />}
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ maxHeight: MAX_HEIGHT_MODAL }}
          >
            {allOrders?.map((order: AnyOrder) => (
              <View key={order?.id}>
                <AnyOrderItem
                  navigation={navigation}
                  order={order}
                  handleCloseModal={handleCloseModal}
                />
                <Separator />
              </View>
            ))}
          </ScrollView>
          <ScrollContainer>
            {allOrders && allOrders?.length >= 4 && <Scroll />}
          </ScrollContainer>
          <TouchableOpacity
            testID={TestIDs.home.comingSoon.actions.seeOrders}
            onPress={handleTerminatedActivities}
          >
            <LastItemContainer>
              <ItemTitle endOfList>
                {I18n.t("homepage.comingSoon.terminatedActivities")}
              </ItemTitle>
              <IconContainer name="Arrows_Right_White" size={13} />
            </LastItemContainer>
          </TouchableOpacity>
        </CloseClickOutsideTemplate>
      </Container>
    );
  }
);

const styles = StyleSheet.create({
  closeBtn: {
    position: "absolute",
    top: 0,
    zIndex: 10,
    right: Spacing.XS,
    padding: Spacing.M,
  },
});
