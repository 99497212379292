import { Warning } from "@assets";
import { Button } from "@atomic";
import { OffersActions } from "@modules";
import { GlobalState } from "@redux";
import { TestIDs } from "@utils";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  Container,
  Title,
  ItemText,
  ListContainer,
  Row,
  IconContainer,
  Wrapper,
  ContentContainer,
  TextContainer,
  ButtonContainer,
} from "./OutOfStockModal.style";

interface IProps {}

export const OutOfStockModal: React.FC<IProps> = React.memo(({}) => {
  const dispatch = useDispatch();

  const { outOfStockItems, outOfStockModalOpen } =
    useSelector((state: GlobalState) => state.offers) || {};

  const handleGoToCart = () => {
    dispatch(OffersActions.removeOrderItems(outOfStockItems));
  };

  return !outOfStockModalOpen ? null : (
    <Wrapper testID={TestIDs.restaurantDetail.views.outOfStockModalContainer}>
      <Container>
        <ContentContainer>
          <Row>
            <IconContainer>
              <Warning />
            </IconContainer>
            <TextContainer>
              <Title>
                {I18n.t("restaurantDetail.productNoLongerAvailable")}
              </Title>
              <ListContainer>
                {outOfStockItems?.map((o, index) => (
                  <ItemText
                    key={`${o?.quantity} ${o?.title}_${index}`}
                  >{`${o?.quantity} ${o?.title}`}</ItemText>
                ))}
              </ListContainer>
              <ButtonContainer>
                <Button
                  forwardTestID={TestIDs.components.Button.views.container}
                  label={I18n.t("restaurantDetail.backToCart")}
                  onPress={handleGoToCart}
                />
              </ButtonContainer>
            </TextContainer>
          </Row>
        </ContentContainer>
      </Container>
    </Wrapper>
  );
});
