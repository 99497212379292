import React from "react";
import { I18n } from "react-redux-i18n";

interface IProps {
  backgroundColor?: string;
  width?: string;
  style?: any;
}

export const Information: React.FC<IProps> = ({
  backgroundColor = "#527EDB",
  width = "22px",
  style
}) => {
  return (
    <svg
      style={style}
      width={width}
      height="22px"
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{I18n.t("hoverIcons.important")}</title>
      <g
        id="Atom/Alerts/Information"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <circle
          id="Oval"
          fill={backgroundColor}
          cx="11"
          cy="11"
          r="11"
        ></circle>
        <text
          id="i"
          font-family="Manrope-ExtraLight_ExtraBold, Manrope"
          font-size="16"
          font-weight="700"
          fill="#FFFFFF"
        >
          <tspan x="9" y="17">
            i
          </tspan>
        </text>
      </g>
    </svg>
  );
};
