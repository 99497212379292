import { createReducer } from "@redux";
import { Action } from "@redux/action";

export interface IArticleParams {
  resume?: string;
  title?: string;
  date: string;
  image: string;
  content?: any;
}

export interface ArticleState {
  params: IArticleParams;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_ARTICLE_PARAMS: "ArticlePage/SET_ARTICLE_PARAMS",
};

const ActionCreators = {
  setArticleParams: (params: IArticleParams): Action<IArticleParams> => ({
    type: ActionTypes.SET_ARTICLE_PARAMS,
    payload: params,
  }),
};

/*************  Reducer  ****************/

const initialState: ArticleState = {
  params: {
    resume: "",
    title: "",
    date: "",
    image: "",
    content: "",
  }
};

const Reduction = {
  setArticleParams: (
    state: ArticleState,
    { payload: params }: Action<IArticleParams>
  ): ArticleState => ({
    ...state,
    params,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_ARTICLE_PARAMS]: Reduction.setArticleParams,
});

export default reducer;

export {
  reducer as ArticleReducer,
  ActionTypes as ArticleActionTypes,
  ActionCreators as ArticleActions,
};
