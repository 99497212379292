import React, { useMemo } from "react";
import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import { Title22 } from "@stylesheets";
import { Colors, Spacing } from "@constants";

interface IProps {
  title: string;
  forwardTestID?: string;
  isSelected: boolean;
  callback: (index: number) => void;
  index: number;
}

export const POSButton: React.FC<IProps> = React.memo(
  ({ forwardTestID, title, isSelected, callback, index }) => {
    const styles = useMemo(() => _styles(!!isSelected), [isSelected]);

    const onPress = () => {
      callback(index);
    };

    return (
      <TouchableOpacity
        onPress={onPress}
        testID={forwardTestID}
        style={styles.container}
      >
        <Title22 style={styles.title}>{title}</Title22>
      </TouchableOpacity>
    );
  }
);

const _styles = (isSelected: boolean) =>
  StyleSheet.create({
    container: {
      height: 40,
      paddingHorizontal: Spacing.L,
      borderWidth: 1,
      borderRadius: 20,
      borderColor: Colors.foodiBlack,
      backgroundColor: isSelected ? Colors.foodiBlack : "transparent",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      color: isSelected ? Colors.white : Colors.foodiBlack,
      fontFamily: "manrope-regular",
    },
  });
