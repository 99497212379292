import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { persistedStore, store } from "@redux";
import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { getApolloClient as Client } from "./apollo";
import { DeeplinkConfigs, navigationRef, RootNavigator } from "./navigation";
import GlobalStyles, { theme } from "./configuration/style/global.style";
import { AuthThunks } from "@modules";
import "./utils/icons";
import { Config } from "../../../config";
import { LoaderAnimation } from "@atomic/atoms/LoaderAnimation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toast } from "@atomic";
import { navigationPermission } from "@utils";
import { get } from "lodash";

const Stack = createStackNavigator();

declare global {
  interface Window {
    config: Config;
  }
}

const config = {
  screens: {
    Home: {
      screens: {
        Profile: "users/:id",
      },
    },
  },
};

const linking = {
  prefixes: ["https://foodi.com", "foodi://"],
  config,
};

export function App() {
  // handle the the cat external link on the top of app, out of all auth logic
  if (get(window, "location.pathname", "").toLowerCase() === "/cat")
    window.location.replace(window.config.CAT_EXTERNAL_URL);

  // persistedStore.purge();
  store.dispatch(AuthThunks.initializeAuth0Client());

  if (!Client()) return null;
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={Client()}>
        <Provider store={store}>
          <PersistGate persistor={persistedStore}>
            <SafeAreaProvider>
              <NavigationContainer
                ref={navigationRef}
                linking={DeeplinkConfigs()}
                onStateChange={(state) => navigationPermission(state, store)}
                documentTitle={{
                  formatter: (options, route) => "Foodi",
                }}
              >
                <LoaderAnimation />
                <Toast />
                <Stack.Navigator initialRouteName={"Root"}>
                  <Stack.Screen
                    component={RootNavigator}
                    name={"Root"}
                    options={{ headerShown: false }}
                  />
                </Stack.Navigator>
              </NavigationContainer>
            </SafeAreaProvider>
          </PersistGate>
        </Provider>
      </ApolloProvider>
      <GlobalStyles />
    </ThemeProvider>
  );
}

declare let global: {
  HermesInternal?: boolean;
};
