import { isHeartMealsRuleValidSelector } from "@modules";
import { GlobalState } from "@redux";
import { useSelector } from "react-redux";

export const useCartSelectors = () => {
  const { idGuest, badgeNumber } =
    useSelector((state: GlobalState) => state.auth?.userInfo) || {};

  const isHeartMealsRuleValid = useSelector(isHeartMealsRuleValidSelector);
  const balance = useSelector(
    (state: GlobalState) => state.account?.userBalance?.amount
  );
  const idOffer = useSelector(
    (state: GlobalState) => state.offers?.selectedOffer?.nextOrderableOffer?.id
  );
  const withdrawalType = useSelector(
    (state: GlobalState) => state.offers?.selectedOffer?.withdrawalType
  );

  const offerSlot = useSelector(
    (state: GlobalState) => state.offers?.selectedOfferSlot
  );

  const cartCurrentInfo = useSelector(
    (state: GlobalState) => state.pointOfSale?.cartCurrentInfo
  );
  const language = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language
  );
  const { edenredBalance, edenredToken } =
    useSelector((state: GlobalState) => state.payments) || {};

  const offerType = useSelector(
    (state: GlobalState) => state.offers?.selectedOffer?.type
  );
  const paymentTypes = useSelector(
    (state: GlobalState) =>
      state.offers?.selectedOffer?.paymentMethod?.paymentTypes || []
  );
  const selectedPointOfSale = useSelector(
    (state: GlobalState) => state.pointOfSale?.selectedPos?.pointOfSale
  );
  const idCashSystem = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.idCashSystem
  );
  const selectedOffer = useSelector(
    (state: GlobalState) => state.offers.selectedOffer
  );

  return [
    idGuest,
    badgeNumber,
    isHeartMealsRuleValid,
    balance,
    idOffer,
    withdrawalType,
    offerSlot,
    cartCurrentInfo,
    language,
    edenredBalance,
    edenredToken,
    offerType,
    paymentTypes,
    selectedPointOfSale,
    idCashSystem,
    selectedOffer,
  ];
};
