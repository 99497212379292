import React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Title13, Title18 } from "@stylesheets";
import { Colors } from "@constants";
import { I18n } from "react-redux-i18n";
import { Delete, Modify } from "@assets";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { HoldingView, UserProfile } from "@foodi/core";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import { HoldingViewModel } from "@modules";
import _ from "lodash";
import { NavigationProp } from "@react-navigation/native";

interface IProps {
  navigation: NavigationProp<any>;
  hv: HoldingView;
  index: number;
  handleModifyHoldingView: (hv: HoldingView) => () => void;
  handleHoldingViewSwitch: (hv: HoldingView) => () => void;
  handleDeleteHoldingView: (
    hv: HoldingView,
    index: number,
    isActiveHoldingView?: boolean
  ) => () => void;
}

export const HoldingViewRow: React.FC<IProps> = React.memo(
  ({
    hv,
    index,
    handleModifyHoldingView,
    handleDeleteHoldingView,
    handleHoldingViewSwitch,
  }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const [isHover, setIsHover] = React.useState<string>();

    const HoldingViewRowText: React.FC<any> = isMobile ? Title13 : Title18;

    const { idHoldingView } =
      useSelector((state: GlobalState) => state.auth?.userInfo) ??
      ({} as UserProfile);

    const holdingViewModel = new HoldingViewModel(dispatch, isMobile);

    const setHoverValue = (value?: string) =>
      /* istanbul ignore next */
      () => setIsHover(value);

    const modifyIconSize = isMobile ? 24 : 30;

    const isActiveHoldingView = holdingViewModel.isActiveHoldingView(
      idHoldingView,
      hv.id
    );
    const rowStyle = isActiveHoldingView
      ? [styles.holdingView, styles.activeHoldingView]
      : [styles.holdingView];

    const { firstName } =
      useSelector((state: GlobalState) => state.auth?.userInfo) ??
      ({} as UserProfile);

    return (
      <View style={[styles.row, styles.marginBottom]}>
        <Pressable
          key={hv.id}
          style={[...rowStyle, isHover === hv.id && styles.rowHover]}
          //@ts-ignore
          onHoverIn={setHoverValue(hv.id)}
          onHoverOut={setHoverValue(undefined)}
          disabled={isActiveHoldingView}
          onPress={handleHoldingViewSwitch(hv)}
        >
          <HoldingViewRowText
            style={isActiveHoldingView && styles.activeHoldingViewText}
            isBold
            bold
            color={Colors.foodiBlack}
          >
            {holdingViewModel.getHoldingViewRowText(
              hv,
              !!hv?.holding?.isBadgeRequired,
              firstName
            )}
          </HoldingViewRowText>
        </Pressable>
        <Pressable
          testID={TestIDs.profile.actions.pressEditHoldingView}
          style={[styles.iconContainer, styles.marginLeft]}
          onPress={handleModifyHoldingView(hv)}
        >
          <Modify width={modifyIconSize} height={modifyIconSize} />
        </Pressable>
        <Pressable
          testID={TestIDs.profile.actions.pressDeleteHoldingView}
          style={styles.iconContainer}
          onPress={handleDeleteHoldingView(hv, index + 1, isActiveHoldingView)}
        >
          <Delete />
        </Pressable>
        {isActiveHoldingView && (
          <HoldingViewRowText color={Colors.middleGreen}>
            {I18n.t("account.active")}
          </HoldingViewRowText>
        )}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  holdingView: {
    backgroundColor: Colors.disabledBackground,
    borderRadius: 15,
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderWidth: 2,
    borderColor: Colors.transparent,
    flexDirection: "row",
  },
  activeHoldingView: {
    backgroundColor: Colors.middleGreen,
  },
  activeHoldingViewText: {
    color: Colors.white,
  },
  row: {
    flexDirection: "row",
  },
  iconContainer: {
    marginRight: 10,
  },
  marginLeft: {
    marginLeft: 20,
  },
  rowHover: {
    borderColor: Colors.middleGreen,
    borderWidth: 2,
  },
  marginBottom: {
    marginBottom: 16,
  },
});
