import moment, { Moment } from "moment";

const TOKEN_EXPIRATION_OFFSET = 5;

export const getExpirationTokenInfoFromExpireIn = (
  expiresIn: number
): { emissionDate: string; expirationDate: string } => {
  const emissionDate: string = moment().toISOString();
  const expirationDate: string = moment(emissionDate)
    .add(expiresIn, "s")
    .subtract(TOKEN_EXPIRATION_OFFSET, "s")
    .toISOString();

  return { emissionDate, expirationDate };
};

export const getExpirationTokenInfoFromExp = (
  exp: number
): {
  emissionDate: string;
  expirationDate: string;
  expireIn: number;
} => {
  const momentNowDate: Moment = moment();
  const momentExpirationDate: Moment = moment(exp * 1000);
  const expireIn: number = momentExpirationDate.diff(momentNowDate, "s");
  const emissionDate: string = momentNowDate.toISOString();
  const expirationDate: string = momentExpirationDate
    .subtract(TOKEN_EXPIRATION_OFFSET, "s")
    .toISOString();

  return { emissionDate, expirationDate, expireIn };
};
