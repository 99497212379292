import React, { useEffect, useMemo, useRef, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { LeftArrow, RightArrow } from "@atomic";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";

interface IProps {
  items: any[];
  itemWidth: number;
  onSelectItem?: Function;
  renderItems: (
    elements: any[],
    onSelectedItem: (selectedElementIndex: number) => void
  ) => JSX.Element[];
  width: number;
  height: number;
  hasBorder?: boolean;
  hasArrowsForShow?: boolean;
}

export const Carousel: React.FC<IProps> = React.memo(
  ({
    items = [],
    onSelectItem,
    itemWidth = 250,
    renderItems,
    width,
    height,
    hasBorder,
    hasArrowsForShow,
  }) => {
    const [isMobile] = useDevices();
    const styles = useMemo(
      () => _styles(isMobile, width, height, hasBorder, hasArrowsForShow),
      [isMobile, width, height, hasBorder, hasArrowsForShow]
    );

    const carouselRef = useRef(null);
    const xOffsetRed = useRef(0);
    const carouselIndex = useRef(0);
    const [elements, setElements] = useState<any[]>([]);
    const mobileHandleLenght = isMobile ? 1 : 2;
    const scrollItems = items?.length - mobileHandleLenght ?? 0;
    const itemsWidth = scrollItems * itemWidth;
    const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
    const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

    useEffect(() => {
      const _elements =
        items?.map((item, index) => {
          return {
            ...item,
            isSelected: index === 0,
          };
        }) || [];

      setElements(_elements);
      setShowRightArrow(_elements.length > 2);
    }, [items]);

    const onSelectedItem = (selectedElementIndex: number) => {
      if (onSelectItem) {
        const _elements = elements.map((item, index) => {
          return {
            ...item,
            isSelected: index === selectedElementIndex,
          };
        });
        setElements(_elements);
        onSelectItem(_elements[selectedElementIndex]);
      }
    };

    const goToPrev = () => {
      if (xOffsetRed.current === 0 || hasArrowsForShow) return;
      xOffsetRed.current = xOffsetRed.current - itemWidth;
      scrollToXOffset();
    };

    const goToNext = () => {
      if (xOffsetRed.current === itemsWidth || hasArrowsForShow) return;
      xOffsetRed.current = xOffsetRed.current + itemWidth;
      scrollToXOffset();
    };

    const scrollToXOffset = () => {
      //@ts-ignore
      carouselRef?.current?.scrollTo({
        x: xOffsetRed.current,
        animated: true,
      });
      updateCarouselIndex();
    };

    const updateCarouselIndex = () => {
      carouselIndex.current = xOffsetRed.current / itemWidth;
      onIndexChanged();
    };

    const onIndexChanged = () => {
      setShowLeftArrow(carouselIndex.current !== 0);
      setShowRightArrow(carouselIndex.current !== scrollItems);
    };

    return (
      <View style={styles.container}>
        <ScrollView
          ref={carouselRef}
          horizontal
          contentContainerStyle={styles.contentContainerStyle}
          scrollEnabled={isMobile}
          showsHorizontalScrollIndicator={false}
        >
          {renderItems(elements, onSelectedItem)}
          {isMobile && <View style={{ width: 250 }}></View>}
        </ScrollView>
        {showLeftArrow && !isMobile && (
          <LeftArrow
            forwardTestID={TestIDs.restaurantDetail.actions.carouselLeftArrow}
            style={styles.leftArrow}
            onPress={goToPrev}
          />
        )}
        {showRightArrow && (!isMobile || hasArrowsForShow) && (
          <RightArrow
            forwardTestID={TestIDs.restaurantDetail.actions.carouselRightArrow}
            style={styles.rightArrow}
            onPress={goToNext}
          />
        )}
      </View>
    );
  }
);

const _styles = (
  isMobile: boolean,
  width: number,
  height: number,
  hasBorder?: boolean,
  hasArrowsForShow?: boolean
) =>
  StyleSheet.create({
    container: {
      width,
      justifyContent: "center",
    },
    contentContainerStyle: {
      height,
      width,
    },
    leftArrow: {
      position: "absolute",
      left: hasBorder ? -5 : -10,
    },
    rightArrow: {
      position: "absolute",
      right:
        isMobile && hasArrowsForShow
          ? 10
          : isMobile
          ? 255
          : hasBorder
          ? 0
          : -10,
    },
  });
