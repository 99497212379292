import { NavigationProp, Route } from "@react-navigation/native";
import { View, StyleSheet } from "react-native";
import React, { useEffect, useState } from "react";
import { LoaderAnimationComponent, OfferOderContainer, Offers } from "@atomic";
import { OfferViewModel, OffersThunks } from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState, LoaderActions } from "@redux";
import { ILanguage, IOfferTemplate } from "@foodi/core";
interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
  isRefillFromCart?: boolean;
}

export const ClickAndCollectTemplate: React.FC<IProps> = React.memo(
  ({ navigation, route, isRefillFromCart }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [offers, setOffers] = useState<IOfferTemplate[]>([]);
    const [isOfferFetching, setIsOfferFetching] = useState(false);
    const userInfo = useSelector((state: GlobalState) => state.auth?.userInfo);
    const userLanguage = (userInfo?.language as ILanguage) || ILanguage.fr;
    const dispatch = useDispatch();
    const [offerVM] = useState(new OfferViewModel(dispatch));
    const pointOfSale = useSelector(
      (state: GlobalState) => state.pointOfSale?.selectedPos?.pointOfSale
    );

    const handleOffers = async () => {
      setIsLoading(true);
      try {
        //@ts-ignore
        const { offerTemplates } = await dispatch(
          OffersThunks.getOfferTemplates({
            type: "CLICK_COLLECT",
            withdrawalType:
              "CONNECTED_LOCKERS,POS_TAKE_AWAY,POS_AT_SITE,TABLE_SERVICE,POS_CLICK_SERVE,INSTANT_CLICK_COLLECT,CLICK_AND_PICK_UP",
            language: userLanguage,
            //@ts-ignore
            posId: `${pointOfSale?.id}`,
          })
        );
        setOffers(
          offerVM
            //@ts-ignore
            .sortOffers(offerTemplates)
        );
      } catch (e) {
        setIsLoading(false);
      }
      setIsLoading(false);
    };

    useEffect(() => {
      handleOffers();
    }, [userInfo, pointOfSale]);

    if (isLoading) {
      return (
        <View style={styles.loadingStyle}>
          <LoaderAnimationComponent />
        </View>
      );
    }

    return (
      <>
        <View style={styles.container}>
          <Offers
            isRefillFromCart={isRefillFromCart}
            offers={offers}
            setIsOfferFetching={setIsOfferFetching}
          />
          <OfferOderContainer
            navigation={navigation}
            route={route}
            isRefillFromCart={isRefillFromCart}
            isOfferFetching={isOfferFetching}
          />
        </View>
      </>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  loadingStyle: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: "500px",
  },
});
