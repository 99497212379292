import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.TouchableOpacity`
    height: 24px;
    width: 24px;
    border-radius: 12px;
    border-width: 2px;
    border-color: ${Colors.black};
    align-items: center;
    justify-content: center;
`;

export const Content = style.View`
    height: 12px;
    width: 12px;
    border-radius: 6px;
    background-color: ${Colors.black};
`;
