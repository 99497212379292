import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.Pressable`
    width: 20px;
    height: 20px;
    border: 1px solid ${Colors.border1};
    align-items: center;
    justify-content: center;
`;
