import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { NavigationProp } from "@react-navigation/native";
import { ProfileTabs } from "@modules/profile";
import { ProfileMenuTab } from "@atomic";
import { Colors, Spacing } from "@constants";
import { I18n } from "react-redux-i18n";
import {
  DisconnectBlack,
  HelpBlack,
  LegalInfoBlack,
  ProfileBlack,
  SettingsBlack,
} from "@assets";
import { TestIDs } from "@utils";
import {
  AccountActions,
  AuthActions,
  BookingActions,
  HomeScreens,
  OffersActions,
  PaymentsActions,
} from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { BrandThemeActions, GlobalState, persistedStore } from "@redux";
import { getApolloClient } from "../../../apollo";
import { useDevices } from "@hooks";
interface IProps {
  selectedTab: ProfileTabs;
  callback: (tab: ProfileTabs) => void;
  navigation: NavigationProp<any>;
  showLeftContainer: boolean;
}

export const ProfileMenu: React.FC<IProps> = React.memo(
  ({ callback, selectedTab, navigation, showLeftContainer }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const styles = useMemo(() => _styles(isMobile, showLeftContainer), [
      isMobile,
      showLeftContainer,
    ]);

    const onSelectedTab = (tab: ProfileTabs) => {
      callback(tab);
    };

    // to update language
    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );

    const handleNeedHelp = () => {
      window.open(window.config.ZENDESK_BASE_URL, "_blank")?.focus();
    };

    const handleLogout = async () => {
      await getApolloClient().cache.reset();

      dispatch(AuthActions.logOutUser());
      dispatch(AccountActions.setUserBalance(null));
      dispatch(BookingActions.initBookingOrders());
      dispatch(OffersActions.initOrderItems());
      dispatch(BrandThemeActions.resetBrandTheme());
      dispatch(PaymentsActions.resetPayment());

      window.localStorage.removeItem('persist:root');
      window.localStorage.clear();
      persistedStore.purge();
      navigation?.navigate(HomeScreens.HOME_SCREEN);
    };

    return (
      <View testID={TestIDs.profile.views.profileMenu} style={styles.container}>
        <ProfileMenuTab
          tabName={ProfileTabs.ACCOUNT}
          title={I18n.t("profile.myAccount")}
          icon={<ProfileBlack />}
          isSelected={selectedTab === ProfileTabs.ACCOUNT}
          onSelectedTab={onSelectedTab}
          forwardTestID={TestIDs.profile.actions.myAccountTab}
          showForward
        />
        <View style={styles.separator} />
        <ProfileMenuTab
          tabName={ProfileTabs.SETTINGS}
          title={I18n.t("profile.settings")}
          icon={<SettingsBlack />}
          isSelected={selectedTab === ProfileTabs.SETTINGS}
          onSelectedTab={onSelectedTab}
          forwardTestID={TestIDs.profile.actions.settingTab}
          showForward
        />
        <View style={styles.separator} />
        <ProfileMenuTab
          tabName={ProfileTabs.LEGAL_INFORMATION}
          title={I18n.t("profile.legalInformation")}
          icon={<LegalInfoBlack />}
          isSelected={selectedTab === ProfileTabs.LEGAL_INFORMATION}
          onSelectedTab={onSelectedTab}
          forwardTestID={TestIDs.profile.actions.legalInfosTab}
          showForward
        />
        <View style={styles.separator} />
        <ProfileMenuTab
          tabName={ProfileTabs.HELP}
          title={I18n.t("profile.help")}
          icon={<HelpBlack />}
          isSelected={selectedTab === ProfileTabs.HELP}
          onSelectedTab={handleNeedHelp}
          forwardTestID={TestIDs.profile.actions.helpTab}
          showForward
        />
        <View style={styles.separator} />
        <ProfileMenuTab
          tabName={ProfileTabs.LOGOUT}
          title={I18n.t("profile.logout")}
          icon={<DisconnectBlack />}
          isSelected={selectedTab === ProfileTabs.LOGOUT}
          onSelectedTab={handleLogout}
          forwardTestID={TestIDs.profile.actions.logoutTab}
        />
      </View>
    );
  }
);

const _styles = (isMobile: boolean, showLeftContainer: boolean) =>
  StyleSheet.create({
    container: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: 284,
      display: showLeftContainer ? "flex" : "none",
    },
    separator: {
      width: isMobile ? "90vw" : "100%",
      height: 1,
      backgroundColor: Colors.disabledBackground,
      marginVertical: Spacing.L,
    },
  });
