import React from "react";

interface IProps {
  height?: number;
  width?: number;
}
export const Checked: React.FC<IProps> = ({ height = 20, width = 20 }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 20 20`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icon/Checked</title>
    <g
      id="Icon/Checked"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <polygon
        id="Path"
        fill="#000000"
        fill-rule="nonzero"
        points="7.5 13.5 4 10 2.83333333 11.1666667 7.5 15.8333333 17.5 5.83333333 16.3333333 4.66666667"
      ></polygon>
    </g>
  </svg>
);
