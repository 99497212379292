import React from "react";
import {
  Row,
  OrderText,
  DescriptionText,
  ProductDescription,
  Title,
  Action,
  BlockTitle,
  GoBackText,
  ModalContainer,
  IconContainer,
} from "./ConfirmationPage.style";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import { Information, WarningOrange } from "@assets";
import { CloseClickOutsideTemplate, ModalTemplate } from "@atomic";
import { GlobalState, ModalActions } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import {
  BookingActions,
  ConfirmationPageActions,
  HomeScreens,
  OffersActions,
  OrderViewModel,
  PointOfSaleActions,
  POSScreens,
} from "@modules";
import { OrderState } from "@foodi/core";
import { NavigationProp } from "@react-navigation/native";
import { getDecodedId, TestIDs } from "@utils";
import { ActivityIndicator, StyleSheet } from "react-native";
import { useDevices } from "@hooks";
import { Colors } from "@constants";

interface IProps {
  navigation?: NavigationProp<any>;
  order: any;
  date: string;
  isFirstTime?: boolean;
  idPos: number;
}

export const BookingContent: React.FC<IProps> = React.memo(
  ({ navigation, order, date, isFirstTime, idPos }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();
    const orderVM = new OrderViewModel(dispatch, "");

    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );
    const selectedDay = useSelector(
      (state: GlobalState) => state.booking?.selectedDay ?? 0
    );
    const holdingId = useSelector(
      (state: GlobalState) => state?.auth?.userInfo?.holdingId
    );

    const [canceled, setCanceled] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const id = getDecodedId(order?.id);

    const handleEdit = () => {
      dispatch(PointOfSaleActions.setBookingButtonStatus(true));
      dispatch(OffersActions.setSelectedOfferSlot(null));
      dispatch(BookingActions.setBookingSelected(true));

      if (isMobile) {
        dispatch(PointOfSaleActions.setMiniBookingCartStatus(false));
        dispatch(PointOfSaleActions.setFullBookingCartStatus(true));
      }

      //@ts-ignore
      navigation?.push(HomeScreens.POINT_OF_SALE_STACK, {
        screen: POSScreens.RESTAURANT_DETAIL,
        params: {
          id: idPos,
          isModify: order,
          isRefillFromCart: false,
        },
      });
    };

    const handleNewReservation = () => {
      dispatch(PointOfSaleActions.setBookingButtonStatus(true));
      dispatch(OffersActions.setSelectedOfferSlot(null));
      dispatch(BookingActions.setBookingSelected(true));
      //@ts-ignore
      navigation?.push(HomeScreens.POINT_OF_SALE_STACK, {
        screen: POSScreens.RESTAURANT_DETAIL,
        params: {
          id: idPos,
          isModify: false,
          isRefillFromCart: false,
        },
      });
    };

    const handleCancel = () => {
      dispatch(ModalActions.setOpen(true));
    };

    const handleCloseModal = () => {
      dispatch(ModalActions.setOpen(false));
    };

    const handleCancelConfirmation = async () => {
      if (order) {
        setLoading(true);
        const res = await orderVM.cancelBookingOrder({
          idOrder: order?.id,
          newState: OrderState.CANCELLED,
        });

        if (res) {
          setLoading(false);
          setCanceled(true);
        }
      }

      dispatch(PointOfSaleActions.setNewOrder(true));
      dispatch(ModalActions.setOpen(false));

      dispatch(
        ConfirmationPageActions.setConfirmationPageParams({
          id: id?.split(":")?.[1],
          orderId: order?.id,
          isFirstTime: false,
          idPos,
          offerSlot: "",
        })
      );

      navigation?.navigate(HomeScreens.POINT_OF_SALE_STACK, {
        screen: POSScreens.CONFIRMATION_PAGE,
      });
    };

    const orderHolding = order?.offer?.offerTemplate?.pos?.zone?.holding;

    const isSameHolding = holdingId === orderHolding?.id;

    const title = canceled
      ? "confirmationPage.yourReservationCanceled"
      : "confirmationPage.yourReservation";

    const information = isFirstTime
      ? "confirmationPage.rememberReservation"
      : isSameHolding
      ? "confirmationPage.toPresentOnArrival"
      : "confirmationPage.differentHoldingDescription";

    return (
      <>
        {!isFirstTime && <Title isMobile={isMobile}>{I18n.t(title)}</Title>}
        <OrderText
          testID={TestIDs.home.confirmationPage.views.bookingContainer}
          isMobile={isMobile}
        >
          {date}
        </OrderText>
        <Row>
          <DescriptionText isMobile={isMobile}>
            {I18n.t("confirmationPage.place")} :{" "}
          </DescriptionText>
          <DescriptionText bold isMobile={isMobile}>
            {order?.withdrawLocation}
          </DescriptionText>
        </Row>
        {canceled ? (
          <Row testID={TestIDs.home.confirmationPage.views.bookingCanceled}>
            <Action
              testID={TestIDs.home.confirmationPage.actions.newReservation}
              marginTop={34}
              backgroundColor={
                brandTheme.buttonActionColors?.backgroundColor ||
                Colors.foodiDefault
              }
              onPress={handleNewReservation}
            >
              <BlockTitle
                color={
                  brandTheme.buttonActionColors?.textColor || Colors.foodiBlack
                }
              >
                {I18n.t("confirmationPage.makeReservation")}
              </BlockTitle>
            </Action>
          </Row>
        ) : (
          <>
              {isSameHolding && (
            <Row
              width={425}
              marginTop={isMobile ? 20 : 50}
              isMobile={isMobile}
            >
              <Action
                testID={TestIDs.home.confirmationPage.actions.editBooking}
                hasBorder
                marginRight={isMobile ? 0 : 30}
                onPress={handleEdit}
              >
                <BlockTitle>{I18n.t("confirmationPage.edit")}</BlockTitle>
              </Action>
              <Action
                testID={
                  TestIDs.home.confirmationPage.actions.clickBookingCancellation
                }
                marginTop={isMobile ? 20 : 0}
                backgroundColor={
                  brandTheme.buttonActionColors?.backgroundColor ||
                  Colors.foodiDefault
                }
                onPress={handleCancel}
              >
                <BlockTitle
                  color={
                    brandTheme.buttonActionColors?.textColor ||
                    Colors.foodiBlack
                  }
                >
                  {I18n.t("confirmationPage.cancel")}
                </BlockTitle>
              </Action>
            </Row>
              )}
            <Row marginTop={isSameHolding ? 20 : 54} width={isMobile ? 300 : undefined}>
              <Information />
              <ProductDescription hasNoMaxWidth marginLeft={6}>
                {I18n.t(information)}
              </ProductDescription>
            </Row>
          </>
        )}
        <ModalTemplate
          forwardTestID={
            TestIDs.home.confirmationPage.views.confirmCancellation
          }
          forwardCloseTestID={
            TestIDs.home.confirmationPage.actions.backCancellation
          }
          style={{ width: 414 }}
          closeXButtonStyle={styles.closeXButtonStyle}
        >
          <CloseClickOutsideTemplate handleClose={handleCloseModal}>
            <ModalContainer>
              <Row>
                <IconContainer>
                  <WarningOrange />
                </IconContainer>
                <GoBackText>
                  {I18n.t("confirmationPage.cancelConfirmation")}
                </GoBackText>
              </Row>
              <Action
                testID={TestIDs.home.confirmationPage.actions.cancelBooking}
                marginTop={34}
                backgroundColor={
                  brandTheme.buttonActionColors?.backgroundColor ||
                  Colors.foodiDefault
                }
                onPress={handleCancelConfirmation}
                justifyContent={loading}
              >
                {loading ? (
                  <>
                    <ActivityIndicator
                      style={styles.loadingStyle}
                      color={
                        brandTheme.buttonActionColors?.textColor ||
                        Colors.foodiBlack
                      }
                    />
                    <BlockTitle hide>
                      {I18n.t("confirmationPage.cancelReservation")}
                    </BlockTitle>
                  </>
                ) : (
                  <BlockTitle
                    color={
                      brandTheme.buttonActionColors?.textColor ||
                      Colors.foodiBlack
                    }
                  >
                    {I18n.t("confirmationPage.cancelReservation")}
                  </BlockTitle>
                )}
              </Action>
            </ModalContainer>
          </CloseClickOutsideTemplate>
        </ModalTemplate>
      </>
    );
  }
);

const styles = StyleSheet.create({
  loadingStyle: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  closeXButtonStyle: {
    top: -15,
    right: -5,
  },
});
