import { POSButton } from "@atomic";
import { Spacing } from "@constants";
import { BookingActions, OffersActions, PointOfSaleActions } from "@modules";
import { GlobalState } from "@redux";
import { TestIDs } from "@utils";
import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";

interface IProps {
  style?: ViewStyle;
}

const BOOKING_INDEX = 0;
const CLICK_COLLECT_INDEX = 1;
export const POSButtons: React.FC<IProps> = React.memo(({ style }) => {
  const isBookingSelected = useSelector(
    (state: GlobalState) => state?.booking?.isBookingSelected
  );
  const dispatch = useDispatch();

  //This line is used to force the component rerender with the right language when changed
  useSelector((state: GlobalState) => state.auth?.userInfo?.language);

  const onSelect = (buttonIndex: number) => {
    dispatch(BookingActions.setBookingSelected(buttonIndex === BOOKING_INDEX));
    if (buttonIndex === BOOKING_INDEX) {
      dispatch(PointOfSaleActions.setMiniCartStatus(false));

      dispatch(OffersActions.initOrderItems());
    } else {
      dispatch(PointOfSaleActions.setMiniBookingCartStatus(false));
    }
  };

  const isCCButtonAvailable = useSelector(
    (state: GlobalState) => state?.pointOfSale?.posCCButtonStatus
  );

  return (
    <View style={[styles.container, style]}>
      <POSButton
        forwardTestID={TestIDs.restaurantDetail.actions.bookingButton}
        title={I18n.t("restaurantDetail.booking")}
        index={BOOKING_INDEX}
        callback={onSelect}
        isSelected={isBookingSelected}
      />

      <View style={styles.space} />
      {isCCButtonAvailable && (
        <POSButton
          forwardTestID={TestIDs.restaurantDetail.actions.clickAndColletButton}
          title={I18n.t("restaurantDetail.clickAndCollect")}
          index={CLICK_COLLECT_INDEX}
          callback={onSelect}
          isSelected={!isBookingSelected}
        />
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
  },
  space: {
    width: Spacing.L,
  },
});
