import React from "react";
import { I18n } from "react-redux-i18n";

export const Delete: React.FC = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("profile.delete")}</title>
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Profil-compte_Default_Holding_HoldingBadge_Profils-Copy"
        transform="translate(-953.000000, -588.000000)"
      >
        <g
          id="delete_outline_black_24dp"
          transform="translate(953.000000, 588.000000)"
        >
          <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 L6,7 L6,19 Z M8,9 L16,9 L16,19 L8,19 L8,9 Z M15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 L15.5,4 Z"
            id="Shape"
            fill="#000000"
            fill-rule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
