export const Colors = {
  black: "#000",
  white: "#FFF",
  middleGreen: "#509E58",
  lightWhite: "#F9F9F9",
  lightGray: "#6C6A6A",
  middleGray: "#D8D8D8",
  darkGray: "#383838",
  foodiBlack: "#090909",
  foodiBlackOpacity: (opacity: number) => `hsla(0, 0%, 4%, ${opacity})`,
  background1: "#F5F4F4",
  border1: "#979797",
  disabledBackground: "#DDDDDD",
  redError: "#CD3C14",
  footerGray: "#1D2C39",
  footerAppsGray: "#ADAFB3",
  footerBackgroundGray: "#EEEEEE",
  darkGrey: "#999999",
  restaurantCardStatusGreen: "#509E50",
  lightBlue: "#09B4D1",
  navyBlue: "#00345B",
  foodiDefault: "#F4B83A",
  blueDuck: "#015E71",
  orange: "#F08243",
  darkMagenta: "#9D619B",
  purple: "#E5D5E4",
  pewter: "#7B7B7B",
  blue1: "#527EDB",
  tagsGray: "#E9EBEF",
  blueLink: "#0084FF",
  gray1: "#666666",
  black1: "#232323",
  transparent: "transparent",
};
