import { Colors } from "@constants";
import { RefillViewModel } from "@modules";
import React, { useState } from "react";
import { Platform, StyleSheet, TextInput, View, ViewStyle } from "react-native";

interface IProps {
  invalidInput: boolean;
  forwardTestID?: string;
  style?: ViewStyle;
  textStyle?: ViewStyle;
  setInvalidInput?: Function;
  handleOtherValue: (value: number) => void;
}

export const RefillTextInput: React.FC<IProps> = React.memo(
  ({
    invalidInput,
    style,
    forwardTestID,
    setInvalidInput,
    handleOtherValue,
  }) => {
    const [value, setValue] = useState<string>("0");
    const refillVM = new RefillViewModel();

    const onChangeBalance = (balance: string) => {
      let balanceNum = parseInt(balance.replace(/€/g, ""));
      balanceNum = isNaN(balanceNum) ? 0 : balanceNum;
      handleOtherValue(balanceNum);
      setValue(`${balanceNum}`);
      setInvalidInput?.(refillVM.isSurpassLimitBalance(balanceNum));
    };

    return (
      <View
        style={[styles.container, style, invalidInput && styles.invalidInput]}
      >
        <TextInput
          testID={forwardTestID}
          style={styles.textInput}
          onChangeText={onChangeBalance}
          keyboardType="numeric"
          value={`${value}€`}
          selection={{ start: value.length }}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    margin: 10,
    backgroundColor: Colors.white,
    height: 50,
    width: 200,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.border1,
  },
  textInput: {
    height: 40,
    width: 160,
    fontSize: 24,
    color: Colors.foodiBlack,
    fontWeight: "bold",
    textAlign: "center",
    ...(Platform.OS === "web" && {
      outlineColor: "transparent",
    }),
  },
  invalidInput: {
    borderWidth: 2,
    borderColor: Colors.redError,
  },
  invalidText: {
    color: Colors.redError,
    marginLeft: 2,
  },
  invalidDiv: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
});
