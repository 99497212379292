import { ExternalServicesList, FastImage } from "@atomic";
import { Colors, Spacing } from "@constants";
import {
  PointOfSaleActions,
  PointOfSaleThunks,
  RestaurantCardViewModel,
} from "@modules";
import { NavigationProp, Route } from "@react-navigation/native";
import { GlobalState } from "@redux";
import { Title16, Title18, Title22, Title36 } from "@stylesheets";
import { TestIDs } from "@utils";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
}

export const RestaurantInfo: React.FC<IProps> = React.memo(
  ({ navigation, route }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const pointOfSale = useSelector(
      (state: GlobalState) => state.pointOfSale?.selectedPos?.pointOfSale
    );

    if (!pointOfSale) return null;

    const {
      schedules,
      image,
      description,
      name,
      externalServices,
    } = pointOfSale;

    const restaurantCardViewModel = new RestaurantCardViewModel();

    const posStatus = restaurantCardViewModel.getPosStatusFromSchedules(
      schedules
    );

    const formatScheduleText = restaurantCardViewModel.formatScheduleText(
      schedules
    );

    const posStatusLabel = I18n.t(
      `homepage.restaurantCard.${posStatus.status.toLowerCase()}`
    );

    const status = restaurantCardViewModel.getStatus();
    dispatch(PointOfSaleActions.setPosStatus(status));

    const finalDescription = restaurantCardViewModel.getRestaurantDescription(
      name,
      description
    );

    return (
      <View style={styles.container}>
        {!isMobile && (
          <Title36
            style={styles.title}
            testID={TestIDs.restaurantDetail.texts.title}
          >
            {name}
          </Title36>
        )}
        <View style={styles.detailsContainer}>
          <View>
            <FastImage
              width={481}
              forwardTestID={TestIDs.restaurantDetail.views.image}
              imagePath={image}
            />
          </View>
          {isMobile && (
            <Title22
              isBlack
              style={styles.title}
              testID={TestIDs.restaurantDetail.texts.titleMobile}
            >
              {name}
            </Title22>
          )}
          <View style={styles.rightDiv}>
            <View style={styles.opening}>
              {isMobile ? (
                <>
                  <Title18
                    isBold
                    style={{
                      color: status ? Colors.middleGreen : Colors.redError,
                    }}
                  >
                    {posStatusLabel}
                  </Title18>
                  {formatScheduleText && (
                    <Title18 style={styles.hours}>
                      ⋅{formatScheduleText}
                    </Title18>
                  )}
                </>
              ) : (
                <>
                  <Title22
                    style={{
                      color: status ? Colors.middleGreen : Colors.redError,
                    }}
                  >
                    {posStatusLabel}
                  </Title22>
                  {formatScheduleText && (
                    <Title22 style={styles.hours}>
                      ⋅{formatScheduleText}
                    </Title22>
                  )}
                </>
              )}
            </View>
            {isMobile ? (
              <Title16
                testID={TestIDs.restaurantDetail.texts.content}
                style={styles.content}
              >
                {finalDescription}
              </Title16>
            ) : (
              <Title22
                testID={TestIDs.restaurantDetail.texts.content}
                style={styles.content}
              >
                {finalDescription}
              </Title22>
            )}
            <View style={styles.externalServicesDiv}>
              <ExternalServicesList externalServices={externalServices} />
            </View>
          </View>
        </View>
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      paddingTop: isMobile ? 0 : Spacing.XL,
      paddingBottom: Spacing.XL,
      backgroundColor: Colors.white,
    },
    title: {
      textAlign: "left",
      marginTop: 11,
      paddingLeft: isMobile ? 18 : 0,
    },
    detailsContainer: {
      marginTop: isMobile ? 0 : Spacing.XL,
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "baseline" : "center",
    },
    rightDiv: {
      flex: 1,
      marginLeft: isMobile ? 18 : 130,
      height: "100%",
    },
    externalServicesDiv: {
      marginTop: 15,
    },
    opening: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    content: {
      marginTop: Spacing.M,
      fontFamily: "manrope-regular",
      color: "#313234",
      textAlign: "left",
      paddingRight: isMobile ? 18 : 0,
    },
    hours: {
      color: Colors.foodiBlack,
    },
  });
