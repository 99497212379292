import React from "react";

export const CarouselArrowRight: React.FC = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    
    <g
      id="Design-System"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Icons-pg1" transform="translate(-61.000000, -665.000000)">
        <g
          id="keyboard_arrow_right_black_24dp"
          transform="translate(61.000000, 665.000000)"
        >
          <circle id="Oval" fill="#D8D8D8" cx="12" cy="12" r="12"></circle>
          <polygon
            id="Path"
            fill="#000000"
            fill-rule="nonzero"
            points="9 16.59 13.58 12 9 7.41 10.41 6 16.41 12 10.41 18"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);
