import {
  BookingOrdersResponse,
  OrdersResponse,
  GetOrdersCommand,
  GetBookingOrdersCommand,
  UpsertOrderCommand,
  UpsertOrderResponse,
  CartSummaryAdmissionAndPriceCommand,
  CartSummaryAdmissionAndPriceResponse,
  ConfirmOrderCommand,
  GetOrderCCCommand,
  OrderCC,
  CreateBookingOrderCommand,
  CancelBookingOrderCommand,
  CancelOrderCommand,
  UpdateBookingOrderSlotCommand,
  GetOrderReceiptCommand,
} from "@foodi/core";
import { ThunkResult } from "@redux";
import { displayToastError } from "@utils";

const ThunkActionCreators = {
  getOrders: (
    params: GetOrdersCommand
  ): ThunkResult<Promise<OrdersResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { getOrders } = getDependencies();
    return getOrders.execute(params);
  },
  getBookingOrders: (
    params: GetBookingOrdersCommand
  ): ThunkResult<Promise<BookingOrdersResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { getBookingOrders } = getDependencies();
    return getBookingOrders.execute(params);
  },
  upsertOrder: (
    params: UpsertOrderCommand
  ): ThunkResult<Promise<UpsertOrderResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { upsertOrder } = getDependencies();
      return upsertOrder.execute(params);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  confirmOrder: (
    params: ConfirmOrderCommand
  ): ThunkResult<Promise<UpsertOrderResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { confirmOrder } = getDependencies();
      return confirmOrder.execute(params);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  getCartSummaryAdmissionAndPrice: (
    params: CartSummaryAdmissionAndPriceCommand
  ): ThunkResult<Promise<CartSummaryAdmissionAndPriceResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getCartSummaryAdmissionAndPrice } = getDependencies();
      return getCartSummaryAdmissionAndPrice.execute(params);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  getOrderCC: (
    params: GetOrderCCCommand
  ): ThunkResult<Promise<OrderCC>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getOrderCC } = getDependencies();
      return getOrderCC.execute(params);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  getBooking: (
    params: GetOrderCCCommand
  ): ThunkResult<Promise<OrderCC>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getBooking } = getDependencies();
      return getBooking.execute(params);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  createBookingOrder: (
    params: CreateBookingOrderCommand
  ): ThunkResult<Promise<UpsertOrderResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { createBookingOrder } = getDependencies();
    return createBookingOrder.execute(params);
  },
  getOrderReceipt: (
    params: GetOrderReceiptCommand
  ): ThunkResult<Promise<string>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getOrderReceipt } = getDependencies();
      return getOrderReceipt.execute(params);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  updateBookingOrderSlot: (
    params: UpdateBookingOrderSlotCommand
  ): ThunkResult<Promise<UpsertOrderResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { updateBookingOrderSlot } = getDependencies();
    return updateBookingOrderSlot.execute(params);
  },
  cancelBookingOrder: (
    params: CancelBookingOrderCommand
  ): ThunkResult<Promise<UpsertOrderResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { cancelBookingOrder } = getDependencies();
      return cancelBookingOrder.execute(params);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  cancelOrder: (
    params: CancelOrderCommand
  ): ThunkResult<Promise<UpsertOrderResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { cancelOrder } = getDependencies();
      return cancelOrder.execute(params);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
};

export { ThunkActionCreators as OrderThunks };
