import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
  Text,
} from "react-native";
import { Title16, Title18, Title22, Title30 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import { I18n, setLocale } from "react-redux-i18n";
import { TestIDs } from "@utils";
import { LocalLoader, SwitchItem } from "@atomic";
import { ILanguage, IOptinCode } from "@foodi/core";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { OptinsViewModel, ProfileThunks } from "@modules/profile";
import { Back, FlagFR, FlagUK } from "@assets";
import { AuthThunks } from "@modules";
import { useDevices } from "@hooks";

export const OPTINS_CODE: { [key in IOptinCode]: key } = {
  FOODI_MARKETING: "FOODI_MARKETING",
  FOODI_TOS: "FOODI_TOS",
  MAIL_FOODI_MARKETING: "MAIL_FOODI_MARKETING",
  MAIL_LOW_BALANCE: "MAIL_LOW_BALANCE",
  PUSH_FOODI_MARKETING: "PUSH_FOODI_MARKETING",
  PUSH_LOW_BALANCE: "PUSH_LOW_BALANCE",
  FOODI_ALLERGEN_DISCLAIMER: "FOODI_ALLERGEN_DISCLAIMER",
  AGE_VALIDATED: "AGE_VALIDATED",
};

const allOptins = {
  FOODI_MARKETING: false,
  FOODI_TOS: true,
  MAIL_FOODI_MARKETING: false,
  MAIL_LOW_BALANCE: false,
  PUSH_FOODI_MARKETING: false,
  PUSH_LOW_BALANCE: false,
  FOODI_ALLERGEN_DISCLAIMER: false,
  AGE_VALIDATED: false,
};

interface IProps {
  handleShowLeftContainer: () => void;
}

export const SettingsScreen: React.FC<IProps> = React.memo(
  ({ handleShowLeftContainer }) => {
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const dispatch = useDispatch();
    const canUpdateOptins = useRef(false);
    const canUpdateLanguage = useRef(false);
    const canUpdateVirtualTicket = useRef(false);

    const Title: React.FC<any> = isMobile ? Title22 : Title30;
    const Info: React.FC<any> = isMobile ? Title18 : Title22;

    const userOptins =
      useSelector((state: GlobalState) => state.auth?.userInfo?.userOptins) ??
      [];
    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );
    const userVirtualTicket =
      useSelector(
        (state: GlobalState) => state.auth?.userInfo?.virtualTicket
      ) ?? false;

    const idGuest = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.idGuest
    );

    const [isLoading, setLoading] = useState<boolean>(false);

    const [optins, setOptins] = useState<{ [key in IOptinCode]: boolean }>(
      OptinsViewModel.initOptins(allOptins, userOptins)
    );
    const [language, setLanguage] = useState<ILanguage>(
      userLanguage as ILanguage
    );
    const [virtualTicket, setVirtualTicket] = useState<boolean>(
      userVirtualTicket
    );

    const onChangeSwitch = (optinCode: IOptinCode, value: boolean) => {
      setOptins({ ...optins, [optinCode]: value });
    };

    useEffect(() => {
      canUpdateLanguage.current && updateLanguage();
      canUpdateLanguage.current = true;
    }, [language]);

    useEffect(() => {
      canUpdateOptins.current && updateOptins();
      canUpdateOptins.current = true;
    }, [optins]);

    useEffect(() => {
      canUpdateVirtualTicket.current && updateVirtualTicket();
      canUpdateVirtualTicket.current = true;
    }, [virtualTicket]);

    const updateLanguage = async () => {
      setLoading(true);
      await dispatch(ProfileThunks.updateLanguage({ language: language }));
      await dispatch(setLocale(language));
      await dispatch(AuthThunks.getUserInfo());
      setLoading(false);
    };

    const updateOptins = async () => {
      try {
        setLoading(true);
        await dispatch(
          ProfileThunks.updateOptins({
            optins: OptinsViewModel.getSelectedOptins(optins),
          })
        );
        await dispatch(AuthThunks.getUserInfo());
        setLoading(false);
      } catch (e) {
        canUpdateOptins.current = false;
        setOptins(OptinsViewModel.initOptins(allOptins, userOptins));
      }
    };

    const updateVirtualTicket = async () => {
      setLoading(true);
      await dispatch(
        ProfileThunks.updateVirtualTicket({
          virtualTicket,
        })
      );
      await dispatch(AuthThunks.getUserInfo());
      setLoading(false);
    };

    /* istanbul ignore next */
    const handleCookiesParameters = () => {
      // this is required because we need to force the reload of one trust functions to be able to display the cookies preferences on click
      (window as any)?.reloadOTBanner?.();
    };

    return (
      <View>
        {isMobile && (
          <Pressable
            style={styles.goBackContainer}
            testID={TestIDs.profile.actions.goBack}
            onPress={handleShowLeftContainer}
          >
            <Back />
            <Text style={styles.goBackText}>{I18n.t("common.return")}</Text>
          </Pressable>
        )}
        <View
          testID={TestIDs.profile.views.settingView}
          style={styles.boxContainer}
        >
          {isMobile && (
            <Title30 isBold style={styles.marginTitle}>
              {I18n.t("profile.settings")}
            </Title30>
          )}
          <Title isBlack isBold>
            {I18n.t("profile.myNotifications")}
          </Title>
          <View style={styles.block}>
            <Info isBold isBlack>
              {I18n.t("profile.tipsAndServices")}
            </Info>
            <Title16 style={styles.infoNameDesc}>
              {I18n.t("profile.tipsAndServicesDesc")}
            </Title16>
            <SwitchItem
              forwardTestID={TestIDs.profile.actions.notificationMobile}
              title={I18n.t("profile.mobileNotfi")}
              switchValue={optins[OPTINS_CODE.PUSH_FOODI_MARKETING]}
              onValueChange={(value: boolean) => {
                onChangeSwitch(OPTINS_CODE.PUSH_FOODI_MARKETING, value);
              }}
            />
            <SwitchItem
              forwardTestID={TestIDs.profile.actions.email}
              title={I18n.t("profile.email")}
              switchValue={optins[OPTINS_CODE.MAIL_FOODI_MARKETING]}
              onValueChange={(value: boolean) => {
                onChangeSwitch(OPTINS_CODE.MAIL_FOODI_MARKETING, value);
              }}
            />
          </View>
          <View style={styles.block}>
            <Info isBold isBlack>
              {I18n.t("profile.lowBalanceAlert")}
            </Info>
            <Title16 style={styles.infoNameDesc}>
              {I18n.t("profile.lowBalanceAlertDesc")}
            </Title16>
            <SwitchItem
              forwardTestID={TestIDs.profile.actions.notificationMobileBalance}
              title={I18n.t("profile.mobileNotfi")}
              switchValue={optins[OPTINS_CODE.PUSH_LOW_BALANCE]}
              onValueChange={(value: boolean) => {
                onChangeSwitch(OPTINS_CODE.PUSH_LOW_BALANCE, value);
              }}
            />
          </View>
        </View>

        {isMobile && <View style={styles.separator} />}

        <View style={styles.boxContainer}>
          <Title
            testID={TestIDs.profile.texts.settingsLanguageTitle}
            isBlack
            isBold
          >
            {I18n.t("profile.languages")}
          </Title>
          <View style={styles.languageBlock}>
            <TouchableOpacity
              style={[styles.language]}
              onPress={() => setLanguage(ILanguage.fr)}
            >
              <View style={styles.flag}>
                <FlagFR />
              </View>
              {language === ILanguage.fr && (
                <View style={styles.selectedLanguage} />
              )}
            </TouchableOpacity>
            <TouchableOpacity
              testID={TestIDs.profile.actions.chooseLanguage}
              onPress={() => setLanguage(ILanguage.en)}
              style={[styles.language]}
            >
              <View style={styles.flag}>
                <FlagUK />
              </View>
              {language !== ILanguage.fr && (
                <View style={styles.selectedLanguage} />
              )}
            </TouchableOpacity>
          </View>
        </View>

        {isMobile && <View style={styles.separator} />}

        <View style={styles.boxContainer}>
          <Title isBlack isBold>
            {I18n.t("profile.cookies")}
          </Title>
          <div
            className="ot-sdk-show-settings-element"
            onMouseOver={handleCookiesParameters}
          >
            <Pressable>
              <Title18 color={Colors.blueLink} isBold style={styles.blueLink}>
                {I18n.t("profile.parameterize")}
              </Title18>
            </Pressable>
          </div>
        </View>
        {isLoading && (
          <View style={styles.loader}>
            <LocalLoader />
          </View>
        )}
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    boxContainer: {
      flex: 1,
      width: isMobile ? "90vw" : "100%",
      borderWidth: isMobile ? 0 : 1,
      borderRadius: 8,
      borderColor: Colors.darkGrey,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingHorizontal: isMobile ? 0 : 40,
      paddingVertical: isMobile ? 0 : 20,
      marginBottom: 20,
    },
    block: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: isMobile ? 10 : 20,
    },
    fullName: {
      fontWeight: "200",
      marginTop: Spacing.S,
    },
    accountInfo: {
      marginTop: Spacing.M,
      marginBottom: Spacing.L,
      justifyContent: "center",
      alignItems: "center",
    },
    iconDiv: {
      height: 120,
      width: 120,
      borderRadius: 60,
      borderWidth: 1,
      borderColor: Colors.foodiBlack,
      justifyContent: "center",
      alignItems: "center",
    },
    infoDiv: {
      flexDirection: "row",
      marginTop: Spacing.M,
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
    },
    infoNameDesc: {
      marginTop: Spacing.S,
      color: Colors.foodiBlack,
    },
    infoNameSwitch: {
      color: Colors.foodiBlack,
      marginRight: Spacing.M,
      fontFamily: "manrope-bold",
    },
    infoDetail: {
      color: Colors.foodiBlack,
      fontFamily: "manrope",
    },
    languageBlock: {
      flexDirection: "row",
      marginTop: isMobile ? 20 : 0,
    },
    language: {
      marginLeft: Spacing.M,
      borderColor: Colors.foodiBlack,
      justifyContent: "center",
      alignItems: "center",
      height: 46,
      width: 46,
      borderRadius: 23,
    },
    flag: {
      height: 40,
      width: 40,
      borderRadius: 20,
      overflow: "hidden",
      justifyContent: "center",
      alignItems: "center",
    },
    selectedLanguage: {
      height: 46,
      width: 46,
      borderRadius: 23,
      borderWidth: 4,
      borderColor: Colors.foodiDefault,
      position: "absolute",
    },
    blueLink: {
      textDecorationLine: "underline",
      marginTop: 3,
    },
    goBackContainer: {
      flexDirection: "row",
      alignItems: "center",
      maxWidth: 250,
      marginBottom: 10,
    },
    goBackText: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 30,
      marginLeft: 7,
    },
    separator: {
      width: "90vw",
      height: 1,
      backgroundColor: Colors.disabledBackground,
      marginVertical: Spacing.L,
    },
    marginTitle: {
      marginBottom: 20,
    },
    loader: {
      height: "100%",
      width: "100%",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
    },
  });
