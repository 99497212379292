import React from "react";
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { Title12, Title16 } from "@stylesheets";
import { FastImage } from "@atomic";
import { Colors } from "@constants";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { I18n } from "react-redux-i18n";
import { getLabelFromWithdrawalType } from "@utils";
import { OfferTemplateWithdrawalType } from "@foodi/core";

interface IProps {
  index: number;
  title: string;
  type: string;
  style?: ViewStyle;
  forwardTestID?: string;
  image: string;
  isSelected: boolean;
  onSelected: Function;
}

export const Offer: React.FC<IProps> = React.memo(
  ({
    style,
    forwardTestID,
    image,
    isSelected,
    title,
    onSelected,
    index,
    type,
  }) => {
    const onSelectOffer = () => {
      onSelected(index);
    };
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );

    return (
      <TouchableOpacity
        onPress={onSelectOffer}
        testID={forwardTestID}
        style={[
          styles.container,
          style,
          isSelected && {
            borderColor:
              brandTheme.buttonActionColors?.backgroundColor ||
              Colors.foodiDefault,
          },
        ]}
      >
        <FastImage imagePath={image} width={120} style={styles.image} />
        <View style={styles.titleDiv}>
          <Title16 style={styles.title} numberOfLines={2} ellipsizeMode="tail">
            {title}
          </Title16>
          <Title12 style={styles.title}>
            {I18n.t(
              `restaurantDetail.tagTypeLegend.${getLabelFromWithdrawalType(
                type as OfferTemplateWithdrawalType
              )}`
            )}
          </Title12>
        </View>
      </TouchableOpacity>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    height: 100,
    borderWidth: 5,
    borderRadius: 8,
    width: 245,
    marginRight: 5,
    borderColor: "transparent",
    flexDirection: "row",
    justifyContent: "center",
    overflow: "hidden",
  },
  image: {
    height: 90,
    width: 110,
    backgroundColor: Colors.white,
    borderRadius: 0,
  },
  titleDiv: {
    justifyContent: "space-between",
    paddingVertical: 5,
    flex: 1,
    backgroundColor: Colors.foodiBlack,
  },
  title: {
    color: Colors.white,
    marginHorizontal: 10,
    textAlign: "left",
    fontFamily: "manrope-bold",
  },
});
