import { VisibilityOff, VisibilityOn } from "@assets";
import { LabelIconError } from "@atomic";
import { Colors } from "@constants";
import { responsiveSizeWidth, useDevices } from "@hooks";
import { TestIDs } from "@utils";
import React, { useRef, useState, useMemo } from "react";
import {
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
  Animated,
  Pressable,
} from "react-native";

interface IProps {
  errorForwardTestID?: string;
  textPlaceHolder?: string;
  secureTextEntry?: boolean;
  forwardTestID?: string;
  forwardPlaceHolderTestID?: string;
  style?: ViewStyle;
  textStyle?: ViewStyle;
  value: string;
  hasError?: boolean;
  errorMessage?: string;
  onChangeValue: (value: string) => void;
  isEdit?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}
const DURATION = 300;

export const FloatTextInput: React.FC<IProps> = React.memo(
  ({
    style,
    forwardTestID,
    forwardPlaceHolderTestID,
    errorForwardTestID,
    textPlaceHolder = "",
    value,
    hasError,
    errorMessage = "",
    onChangeValue,
    secureTextEntry = false,
    isEdit = false,
    onFocus,
    onBlur,
  }) => {
    const [isMobile] = useDevices();

    const [showtext, setShowText] = useState(false);
    const textFontAnim = useRef(new Animated.Value(16)).current;
    const textTopAnim = useRef(new Animated.Value(15)).current;
    const styles = useMemo(() => _styles(isMobile, hasError), [
      isMobile,
      hasError,
    ]);

    const onChangevalue = (value: string) => {
      onChangeValue(value);
    };

    const textFontDecrease = () => {
      onFocus?.();
      Animated.timing(textFontAnim, {
        toValue: 10,
        duration: DURATION,
        useNativeDriver: false,
      }).start();
      Animated.timing(textTopAnim, {
        toValue: 5,
        duration: DURATION,
        useNativeDriver: false,
      }).start();
    };

    const textFontIncrease = () => {
      onBlur?.();
      if (value !== "") return;
      Animated.timing(textFontAnim, {
        toValue: 16,
        duration: DURATION,
        useNativeDriver: false,
      }).start();
      Animated.timing(textTopAnim, {
        toValue: 15,
        duration: DURATION,
        useNativeDriver: false,
      }).start();
    };

    React.useEffect(() => {
      if (isEdit || value) textFontDecrease();
    }, [isEdit]);

    return (
      <View style={[styles.mainContainer]}>
        <View style={[styles.container, style]}>
          <TextInput
            testID={forwardTestID}
            style={styles.textInput}
            onChangeText={onChangevalue}
            value={value}
            onFocus={() => textFontDecrease()}
            onBlur={() => textFontIncrease()}
            secureTextEntry={secureTextEntry && !showtext}
          />
          <Animated.Text
            testID={forwardPlaceHolderTestID}
            style={[
              styles.textPlaceHolder,
              { top: textTopAnim, fontSize: textFontAnim },
            ]}
          >
            {textPlaceHolder}
          </Animated.Text>
          {secureTextEntry ? (
            <Pressable
              testID={TestIDs.components.FloatTextInput.actions.showText}
              style={styles.iconContainer}
              onPress={() => setShowText(!showtext)}
            >
              {showtext ? <VisibilityOn /> : <VisibilityOff />}
            </Pressable>
          ) : null}
        </View>
        {errorMessage && hasError && (
          <LabelIconError
            forwardTestID={errorForwardTestID}
            title={errorMessage}
            textStyle={styles.labelTextIconError}
            style={styles.labelIconError}
          />
        )}
      </View>
    );
  }
);

const _styles = (isMobile?: boolean, hasError?: boolean) =>
  StyleSheet.create({
    mainContainer: {
      alignItems: "flex-start",
    },
    container: {
      margin: 10,
      backgroundColor: Colors.white,
      height: 50,
      width: isMobile ? responsiveSizeWidth(320) : 350,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
      borderWidth: 1,
      borderColor: hasError ? Colors.redError : Colors.darkGrey,
    },
    textInput: {
      height: 50,
      width: isMobile ? responsiveSizeWidth(320) : 350,
      fontSize: 16,
      zIndex: 1,
      color: Colors.foodiBlack,
      fontWeight: "400",
      borderRadius: 8,
      paddingHorizontal: 17,
      paddingTop: 25,
      paddingBottom: 14,
    },
    textPlaceHolder: {
      position: "absolute",
      left: 14,
      color: Colors.darkGrey,
    },
    iconContainer: {
      position: "absolute",
      width: 25,
      height: 20,
      marginRight: 10,
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,
      right: 0,
      color: Colors.darkGrey,
    },
    labelIconError: {
      marginLeft: isMobile ? 0 : 10,
      alignItems: "flex-start",
    },
    labelTextIconError: {
      fontSize: 13,
      textAlign: "left",
    },
  });
