export const certificationsMapper = {
  AOCP: 'aocp',
  SUSTAINABLE_AGRICULTURE: 'sustainableAgriculture',
  BIO: 'bio',
  CASHER: 'casher',
  CERTIFIED: 'certified',
  BIO_CONVERSION: 'bioConversion',
  FAIR_TRADE: 'fairTrade',
  HALAL: 'halal',
  IGP: 'igp',
  RED_LABEL: 'redLabel',
  FRENCH_PRODUCTS: 'frenchProducts',
  SUSTAINABLE_SEAFOOD: 'sustainableSeafood',
  MEAT: 'meat',
  REGIONAL: 'regional',
  EU: 'eu',
  FRENCH_BOVINE_MEET: 'frenchBovineMeet',
  FRENCH_MEAT: 'frenchMeat',
  MSC: 'msc',
  BLUE_WHITE_HEARTH: 'blueWhiteHearth',
  ECOLABEL: 'ecolabel',
  HVE: 'hve',
  FRENCH_PORK: 'frenchPork',
  AOP: 'aop',
  FRENCH_POULTRY: 'frenchPoultry',
  INFO_INCOMPLETE: 'infoIncomplete',
  INFO_NOT_DISCLOSED: 'infoNotDisclosed'
};
