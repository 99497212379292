import React from "react";
import {
  Wrapper,
  Container,
  Row,
  TitleContainer,
  ResumeContainer,
  IconContainer,
  ImageContainer,
  TagIconContainer,
  Tag,
  TagTextContainer,
} from "./Infonews.style";
import { ArrowRight, Images } from "@assets";
import { GlobalState } from "@redux";
import { useSelector } from "react-redux";
import { Title16, Title18, Title22 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import { InfonewsTag } from "@assets";
import { useDevices } from "@hooks";

interface IProps {
  contentTitle?: string;
  resume?: string;
  linkToExternalPage?: string;
  forwardTestId?: string;
}

export const Infonews: React.FC<IProps> = React.memo(
  ({ contentTitle, resume, linkToExternalPage, forwardTestId }) => {
    const { backgroundColor, textColor } =
      useSelector(
        (state: GlobalState) => state.brandTheme?.brandTheme?.infoNewsColors
      ) || {};

    const [isMobile] = useDevices();

    const handleSeeMore = () => {
      window.open(linkToExternalPage, "_blank")?.focus();
    };

    const TitleResume: React.FC<any> = isMobile ? Title16 : Title18;
    const TitleSeeMore: React.FC<any> = isMobile ? Title16 : Title22;
    const backgroundImageWidth = isMobile ? 378 : 560;
    const backgroundImageHeight = isMobile ? 232 : 400;

    return (
      <Wrapper testID={`${forwardTestId}_CONTAINER`} isMobile={isMobile}>
        <ImageContainer>
          <Images.InfonewsBackground
            color={backgroundColor}
            width={backgroundImageWidth}
            height={backgroundImageHeight}
          />
        </ImageContainer>
        <Tag isMobile={isMobile}>
          <TagIconContainer>
            <InfonewsTag />
          </TagIconContainer>
          <TagTextContainer>
            <Title16>{I18n.t("homepage.infoNews")}</Title16>
          </TagTextContainer>
        </Tag>
        <Container isMobile={isMobile}>
          {contentTitle && (
            <TitleContainer>
              <Title22
                numberOfLines={1}
                ellipsizeMode="tail"
                color={textColor}
                textAlign="left"
              >
                {contentTitle}
              </Title22>
            </TitleContainer>
          )}
          {resume && (
            <ResumeContainer isMobile={isMobile}>
              <TitleResume
                color={textColor}
                numberOfLines={5}
                ellipsizeMode="tail"
              >
                {resume}
              </TitleResume>
            </ResumeContainer>
          )}
          {linkToExternalPage && (
            <Row testID={`${forwardTestId}_ACTION`} onPress={handleSeeMore}>
              <TitleSeeMore color={textColor} bold>
                {I18n.t("homepage.seeMore")}
              </TitleSeeMore>
              <IconContainer>
                <ArrowRight color={textColor} />
              </IconContainer>
            </Row>
          )}
        </Container>
      </Wrapper>
    );
  }
);
