import { View, StyleSheet } from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { Carousel, Offer } from "@atomic";
import {
  BookingActions,
  IOrderableOffer,
  OffersActions,
  OfferViewModel,
} from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState, LoaderActions } from "@redux";
import { ILanguage, ActiveOffer, IOfferTemplate } from "@foodi/core";
import { TestIDs } from "@utils";

type IOffer = IOfferTemplate & {
  isSelected: boolean;
  published: boolean;
};

interface IProps {
  isRefillFromCart?: boolean;
  offers: IOfferTemplate[];
  setIsOfferFetching: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Offers: React.FC<IProps> = React.memo(
  ({ isRefillFromCart, offers, setIsOfferFetching }) => {
    const prevSelectedOffer = useRef<IOfferTemplate | undefined>();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: GlobalState) => state.auth?.userInfo);
    const userLanguage = (userInfo?.language as ILanguage) || ILanguage.fr;
    const pointOfSale = useSelector(
      (state: GlobalState) => state.pointOfSale?.selectedPos?.pointOfSale
    );
    const selectedOffer = useSelector(
      (state: GlobalState) => state.offers?.selectedOffer
    );
    const [offerVM] = useState(new OfferViewModel(dispatch));

    const resetOffers = async (offer: IOffer | IOfferTemplate) => {
      setIsOfferFetching(true);
      dispatch(BookingActions.setSelectedDay(0));
      const activeOffer: ActiveOffer | null =
        offer?.daysInAdvanceStart === 0
          ? await offerVM.getOffer(offer?.nextOrderableOffers?.[0]?.id || "")
          : null;
      dispatch(
        OffersActions.setNextOrderableOffers(
          (offer?.nextOrderableOffers as IOrderableOffer[]) ?? []
        )
      );
      dispatch(OffersActions.setActiveOrderableOffer(activeOffer || null));
      setIsOfferFetching(false);
    };

    useEffect(() => {
      if ((!offers && !offers?.[0]) || isRefillFromCart) return;
      const firstOffer =
        isRefillFromCart && selectedOffer ? selectedOffer : offers[0];
      dispatch(OffersActions.setSelectedOffer(firstOffer));
      resetOffers(firstOffer);
      prevSelectedOffer.current = firstOffer;
    }, [offers]);

    const onSelectOffer = async (_selectedOffer: IOffer) => {
      await resetOffers(_selectedOffer);
      if (
        prevSelectedOffer.current?.nextOrderableOffers?.[0]?.id ===
          _selectedOffer.nextOrderableOffers?.[0]?.id &&
        prevSelectedOffer.current?.name === _selectedOffer.name
      )
        return;
      dispatch(OffersActions.setSelectedOffer(_selectedOffer));
      dispatch(OffersActions.initOrderItems());
      prevSelectedOffer.current = _selectedOffer;
    };

    const renderItems = (
      _offers: IOffer[],
      onSelectedItem: (selectedElementIndex: number) => void
    ) => {
      return _offers?.filter(offer => offer.published).map((offer: IOffer, index: number) => (
        <Offer
          forwardTestID={`${TestIDs.restaurantDetail.actions.selectOffer}${offer.name}`}
          key={index}
          index={index}
          title={offer.name}
          type={offer.withdrawalType || ""}
          image={offer.image.path}
          isSelected={offer.isSelected}
          onSelected={onSelectedItem}
        />
      ));
    };

    return (
      <View>
        {offers && (
          <View style={styles.offerDiv}>
            <Carousel
              items={offers}
              itemWidth={250}
              onSelectItem={onSelectOffer}
              renderItems={renderItems}
              width={500}
              height={100}
              hasBorder
            />
          </View>
        )}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  offerDiv: {
    marginTop: 50,
  },
});
