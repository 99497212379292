import React, { useEffect, useMemo } from "react";
import { Pressable, StyleSheet, View, Text } from "react-native";
import { Title16, Title22, Title36 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import { I18n } from "react-redux-i18n";
import { Back, CheckCircle, CheckCircleMobile, Warning } from "@assets";
import { useDispatch, useSelector } from "react-redux";
import { NavigationProp, Route } from "@react-navigation/native";
import { useDevices } from "@hooks";
import {
  AccountThunks,
  BalanceScreens,
  HomeScreens,
  POSScreens,
  TransactionsActions,
} from "@modules";
import { ScreenWithFooter } from "@atomic";
import { GlobalState } from "@redux";
import { getDecodedId, TestIDs } from "@utils";

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
}

export const SucceedRefill: React.FC<IProps> = React.memo(
  ({ navigation, route }) => {
    //@ts-ignore
    const { successMessage = "refill.successTitle" } = route?.params || {};
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);
    const dispatch = useDispatch();

    const id =
      useSelector((state: GlobalState) => state.auth?.userInfo?.id) ?? "";
    const refillFromCart = useSelector(
      (state: GlobalState) => state.transactions?.refillFromCart
    );
    const posId = useSelector(
      (state: GlobalState) => state.offers?.selectedOffer?.pos?.id
    );

    useEffect(() => {
      dispatch(
        AccountThunks.getBalance({
          id,
        })
      );
      if (refillFromCart) {
        dispatch(TransactionsActions.setRefillFromCart(false));
        const idPos = getDecodedId(posId);
        navigation?.navigate(HomeScreens.POINT_OF_SALE_STACK, {
          screen: POSScreens.RESTAURANT_DETAIL,
          params: {
            id: idPos?.split?.(":")?.[1],
            isModify: false,
            isRefillFromCart: true,
          },
        });
      }
      return () => {
        if (refillFromCart)
          dispatch(TransactionsActions.setRefillFromCart(false));
      };
    }, []);

    const handleGoBack = () => {
      navigation?.navigate(BalanceScreens.BALANCE_MAIN_SCREEN);
    };

    const handleEmailTo = () => {
      window.open("mailto:help@foodi.fr");
    };

    const isDelayError = successMessage === "refill.delayTitle";

    return (
      <ScreenWithFooter navigation={navigation}>
        <View style={styles.container}>
          <Pressable
            style={styles.return}
            testID={TestIDs.home.balance.actions.errorReceiptGoBack}
            onPress={handleGoBack}
          >
            <Back />
            <Text style={styles.text}>{I18n.t("common.return")}</Text>
          </Pressable>
          <View style={styles.icon}>
            {isMobile ? <CheckCircleMobile /> : <CheckCircle />}
          </View>
          {isMobile ? (
            <>
              <Title22 isBlack style={styles.title}>
                {I18n.t("refill.refillSucceedTitleMobile")}
              </Title22>
              <View style={styles.messageContainer}>
                {isDelayError && (
                  <View style={styles.warningIcon}>
                    <Warning />
                  </View>
                )}
                <Title16 style={styles.subTitle}>
                  {I18n.t(successMessage)}
                  {isDelayError && (
                    <>
                      {" "}
                      <Text style={styles.emailTo} onPress={handleEmailTo}>
                        help@foodi.fr
                      </Text>
                    </>
                  )}
                </Title16>
              </View>
            </>
          ) : (
            <>
              <Title36 style={styles.title}>
                {I18n.t("refill.refillSucceedTitle")}
              </Title36>
              <View style={styles.messageContainer}>
                {isDelayError && (
                  <View style={styles.warningIcon}>
                    <Warning />
                  </View>
                )}
                <Title22 isBlack isNotBold style={styles.subTitle}>
                  {I18n.t(successMessage)}
                  {isDelayError && (
                    <>
                      {" "}
                      <Text style={styles.emailTo} onPress={handleEmailTo}>
                        help@foodi.fr
                      </Text>
                    </>
                  )}
                </Title22>
              </View>
            </>
          )}
        </View>
      </ScreenWithFooter>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.white,
      paddingHorizontal: isMobile ? 18 : 150,
      paddingVertical: isMobile ? 20 : 45,
    },
    title: {
      marginTop: isMobile ? 60 : Spacing.M,
      marginBottom: isMobile ? 40 : Spacing.M,
      letterSpacing: isMobile ? 0 : 1,
    },
    subTitle: {
      marginLeft: 7,
    },
    return: {
      flexDirection: "row",
      alignItems: "center",
      maxWidth: "250px",
    },
    text: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: isMobile ? 16 : 22,
      letterSpacing: 0,
      lineHeight: isMobile ? 22 : 30,
      marginLeft: 7,
    },
    icon: {
      alignItems: "center",
      marginTop: 100,
    },
    emailTo: {
      color: Colors.redError,
    },
    warningIcon: {
      top: isMobile ? 2 : 5,
    },
    messageContainer: {
      flexDirection: "row",
      justifyContent: "center",
      marginTop: isMobile ? 13 : 5,
      maxWidth: isMobile ? 356 : 862,
      alignSelf: "center",
    },
  });
