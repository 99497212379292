import { View, StyleSheet } from "react-native";
import React, { useMemo, useState } from "react";
import { CalendarDays, Cart } from "@atomic";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { Information } from "@assets/icons/Information";
import { Title16, Title22, Title36 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import { Colors, Spacing } from "@constants";
import {
  CalendarFlow,
  OffersActions,
  OfferViewModel,
  PointOfSaleActions,
} from "@modules";
import { OfferProductsList } from "@atomic/organisms/OfferProductsList";
import { NavigationProp, Route } from "@react-navigation/native";
import { useDevices } from "@hooks";
import { FormulaApp, LocalLoader } from "@atomic/molecules";
import { ActiveOffer, OfferTemplateSalesType } from "@foodi/core";
import { classPrivateMethod } from "@babel/types";

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
  isRefillFromCart?: boolean;
  isOfferFetching: boolean;
}

export const OfferOderContainer: React.FC<IProps> = React.memo(
  ({ navigation, route, isRefillFromCart, isOfferFetching }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);
    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );
    const offers = useSelector((state: GlobalState) => state.offers);
    const selectedOffer = offers.selectedOffer;
    const selectedActiveOffer = offers.activeOrderableOffer;
    const orderItems = offers.orderItems;

    const cartCurrentInfo = useSelector(
      (state: GlobalState) => state.pointOfSale?.cartCurrentInfo
    );
    const [offerVM] = useState(new OfferViewModel());

    const offerItems = offerVM.getGroupedProducts(
      selectedActiveOffer?.offerItems
    );

    const selectedDay = useSelector(
      (state: GlobalState) => state.booking?.selectedDay ?? 0
    );

    const hasContainers = offerVM.hasContainers(
      selectedActiveOffer?.offerItems
    );

    const pointOfSale = useSelector(
      (state: GlobalState) => state.pointOfSale?.selectedPos?.pointOfSale
    );

    React.useEffect(() => {
      if (selectedOffer && !isRefillFromCart) {
        dispatch(OffersActions.initOrderItems());
        dispatch(OffersActions.resetOutOfStockOrderItems());
      }
      return () => {
        if (!isRefillFromCart) {
          dispatch(OffersActions.initOrderItems());
          dispatch(OffersActions.resetOutOfStockOrderItems());
        }
      };
    }, [selectedOffer]);

    const isFormulaType =
      selectedOffer?.salesType === OfferTemplateSalesType.BUNDLE;
    const hasAvailableOffer = selectedOffer
      ? offerVM.hasAvailableOffer(selectedOffer)
      : false;

    React.useEffect(() => {
      if (!isRefillFromCart) {
        if (hasAvailableOffer)
          dispatch(PointOfSaleActions.setMiniCartStatus(true));
        else dispatch(PointOfSaleActions.setMiniCartStatus(false));
      }
    }, [hasAvailableOffer]);

    if (isOfferFetching) {
      return (
        <View style={styles.loaderContainer}>
          <LocalLoader containerStyle={{ backgroundColor: "transparent" }} />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        {!!selectedOffer && (
          <View>
            {isMobile ? (
              <Title22 isBlack style={styles.offerTitle}>
                {selectedOffer.name}
              </Title22>
            ) : (
              <Title36 style={styles.offerTitle}>{selectedOffer.name}</Title36>
            )}
            {isFormulaType ? (
              <FormulaApp />
            ) : hasAvailableOffer ? (
              <>
                {selectedOffer?.daysInAdvanceEnd !== 0 && (
                  <CalendarDays
                    style={styles.calendarView}
                    isModify={false}
                    userLanguage={userLanguage}
                    calendarFlow={CalendarFlow.ORDER}
                    isRefillFromCart={isRefillFromCart}
                  />
                )}
                  {
                      selectedActiveOffer && offerVM.isActiveOfferReady(
                          selectedActiveOffer as ActiveOffer
                      ) &&  <View style={[styles.unavailable, { marginTop: 0 }]}>
                          <Title16 style={styles.unavailableText}>
                              {I18n.t("restaurantDetail.offerReady")}
                          </Title16>
                      </View>
                  }
                  {selectedActiveOffer && (offerVM.isActiveOfferReady(
                      selectedActiveOffer as ActiveOffer
                  ) || offerVM.isActiveOfferAvailable(
                      selectedActiveOffer as ActiveOffer
                  )) && (
                          <View style={styles.productsDiv}>
                              {!!offerItems && !!pointOfSale?.zone?.holding && (
                                  <View style={styles.productsList}>
                                      <OfferProductsList
                                          holding={pointOfSale?.zone?.holding}
                                          selectedDay={selectedDay}
                                          offerItems={offerItems}
                                          selectedActiveOfferId={selectedActiveOffer.id}
                                          selectedOfferId={
                                              selectedOffer.id ||
                                              `${selectedOffer.nextOrderableOffer?.id}` ||
                                              selectedOffer.name
                                          }
                                          mealHeartRule={selectedOffer.mealHeartRule}
                                          orderItems={
                                              isRefillFromCart
                                                  ? cartCurrentInfo?.products
                                                  : orderItems
                                          }
                                          hasContainers={hasContainers}
                                      />
                                  </View>
                              )}
                              {!isMobile &&  !offerVM.isActiveOfferReady(
                                  selectedActiveOffer as ActiveOffer
                              ) && (
                                  <View style={styles.cardDiv}>
                                      <Cart
                                          route={route}
                                          navigation={navigation}
                                          products={orderItems}
                                          isRefillFromCart={isRefillFromCart}
                                      />
                                  </View>
                              )}
                          </View>
                      )}
                  {selectedActiveOffer === null && <View style={[styles.unavailable, { marginTop: 0 }]}>
                      <Title16 style={styles.unavailableText}>
                          {I18n.t("restaurantDetail.unavailableOfferCalendar")}
                      </Title16>
                  </View>}
              </>
            ) : (
              <View style={styles.unavailable}>
                <Information />
                <Title16 style={styles.unavailableText}>
                  {I18n.t("restaurantDetail.unavailableOffer")}
                </Title16>
              </View>
            )}
          </View>
        )}
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      backgroundColor: Colors.background1,
      marginTop: isMobile ? 20 : 41,
    },
    unavailable: {
      marginTop: 45,
      flexDirection: "row",
      paddingLeft: isMobile ? 18 : 0,
      width: "100vw",
    },
    unavailableText: {
      marginLeft: 6,
    },
    offerTitle: {
      marginTop: Spacing.M,
      textAlign: "left",
      paddingLeft: isMobile ? 18 : 0,
      width: "100vw",
    },
    productsDiv: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    cardDiv: {
      marginTop: 50,
    },
    productsList: {
      marginRight: 50,
    },
    calendarView: {
      marginVertical: Spacing.XL,
      alignItems: "flex-start",
      paddingLeft: isMobile ? 18 : 0,
    },
    loaderContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
      minHeight: "500px",
    },
  });
