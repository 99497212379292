import React, { useEffect, useRef, useState } from "react";
import { RefillInputs } from "@atomic";
import {
  PaymentsThunks,
  Refills,
  RefillViewModel,
  TransactionsActions,
} from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { submitToUrl } from "@utils/submitToUrl";
import { GlobalState } from "@redux";
import { NavigationProp } from "@react-navigation/core";

interface IProps {
  navigation: NavigationProp<any>;
}

export const RefillsTemplate: React.FC<IProps> = React.memo(
  ({ navigation }) => {
    const dispatch = useDispatch();
    const accessToken = useSelector(
      (state: GlobalState) => state.auth.authToken?.accessToken
    );
    const prevSelectedInput = useRef(-1);
    const refillVM = new RefillViewModel();

    // useState
    const [refills, setRefills] = useState<Refills[]>([]);
    const [isOtherRefill, setOtherRefill] = useState<boolean>(false);
    const otherValue = React.useRef(0);

    // useEffects
    useEffect(() => {
      if (!isOtherRefill) {
        setRefills(refillVM.getInitState());
      } else {
        prevSelectedInput.current = -1;
      }
    }, [isOtherRefill]);

    const onSelectRefillInput = (inputIndex: number) => {
      if (prevSelectedInput.current === inputIndex) return;
      const otherRefillIndex = 3;
      prevSelectedInput.current = inputIndex;
      setRefills(refillVM.getRefillInputs(inputIndex));
      setOtherRefill(inputIndex === otherRefillIndex);
    };

    /* istanbul ignore next */
    const handleValidate = async () => {
      dispatch(TransactionsActions.setMercanetStatus(true));
      const amount = isOtherRefill
        ? `${otherValue.current}`
        : refills?.[prevSelectedInput.current]?.amount;
      if (amount && accessToken) {
        //@ts-ignore
        const { uri, method, body } = await dispatch(
          PaymentsThunks.getPaymentRequest(
            amount,
            accessToken,
            // @ts-ignore
            window.config.MERCANET_CALLBACK_URL
          )
        );
        submitToUrl(uri, method, body);
      }
    };

    const handleOtherValue = (value: number) => {
      otherValue.current = value;
    };

    return (
      <RefillInputs
        onValidate={handleValidate}
        handleOtherValue={handleOtherValue}
        onSelectRefillInput={onSelectRefillInput}
        refills={refills}
        isOtherRefill={isOtherRefill}
        setOtherRefill={setOtherRefill}
        isDisabled={refillVM.noInputIsSelected(refills)}
      />
    );
  }
);
