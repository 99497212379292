export type Environment = {
  ENV_NAME: Environments;
  FOODI_BASE_URL: string;
};

export const Dev: Environment = {
  ENV_NAME: "Dev",
  FOODI_BASE_URL: "http://localhost:3001",
};

export const Qa: Environment = {
  ...Dev,
  ENV_NAME: "Qa",
  FOODI_BASE_URL: "link",
};

export const Preprod: Environment = {
  ...Dev,
  ENV_NAME: "Preprod",
  FOODI_BASE_URL: "link",
};

export const Prod: Environment = {
  ...Dev,
  ENV_NAME: "Prod",
  FOODI_BASE_URL: "link",
};

export const Constants = {
  Dev,
  Qa,
  Preprod,
  Prod,
};

export type Environments = keyof typeof Constants;
