import { OrderItem } from "@foodi/core";
import {
  ActiveOffer,
  getOfferTemplates_list_edges_node_OfferTemplate_nextOrderableOffer_offerItems,
  IOfferTemplate,
  OfferSlot,
} from "@foodi/core";
import _ from "lodash";
import moment from "moment";
import { Dispatch } from "react";
import { I18n } from "react-redux-i18n";
import { IOrderableOffer, OffersThunks } from "../ducks";
import { Holding } from "@foodi/core/lib/domain/entities/Holding";
import {
  IImportationTypes,
  IMPORTATION_TYPES,
  menuFamiliesRank,
} from "@atomic";

export interface ISlots {
  initSlot: string;
  endSlot: string;
  withdrawRange: string;
}

export interface IProduct {
  id: string;
  label: string;
  certifications?: [string];
}

export interface IOfferItem
  extends getOfferTemplates_list_edges_node_OfferTemplate_nextOrderableOffer_offerItems {
  inheritedDescription?: string;
  allergens?: string[];
  certifications?: string[];
  products?: IProduct[];
}

const productFamiliesRank: string[] = [
  "formulas",
  "starters",
  "soups",
  "dishes",
  "sandwiches",
  "salads",
  "sideDishes",
  "dairies",
  "desserts",
  "pastries",
  "fruits",
  "breads",
  "snacks",
  "beverages",
  "miscellaneous",
  "vegetables",
  "cheeses",
  "toTaste",
  "breakfast",
  "condimentTable",
];

export class OfferViewModel {
  constructor(private dispatch?: Dispatch<any>) {}

  hasAvailableOffer = (offer: IOfferTemplate): boolean => {
    const { nextOrderableOffer } = offer;
    const haveAvailableItems = !!nextOrderableOffer?.offerItems.some(({ quantityRemaining }) => quantityRemaining > 0);

    return !!nextOrderableOffer?.available
      && haveAvailableItems
      && !!nextOrderableOffer?.mealHeartOrderAvailable
      && !this.areNextOffersSlotsFullyBooked(offer)
  };

  isActiveOfferAvailable = (activeOffer: ActiveOffer): boolean => {
    if (activeOffer === null) {
      return false;
    }
    const currentDay = moment();
    const [orderStartDate, orderEndDate] = activeOffer?.orderRange?.split("/");
    const haveAvailableItems = !!activeOffer?.offerItems.some(({ quantityRemaining }) => quantityRemaining > 0);

    return (
      !!activeOffer?.published
      && haveAvailableItems
      && currentDay.isBetween(moment(orderStartDate), moment(orderEndDate))
    );
  };

  areNextOffersSlotsFullyBooked = ({
    nextOrderableOffers,
  }: IOfferTemplate): boolean =>
    !!nextOrderableOffers &&
    !nextOrderableOffers
      .some(
        (eachNextOrdableOffer: any) => (
          eachNextOrdableOffer.fullyBooked === false
        ));


  isOrderRangeValid = (orderRange: string): boolean => {
    const [beginDate, endDate] = orderRange.split('/');
    const currentDate = new Date().toISOString();

    return (
      currentDate >= new Date(beginDate).toISOString() &&
      currentDate <= new Date(endDate).toISOString()
    );
  };
  isActiveOfferReady = (activeOffer: ActiveOffer): boolean => {
    if (activeOffer === null) {
      return false;
    }
    const currentDay = moment();
    const [orderStartDate] = activeOffer?.orderRange?.split('/');

    return (
      !!activeOffer?.published &&
      currentDay.isBefore(moment(orderStartDate))
    )
  };

  sortOffers = (offers: IOfferTemplate[]): IOfferTemplate[] =>
    offers.sort((offer1, offer2) => {
      const length1 = offer1?.nextOrderableOffer?.offerItems?.length ?? 0;
      const length2 = offer2?.nextOrderableOffer?.offerItems?.length ?? 0;
      return length2 - length1;
    });

  getDateDescription = (
    selectedDay?: number,
    userLanguage?: string
  ): string => {
    const _selectedDay = selectedDay ?? 0;
    return `${_.upperFirst(
      moment()
        .add(_selectedDay, "days")
        .locale(userLanguage || "fr")
        .format("dddd")
    )} ${moment().add(_selectedDay, "days").format("DD/MM")}`;
  };

  getWithdrawDateFromOfferSlot = (offer?: ISlots | null): string => {
    return offer?.withdrawRange.split("/")?.[1].split("T")?.[0] || "";
  };

  checkIfDayHasOffer = (
    dayToCheck: string,
    nextOrderableOffers: IOrderableOffer[] | null
  ): boolean => {
    const currentDay = moment();
    const formatedCurrentDay = currentDay.format("YYYY-MM-DD");

    const haveOffer =
      (nextOrderableOffers &&
        !!nextOrderableOffers.find(
          ({ withdrawRange, published, orderRange }) => {
            const [startDate, endDate] = withdrawRange.split("/");
            const [orderStartDate, orderEndDate] = orderRange.split("/");
            const endDateWithoutTime = endDate?.split("T")?.[0];

            // for the current day we need to check if the offer is already expired or not
            return formatedCurrentDay === dayToCheck
              ? currentDay.isBetween(moment(startDate), moment(endDate)) &&
                  published
              : endDateWithoutTime === dayToCheck &&
                  currentDay.isBetween(
                    moment(orderStartDate),
                    moment(orderEndDate)
                  ) &&
                  published;
          }
        )) ||
      false;

    return !haveOffer;
  };

  getOfferDayIndex = (
    currentday: string,
    nextOrderableOffers: IOrderableOffer[] | null
  ): number => {
    const haveOffer =
      nextOrderableOffers &&
      nextOrderableOffers.findIndex(
        ({ withdrawRange }) =>
          withdrawRange.split("/")?.[1].split("T")?.[0] === currentday
      );
    return haveOffer || 0;
  };

  async getOffer(idOffer: string): Promise<ActiveOffer> {
    //@ts-ignore
    const { offer } = await this.dispatch(
      OffersThunks.getOffer({
        idOffer,
      })
    );
    return offer;
  }

  getCurrentSlots = (offerSlots: Omit<OfferSlot, "__typename">[]): ISlots[] => {
    return offerSlots
      .filter((o) => {
        const endSlot = o?.withdrawRange?.split("/")?.[1];
        return moment().isSameOrBefore(endSlot);
      })
      ?.map((o) => {
        const slots = o?.withdrawRange?.split("/");

        const initSlot = moment(slots?.[0]).format("HH:mm");
        const endSlot = moment(slots?.[1]).format("HH:mm");

        return {
          initSlot,
          endSlot,
          withdrawRange: o?.withdrawRange,
          selectableAt: o?.selectableAt,
          numOrders: o?.numOrders,
        };
      });
  };

  getWarningMessage = (
    offerSlot: ISlots | null,
    isSelected?: number,
    changeSelection?: boolean,
    fullyBooked?: boolean,
    published?: boolean,
    hasSlots?: boolean,
    bookingForDifferentPos?: boolean,
    isBookingSelected?: boolean
  ) => {
    return !isBookingSelected
      ? isSelected !== undefined
        ? `${I18n.t("restaurantDetail.cart.youChose")}: ${
            offerSlot?.initSlot
          }-${offerSlot?.endSlot}`
        : I18n.t("restaurantDetail.cart.chooseSlot")
      : published
      ? I18n.t("restaurantDetail.bookingUnavailable")
      : bookingForDifferentPos
      ? I18n.t("restaurantDetail.bookingForAnotherPos")
      : !hasSlots
      ? I18n.t("restaurantDetail.bookingExpired")
      : fullyBooked
      ? I18n.t("restaurantDetail.fullyBooked")
      : offerSlot && changeSelection === false
      ? I18n.t("restaurantDetail.reservationRegistered", {
          slot: `${offerSlot?.initSlot}/${offerSlot?.endSlot}`,
        })
      : isSelected !== undefined
      ? `${I18n.t("restaurantDetail.cart.youChose")}: ${offerSlot?.initSlot}-${
          offerSlot?.endSlot
        }`
      : I18n.t("restaurantDetail.cart.chooseSlot");
  };

  static getAllergensWarningMessage = (
    menuType?: IImportationTypes
  ): string => {
    switch (menuType) {
      case IMPORTATION_TYPES.WINAPRO:
        return I18n.t(
          "restaurantDetail.product.allergensWarningMessages.winapro"
        );
      default:
        return I18n.t(
          "restaurantDetail.product.allergensWarningMessages.oscar"
        );
    }
  };

  getGroupedProducts = (offerItems: IOfferItem[] | undefined) => {
    if (!offerItems) return null;
    return offerItems.reduce<Record<string, IOfferItem[]>>(
      (_offerItems, item) => {
        _offerItems[`${item.inheritedFamily}`] = [
          ...(_offerItems[`${item.inheritedFamily}`] ?? []),
          item,
        ];
        return _offerItems;
      },
      {}
    );
  };

  static sortFamiliesListMenu = (
    translatedEntries: any,
    holding: Holding,
    isSimpleArray?: boolean
  ) => {
    return translatedEntries.sort(
      (a: any, b: any) =>
        menuFamiliesRank[holding.importationType as IImportationTypes].indexOf(
          isSimpleArray ? a : a[0]
        ) -
        menuFamiliesRank[holding.importationType as IImportationTypes].indexOf(
          isSimpleArray ? b : b[0]
        )
    );
  };

  static sortFamiliesList = (
    translatedEntries: any,
    isSimpleArray?: boolean
  ) => {
    return translatedEntries.sort(
      (a: any, b: any) =>
        productFamiliesRank.indexOf(isSimpleArray ? a : a[0]) -
        productFamiliesRank.indexOf(isSimpleArray ? b : b[0])
    );
  };

  sortFamilyByName = (
    groupedProducts: Record<string, IOfferItem[]>,
    isMenu: boolean,
    holding: Holding
  ) => {
    const entries = Object.entries(groupedProducts);

    const translatedGroupedProducts = _.chain(
      entries?.map((p: [string, IOfferItem[]]) => {
        const cleanerLabel = OfferViewModel.getLabelFromProductFamily(p[0]);
        return { cleanerLabel, values: p[1] };
      })
    )
      .groupBy("cleanerLabel")
      .mapValues((v: any) => _.chain(v).map("values").flattenDeep().value())
      .value();

    const translatedEntries = Object.entries(translatedGroupedProducts);

    isMenu
      ? OfferViewModel.sortFamiliesListMenu(translatedEntries, holding)
      : OfferViewModel.sortFamiliesList(translatedEntries);

    return translatedEntries;
  };

  getRealFamilyName = (items: IOfferItem[]) => {
    return items?.[0]?.inheritedFamily || "";
  };

  getProductAmount = (_amount: string | null): any => {
    const amount = _amount || "0,00";
    return parseFloat(amount?.replace(",", ".")).toFixed(2);
  };

  getContainerAmount = (_amount: string | null): any => {
    const amount = _amount || "0,00";
    return parseFloat(amount?.replace(",", ".")).toFixed(2);
  };

  getOfferDescription = (offer: IOfferItem, menuType?: IImportationTypes) => {
    const description = (offer as any)?.description;

    if (!!description) {
      return description;
    } else if (menuType === IMPORTATION_TYPES.WINAPRO) {
      return null;
    }

    if (offer?.inheritedDescription) {
      return offer?.inheritedDescription;
    }

    if (offer.products && offer.products?.length < 2 ) {
      return null;
    }

    return (
      offer.products
        ?.map((product) =>
          _.capitalize(_.trimEnd(product.label.toLowerCase(), "."))
        )
        .join(" / ")
    );
  };

  computeTotalPrice = (products: OrderItem[]) => {
    let totalContainerPrice: number = 0;

    const totalPrice = products.reduce((acc, current) => {
      const amount = this.getProductAmount(current.priceWhenAdded.amount);

      const containerAmount = this.getContainerAmount(
        current.containerPriceWhenAdded
          ? current.containerPriceWhenAdded.amount
          : ""
      );

      totalContainerPrice += containerAmount * current.quantity;

      return acc + amount * current.quantity;
    }, 0);

    return (totalPrice + totalContainerPrice).toFixed(2).replace(".", ",");
  };

  static getLabelFromProductFamily = (family: string) => {
    const adjustedLabel = family?.trim?.();
    return _.get(
      {
        FORMULAS: "formulas",
        STARTERS: "starters",
        SOUPS: "soups",
        DISHES: "dishes",
        SANDWICHES: "sandwiches",
        SALADS: "salads",
        "SIDE DISHES": "sideDishes",
        DAIRIES: "dairies",
        PASTRIES: "pastries",
        FRUITS: "fruits",
        BREADS: "breads",
        SNACKS: "snacks",
        BEVERAGES: "beverages",
        MISCELLANEOUS: "miscellaneous",
        // product families given by Click and Collect
        STARTER: "starters",
        SOUP: "soups",
        DISH: "dishes",
        SANDWICH: "sandwiches",
        SALAD: "salads",
        SIDE_DISH: "sideDishes",
        DAIRY: "dairies",
        DESSERT: "desserts",
        PASTRY: "pastries",
        FRUIT: "fruits",
        BREAD: "breads",
        SNACKING: "snacks",
        BEVERAGE: "beverages",
        // product families given by Booking (Oscar)
        "SALAD BAR AU KILOS": "salads",
        "PRODUITS LAITIERS": "dairies",
        "DESSERTS BAR AU KILOS": "desserts",
        PAIN: "breads",
        BOISSONS: "beverages",
        DIVERS: "miscellaneous",
        LEGUMES: "vegetables",
        FROMAGES: "cheeses",
        GOUTER: "toTaste",
        "PLATS CHAUDS": "menuHotMeals",
        "HORS D'OEUVRES": "menuStarters",
        DESSERTS: "menuDesserts",
        ENTREES: "menuStarters",
        ACCOMP: "menuAccompaniment",
        ACCOMPAGNEMENTS: "menuAccompaniment",
        PLAT: "menuHotMeals",
        ENTREE: "menuStarters",
        COMPLEMENT: "menuCompliments",
        FROMAGE: "menuCheese",
        "PETIT DEJEUNER": "breakfast",
        "TABLE A CONDIMENT": "condimentTable",
      },
      adjustedLabel,
      adjustedLabel
    );
  };

  hasContainers = (offerItems: IOfferItem[] | undefined) =>
    !!offerItems && offerItems.some(({ container }) => !!container);
}
