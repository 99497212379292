import React, { useCallback, useState } from "react";
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { Title13, Title16 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import { Colors } from "@constants";
import { TestIDs } from "@utils";
import {
  Row,
  Action,
  YourOrder,
  GoBackText,
  ModalContainer,
  IconContainer,
  DescriptionText,
} from "./DeleteAccountModal.style";
import { CloseClickOutsideTemplate, ModalTemplate } from "@atomic/templates";
import { Sucess, WarningOrange } from "@assets";
import { useDispatch, useSelector } from "react-redux";
import {
  BrandThemeActions,
  GlobalState,
  ModalActions,
  persistedStore,
} from "@redux";
import { getApolloClient } from "../../../apollo";
import {
  AccountActions,
  AuthActions,
  AuthThunks,
  BookingActions,
  HomeScreens,
  OffersActions,
  PaymentsActions,
} from "@modules";
import { NavigationProp } from "@react-navigation/core";

interface IProps {
  forwardTestID?: string;
  navigation: NavigationProp<any>;
}

export const DeleteAccountModal: React.FC<IProps> = React.memo(
  ({ forwardTestID, navigation }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isAccountDeleted, setAccountDeleted] = React.useState<boolean>(
      false
    );

    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );
    const { id } =
      useSelector((state: GlobalState) => state?.auth?.userInfo) ?? {};

    const handleCloseModal = () => {
      dispatch(ModalActions.setOpen(false));
    };

    const clearCache = async () => {
      await getApolloClient().cache.reset();
      dispatch(AuthActions.logOutUser());
      dispatch(AccountActions.setUserBalance(null));
      dispatch(BookingActions.initBookingOrders());
      dispatch(OffersActions.initOrderItems());
      dispatch(BrandThemeActions.resetBrandTheme());
      dispatch(PaymentsActions.resetPayment());
      persistedStore.purge();
      navigation?.navigate(HomeScreens.HOME_SCREEN);
    };

    const handleDeleteAccount = useCallback(async () => {
      try {
        setLoading(true);
        await dispatch(AuthThunks.deleteAccount(`${id}`));
        setLoading(false);
        setAccountDeleted(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        clearCache();
      } catch (e) {
        setLoading(false);
      }
    }, [id]);

    return (
      <ModalTemplate
        forwardTestID={TestIDs.profile.views.deleteUserAccountView}
        forwardCloseTestID={TestIDs.profile.actions.closeDeleteUserAccount}
        style={styles.modalStyle}
        closeXButtonStyle={styles.closeXButtonStyle}
      >
        <CloseClickOutsideTemplate handleClose={handleCloseModal}>
          <ModalContainer>
            {!isAccountDeleted ? (
              <View>
                <Row>
                  <IconContainer>
                    <WarningOrange />
                  </IconContainer>
                  <View style={{ flex: 1 }}>
                    <GoBackText>
                      {I18n.t("profile.deleteMyAccoutTitle")}
                    </GoBackText>
                    <DescriptionText>
                      {I18n.t("profile.deleteMyAccoutDesc")}
                    </DescriptionText>
                  </View>
                </Row>

                <Action
                  testID={TestIDs.profile.actions.deleteUserAccount}
                  marginTop={16}
                  backgroundColor={
                    brandTheme.buttonActionColors?.backgroundColor ||
                    Colors.foodiDefault
                  }
                  onPress={handleDeleteAccount}
                  justifyContent={loading}
                >
                  {loading ? (
                    <>
                      <ActivityIndicator
                        style={styles.loadingStyle}
                        color={
                          brandTheme.buttonActionColors?.textColor ||
                          Colors.foodiBlack
                        }
                      />
                      <YourOrder hide>{I18n.t("profile.delete")}</YourOrder>
                    </>
                  ) : (
                    <YourOrder
                      color={
                        brandTheme.buttonActionColors?.textColor ||
                        Colors.foodiBlack
                      }
                    >
                      {I18n.t("profile.delete")}
                    </YourOrder>
                  )}
                </Action>
                <Action
                  testID={TestIDs.profile.actions.cancelDeleteUserAccount}
                  marginTop={12}
                  backgroundColor={Colors.white}
                  hasBorder={true}
                  onPress={handleCloseModal}
                  justifyContent={loading}
                >
                  <YourOrder color={Colors.foodiBlack}>
                    {I18n.t("profile.cancel")}
                  </YourOrder>
                </Action>
              </View>
            ) : (
              <View>
                <Row>
                  <IconContainer>
                    <Sucess />
                  </IconContainer>
                  <View style={{ flex: 1 }}>
                    <GoBackText>{I18n.t("profile.accountDeleted")}</GoBackText>
                  </View>
                </Row>
              </View>
            )}
          </ModalContainer>
        </CloseClickOutsideTemplate>
      </ModalTemplate>
    );
  }
);

const styles = StyleSheet.create({
  loadingStyle: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  closeXButtonStyle: {
    top: -15,
    right: -5,
  },
  modalStyle: {
    width: 414,
    maxHeight: 650,
  },
});
