import { InfonewsTag } from "@assets";
import { Article, Carousel, Footer } from "@atomic";
import { useDevices } from "@hooks";
import { NavigationProp, Route } from "@react-navigation/native";
import { GlobalState } from "@redux";
import { Title16, Title22, Title36 } from "@stylesheets";
import { getCloudImageUrl } from "@utils";
import React from "react";
import { PixelRatio } from "react-native";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  Wrapper,
  Container,
  TitleContainer,
  HTML,
  ImageContainer,
  CarouselContainer,
  InfoNewsHTML,
  IMAGE_HEIGHT,
  IMAGE_HEIGHT_MOBILE,
  CONTENT_WIDTH,
  CONTENT_WIDTH_MOBILE,
  IMAGE_WIDTH_MOBILE,
  ITEM_WIDTH_WITH_MARGIN,
  Tag,
  TagIconContainer,
  TagTextContainer,
  InfoNewsContainer,
} from "./ArticleDetail.style";

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
}

export const ArticleDetail: React.FC<IProps> = React.memo(
  ({ navigation, route }) => {
    const articleParams = useSelector(
      (state: GlobalState) => state?.articlePage?.params
    );
    const { content, date, image, resume, title } = articleParams;
    const [isMobile] = useDevices();

    const Title: React.FC<any> = isMobile ? Title22 : Title36;

    const renderHTML = (html: string) => (
      <HTML
        isMobile={isMobile}
        key={html}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );

    const renderInfoNews = (html: string) => (
      <InfoNewsContainer>
        <Tag isMobile={isMobile}>
          <TagIconContainer>
            <InfonewsTag />
          </TagIconContainer>
          <TagTextContainer>
            <Title16>{I18n.t("homepage.infoNews")}</Title16>
          </TagTextContainer>
        </Tag>
        <InfoNewsHTML
          isMobile={isMobile}
          key={html}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </InfoNewsContainer>
    );

    const renderItems = (_elements: any[]) => {
      return _elements?.map((element: any, index: number) => {
        const uri = getCloudImageUrl(
          element?.path,
          "height",
          String(PixelRatio.getPixelSizeForLayoutSize(239))
        );
        const hasGap = (index + 1) % _elements.length !== 0;
        return (
          <ImageContainer
            key={element?.id}
            isMobile={isMobile}
            resizeMode="cover"
            source={{ uri }}
            hasGap={hasGap}
          />
        );
      });
    };

    const renderCarousel = (value: string) => {
      const images = JSON.parse(value);
      return (
        <CarouselContainer>
          <Carousel
            items={images}
            itemWidth={isMobile ? IMAGE_WIDTH_MOBILE : ITEM_WIDTH_WITH_MARGIN}
            renderItems={renderItems}
            width={isMobile ? CONTENT_WIDTH_MOBILE : CONTENT_WIDTH}
            height={isMobile ? IMAGE_HEIGHT_MOBILE : IMAGE_HEIGHT}
            hasArrowsForShow={isMobile}
          />
        </CarouselContainer>
      );
    };

    return (
      <Wrapper>
        <Container isMobile={isMobile}>
          <TitleContainer>
            <Title isBlack>{I18n.t("homepage.news")}</Title>
          </TitleContainer>
          <Article
            title={title}
            resume={resume}
            image={image}
            date={date}
            content={content}
            isDetail
          />
          {content?.map((v: any) => {
            if (v?.key === "paragraph") return renderHTML(v?.content);
            if (v?.key === "infoNews") return renderInfoNews(v?.content);
            if (v?.key === "image") return renderCarousel(v?.content);
          })}
        </Container>
        <Footer navigation={navigation} />
      </Wrapper>
    );
  }
);
