import {
  PointsOfSaleWithMenuResponse,
  GetPointsOfSaleWithMenuCommand,
  GetPosCommand,
  PosResponse,
  GetOnePosCommand,
  OnePosResponse,
  GetPointsOfSaleCommand,
  IPosStatus,
} from "@foodi/core";
import { createReducer, ThunkResult } from "@redux";
import { Action } from "@redux/action";
import { displayToastError } from "@utils";

export interface PointOfSaleState {
  selectedPos: OnePosResponse | null;
  posStatus: boolean;
  hasNewOrder: boolean;
  posCCButtonStatus: boolean;
  posBookingButtonStatus: boolean;
  miniCartEnabled: boolean;
  fullCartEnabled: boolean;
  miniBookingCartEnabled: boolean;
  fullBookingCartEnabled: boolean;
  cartCurrentInfo: any;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_SELECTED_POS: "pointOfSale/SET_SELECTED_POS",
  SET_POS_STATUS: "pointOfSale/SET_POS_STATUS",
  SET_RESERVATION: "pointOfSale/SET_RESERVATION",
  SET_NEW_ORDER: "pointOfSale/SET_NEW_ORDER",
  SET_CC_BUTTON_STATUS: "pointOfSale/SET_CC_BUTTON_STATUS",
  SET_BOOKING_BUTTON_STATUS: "pointOfSale/SET_BOOKING_BUTTON_STATUS",
  SET_MINICART_STATUS: "pointOfSale/SET_MINICART_STATUS",
  SET_FULLCART_STATUS: "pointOfSale/SET_FULLCART_STATUS",
  SET_MINIBOOKINGCART_STATUS: "pointOfSale/SET_MINIBOOKINGCART_STATUS",
  SET_FULLBOOKINGCART_STATUS: "pointOfSale/SET_FULLBOOKINGCART_STATUS",
  SET_CART_CURRENT_INFO: "pointOfSale/SET_CART_CURRENT_INFO",
};

const ActionCreators = {
  setSelectedPos: (
    selectedPos: OnePosResponse | null
  ): Action<OnePosResponse | null> => ({
    type: ActionTypes.SET_SELECTED_POS,
    payload: selectedPos,
  }),
  setPosStatus: (status: boolean): Action<boolean> => ({
    type: ActionTypes.SET_POS_STATUS,
    payload: status,
  }),
  setNewOrder: (status: boolean): Action<boolean> => ({
    type: ActionTypes.SET_NEW_ORDER,
    payload: status,
  }),
  setCCButtonStatus: (status: boolean): Action<boolean> => ({
    type: ActionTypes.SET_CC_BUTTON_STATUS,
    payload: status,
  }),
  setBookingButtonStatus: (status: boolean): Action<boolean> => ({
    type: ActionTypes.SET_BOOKING_BUTTON_STATUS,
    payload: status,
  }),
  setMiniCartStatus: (status: boolean): Action<boolean> => ({
    type: ActionTypes.SET_MINICART_STATUS,
    payload: status,
  }),
  setFullCartStatus: (status: boolean): Action<boolean> => ({
    type: ActionTypes.SET_FULLCART_STATUS,
    payload: status,
  }),
  setMiniBookingCartStatus: (status: boolean): Action<boolean> => ({
    type: ActionTypes.SET_MINIBOOKINGCART_STATUS,
    payload: status,
  }),
  setFullBookingCartStatus: (status: boolean): Action<boolean> => ({
    type: ActionTypes.SET_FULLBOOKINGCART_STATUS,
    payload: status,
  }),
  setCartCurrentInfo: (cartCurrentInfo: any): Action<any> => ({
    type: ActionTypes.SET_CART_CURRENT_INFO,
    payload: cartCurrentInfo,
  }),
};

/*************  Side Effects, only if applicable ****************/
// e.g. thunks, epics, etc
const ThunkActionCreators = {
  getPointsOfSaleWithMenu: (
    params: GetPointsOfSaleWithMenuCommand
  ): ThunkResult<Promise<PointsOfSaleWithMenuResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getPointsOfSaleWithMenu } = getDependencies();
      return getPointsOfSaleWithMenu.execute(params);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  getPos: (params: GetPosCommand): ThunkResult<Promise<PosResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getPos } = getDependencies();
      return getPos.execute(params);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  getOnePos: (params: GetOnePosCommand): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getOnePos } = getDependencies();
      const response = await getOnePos.execute(params);
      dispatch(ActionCreators.setSelectedPos(response));
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  getPointsOfSale: (
    params: GetPointsOfSaleCommand
  ): ThunkResult<Promise<PointsOfSaleWithMenuResponse | void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getPointsOfSale } = getDependencies();
      return getPointsOfSale.execute(params);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
};

/*************  Reducer  ****************/

const initialState: PointOfSaleState = {
  selectedPos: null,
  posStatus: false,
  hasNewOrder: false,
  posCCButtonStatus: true,
  posBookingButtonStatus: true,
  miniCartEnabled: false,
  fullCartEnabled: false,
  miniBookingCartEnabled: false,
  fullBookingCartEnabled: false,
  cartCurrentInfo: null,
};

const Reduction = {
  setSelectedPos: (
    state: PointOfSaleState,
    { payload: selectedPos }: Action<OnePosResponse>
  ): PointOfSaleState => ({
    ...state,
    selectedPos,
  }),
  setPosStatus: (
    state: PointOfSaleState,
    { payload: posStatus }: Action<boolean>
  ): PointOfSaleState => ({
    ...state,
    posStatus,
  }),
  setCCButtonStatus: (
    state: PointOfSaleState,
    { payload: posCCButtonStatus }: Action<boolean>
  ): PointOfSaleState => ({
    ...state,
    posCCButtonStatus,
  }),
  setBookingButtonStatus: (
    state: PointOfSaleState,
    { payload: posBookingButtonStatus }: Action<boolean>
  ): PointOfSaleState => ({
    ...state,
    posBookingButtonStatus,
  }),
  setNewOrder: (
    state: PointOfSaleState,
    { payload: hasNewOrder }: Action<boolean>
  ): PointOfSaleState => ({
    ...state,
    hasNewOrder,
  }),
  setMiniCartStatus: (
    state: PointOfSaleState,
    { payload: miniCartEnabled }: Action<boolean>
  ): PointOfSaleState => ({
    ...state,
    miniCartEnabled,
  }),
  setFullCartStatus: (
    state: PointOfSaleState,
    { payload: fullCartEnabled }: Action<boolean>
  ): PointOfSaleState => ({
    ...state,
    fullCartEnabled,
  }),
  setMiniBookingCartStatus: (
    state: PointOfSaleState,
    { payload: miniBookingCartEnabled }: Action<boolean>
  ): PointOfSaleState => ({
    ...state,
    miniBookingCartEnabled,
  }),
  setFullBookingCartStatus: (
    state: PointOfSaleState,
    { payload: fullBookingCartEnabled }: Action<boolean>
  ): PointOfSaleState => ({
    ...state,
    fullBookingCartEnabled,
  }),
  setCartCurrentInfo: (
    state: PointOfSaleState,
    { payload: cartCurrentInfo }: Action<any>
  ): PointOfSaleState => ({
    ...state,
    cartCurrentInfo,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_SELECTED_POS]: Reduction.setSelectedPos,
  [ActionTypes.SET_POS_STATUS]: Reduction.setPosStatus,
  [ActionTypes.SET_NEW_ORDER]: Reduction.setNewOrder,
  [ActionTypes.SET_CC_BUTTON_STATUS]: Reduction.setCCButtonStatus,
  [ActionTypes.SET_BOOKING_BUTTON_STATUS]: Reduction.setBookingButtonStatus,
  [ActionTypes.SET_MINICART_STATUS]: Reduction.setMiniCartStatus,
  [ActionTypes.SET_FULLCART_STATUS]: Reduction.setFullCartStatus,
  [ActionTypes.SET_MINIBOOKINGCART_STATUS]: Reduction.setMiniBookingCartStatus,
  [ActionTypes.SET_FULLBOOKINGCART_STATUS]: Reduction.setFullBookingCartStatus,
  [ActionTypes.SET_CART_CURRENT_INFO]: Reduction.setCartCurrentInfo,
});

export default reducer;

export {
  reducer as PointOfSaleReducer,
  ActionTypes as PointOfSaleActionTypes,
  ActionCreators as PointOfSaleActions,
  ThunkActionCreators as PointOfSaleThunks,
};
