import { ArticleTag } from "@assets";
import { useDevices } from "@hooks";
import { ArticleActions, HomeScreens } from "@modules";
import { NavigationProp } from "@react-navigation/native";
import { Title16, Title18, Title22, Title30 } from "@stylesheets";
import { getCloudImageUrl } from "@utils";
import moment from "moment";
import React from "react";
import { PixelRatio } from "react-native";
import { useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  Container,
  ImageContainer,
  DateText,
  TagIconContainer,
  TagTextContainer,
  Tag,
} from "./Article.style";

interface IProps {
  navigation?: NavigationProp<any>;
  resume?: string;
  title?: string;
  date: string;
  image: string;
  content: any;
  isDetail?: boolean;
  forwardTestId?: string;
}

export const Article: React.FC<IProps> = React.memo(
  ({
    navigation,
    resume,
    title,
    date,
    image,
    content,
    isDetail,
    forwardTestId,
  }) => {
    const [isMobile] = useDevices();
    const dispatch = useDispatch();
    const uri = getCloudImageUrl(
      image,
      "height",
      String(PixelRatio.getPixelSizeForLayoutSize(460))
    );

    const publishedDate = moment(date?.split("/")?.[0])
      ?.format("DD/MM/YYYY");

    const Title: React.FC<any> = isDetail && !isMobile ? Title30 : Title22;
    const Resume: React.FC<any> = isDetail ? Title18 : Title16;

    const handleLines = !isDetail
      ? { numberOfLines: 2, ellipsizeMode: "tail" }
      : null;

    const handleArticleDetail = () => {
      dispatch(
        ArticleActions.setArticleParams({
          resume,
          title,
          date,
          image,
          content,
        })
      );
      navigation?.navigate(HomeScreens.ARTICLE_SCREEN);
    };

    return (
      <Container
        testID={`${forwardTestId}_CONTAINER`}
        isMobile={isMobile}
        onPress={handleArticleDetail}
        disabled={isDetail}
        isDetail={isDetail}
      >
        <Tag isMobile={isMobile} isDetail={isDetail}>
          <TagIconContainer>
            <ArticleTag />
          </TagIconContainer>
          <TagTextContainer>
            <Title16>{I18n.t("homepage.article")}</Title16>
          </TagTextContainer>
        </Tag>
        <ImageContainer
          isMobile={isMobile}
          resizeMode="cover"
          source={{ uri }}
          isDetail={isDetail}
        />
        {title && (
          <Title {...handleLines} textAlign="left" isBlack isBold>
            {title}
          </Title>
        )}
        <DateText isDetail={isDetail}>{`${I18n.t(
          "homepage.publishedOn"
        )} ${publishedDate}.`}</DateText>
        {resume && !isDetail && (
          <Resume numberOfLines={2} ellipsizeMode="tail">
            {resume}
          </Resume>
        )}
      </Container>
    );
  }
);
