import React, { useState } from "react";
import { Sucess, WarningOrange, Warning } from "@assets";
import { TestIDs } from "@utils";
import _ from "lodash";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { I18n } from "react-redux-i18n";
import { TableNumberInput } from "../TableNumberInput";
import {
  Container,
  Title,
  Description,
  WarningMessage,
  WarningMessageContainer,
  LocalDateContainer,
} from "./TableNumber.style";
import { TableViewModel, TableNumberViewModel } from "@modules";
import { Colors } from "@constants";
interface IProps {
  tableNumber: number | undefined;
  onChangeTableNumber: (tableNumber: number, isValid: boolean) => void;
  validateWithTableService: boolean;
}

export const TableNumber: React.FC<IProps> = React.memo(({
  onChangeTableNumber,
  tableNumber,
  validateWithTableService,
}) => {
  const [isValidNumber, setValidNumber] = useState<boolean>(false);

  const [tableVM] = useState<TableViewModel>(new TableViewModel());

  const validNumbers =
    useSelector(
      (state: GlobalState) => tableVM.getValidTableNumbers(state.table.tablesAvailability?.tables)
    );

  const isEmptyTableNumber = TableNumberViewModel.isEmpty(tableNumber);

  return (
    <Container testID={TestIDs.restaurantDetail.views.tableNumberContainer}>
      <Title>{I18n.t("restaurantDetail.cart.table")}</Title>
      <LocalDateContainer>
        <Description>
          {I18n.t("restaurantDetail.cart.enterYourTableNumber")}
        </Description>
      </LocalDateContainer>

      <TableNumberInput
        forwardTestID={TestIDs.restaurantDetail.inputs.tableNumber}
        isValidNumber={isValidNumber}
        setValidInput={setValidNumber}
        callback={onChangeTableNumber}
        validNumbers={validateWithTableService ? validNumbers : undefined}
      />

      <WarningMessageContainer>
        {
          isValidNumber
            ? <Sucess />
            : isEmptyTableNumber ? <WarningOrange /> : <Warning />
        }
        <WarningMessage
          testID={TestIDs.restaurantDetail.texts.warningMessage}
          textColor={!isEmptyTableNumber && !isValidNumber ? Colors.redError : undefined}
        >
          {
            isValidNumber
              ? I18n.t("restaurantDetail.cart.chosenTable", { tableNumber })
              : isEmptyTableNumber
                ? I18n.t("restaurantDetail.cart.selectTable")
                : I18n.t("restaurantDetail.cart.invalidTableNumber")
          }
        </WarningMessage>
      </WarningMessageContainer>
    </Container>
  );
});
