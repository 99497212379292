import { Colors } from "@constants";
import style, { css } from "styled-components/native";

export const Container = style.View<{ isMobile?: boolean }>`
    padding: ${({ isMobile }) => (isMobile ? "20px 18px" : "45px 150px")};
    background-color: white;
    height: 100%;
`;

export const ContentContainer = style.View`
    align-items: center;
    justify-content: center;
`;

export const GoBackContainer = style.Pressable`
    flex-direction: row;
    align-items: center;
    max-width: 250px;
`;

export const GoBackText = style.Text<{ isMobile?: boolean }>`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: ${({ isMobile }) => (isMobile ? 16 : 22)}px;
    letter-spacing: 0;
    line-height: 30px;
    margin-left: 7px;
`;

export const Title = style.Text<{ isMobile?: boolean }>`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: ${({ isMobile }) => (isMobile ? 30 : 36)}px;
    letter-spacing: 0;
    line-height: ${({ isMobile }) => (isMobile ? 41 : 49)}px;
    text-align: center;
    margin-top: 24px;
`;

export const OrderText = style.Text<{
  bold?: boolean;
  canceled?: boolean;
  isMobile?: boolean;
}>`
    color: ${({ bold, canceled }) =>
      canceled
        ? Colors.redError
        : bold
        ? Colors.restaurantCardStatusGreen
        : Colors.foodiBlack};

    font-family: ${({ bold }) => (bold ? "manrope-bold" : "manrope")};
    font-size: ${({ isMobile }) => (isMobile ? 22 : 30)}px;
    letter-spacing: 0;
    line-height: 41px;
    text-align: center;
    margin-top: 5px;
`;

export const DescriptionText = style.Text<{
  bold?: boolean;
  isMobile?: boolean;
}>`
    color: ${Colors.foodiBlack};
    font-family: ${({ bold }) => (bold ? "manrope-bold" : "manrope")};
    font-size: ${({ isMobile }) => (isMobile ? 16 : 18)}px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-top: 7px;
`;

export const Row = style.View<{
  marginTop?: number;
  maxWidth?: number;
  isMobile?: boolean;
  width?: number;
}>`
    flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
    justify-content: space-between;
    ${({ marginTop }) =>
      marginTop &&
      css`
        margin-top: ${marginTop}px;
      `}
    ${({ width }) =>
      width &&
      css`
        width: ${width}px;
      `}
    ${({ maxWidth }) =>
      maxWidth &&
      css`
        max-width: ${maxWidth}px;
      `}
`;

export const Column = style.View<{ marginTop?: number; maxWidth?: number, isMobile?: boolean, marginLeft?: number, width?: number }>`
    flex-direction: column;
    justify-content: space-between;
    margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : '0'}px;
    ${({marginTop}) => marginTop && css`
      margin-top: ${marginTop}px;
    `}
    ${({width}) => width && css`
      width: ${width}px;
    `}
    ${({maxWidth}) => maxWidth && css`
      max-width: ${maxWidth}px;
    `}
`;

export const PressableRow = style.Pressable`
    flex-direction: row;
    justify-content: flex-start;
    max-width: 175px;
`;

export const BlockContainer = style.View<{ isMobile?: boolean }>`
    width: ${({ isMobile }) => (isMobile ? 365 : 401)}px;
    border: 1px solid ${Colors.border1};
    border-radius: 8px;
    padding: ${({ isMobile }) => (isMobile ? "12px 10px" : "12px 20px")};
    margin-top: 23px;
`;

export const CancelContainer = style.View<{ isMobile?: boolean }>`
    width: ${({ isMobile }) => (isMobile ? 365 : 401)}px;
    border: 1px solid ${Colors.border1};
    border-radius: 8px;
    padding: ${({ isMobile }) => (isMobile ? "12px 5px" : "12px 20px")};
    margin-top: 23px;
    text-align: center;
`;

export const BlockTitle = style.Text<{ color?: string; hide?: boolean }>`
    color: ${({ color }) => (color ? color : Colors.foodiBlack)};
    font-family: manrope-bold;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 30px;
    opacity: ${({ hide }) => (hide ? 0 : 1)};
`;

export const ProductDescriptionContainer = style.View`
    flex-direction: row;
`;

export const ProductDescription = style.Text<{
  marginRight?: number;
  marginLeft?: number;
  marginBottom?: number;
  bold?: boolean;
  isLink?: boolean;
  underline?: boolean;
  hasNoMaxWidth?: boolean;
  alignSelf?: string;
}>`
      color: ${({ isLink }) => (isLink ? Colors.blue1 : Colors.foodiBlack)};
      font-family: ${({ bold }) => (bold ? "manrope-bold" : "manrope")};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      max-width: ${({ hasNoMaxWidth }) => (hasNoMaxWidth ? null : "213px")};
      flex-wrap: wrap;
      margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "12")}px;
      text-decoration-line: ${({ underline }) =>
        underline ? "underline" : "none"};
      ${({alignSelf}) => alignSelf && css `align-self: ${alignSelf};` }
      margin-bottom: 12px;
      text-decoration-line: ${({ underline }) =>
        underline ? "underline" : "none"};
      ${({ marginRight }) =>
        marginRight &&
        css`
          margin-right: ${marginRight}px;
        `}
      ${({ marginLeft }) =>
        marginLeft &&
        css`
          margin-left: ${marginLeft}px;
        `}
  `;

export const ContainerDescription = style.Text<{ isMobile?: boolean }>`
  color: ${Colors.foodiBlack};
  font-family: "manrope";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin-left: -20px;
  max-width: ${({ isMobile }) => (isMobile ? "70vw" : "213px")};
  margin-bottom: 12px;
`;

export const IconContainer = style.View`
    margin: 2px 0px 0px -20px;
`;

export const ModalContainer = style.View`
    padding: 10px 13px;
`;

export const Action = style.Pressable<{
  hasBorder?: boolean;
  marginTop?: number;
  marginRight?: number;
  justifyContent?: boolean;
  backgroundColor?: string;
}>`
    border-radius: 8px;
    padding: 5px 54px;
    background-color: ${({ hasBorder, backgroundColor }) =>
      hasBorder
        ? "transparent"
        : backgroundColor
        ? backgroundColor
        : Colors.foodiDefault};
    border: ${({ hasBorder }) => (hasBorder ? 1 : 0)}px solid ${
  Colors.foodiBlack
};
    align-self: center;
    justify-content: ${({ justifyContent }) =>
      justifyContent ? "center" : "flex-start"};
    text-align: center;
    margin-right: ${({ marginRight }) => marginRight || 0}px;
    ${({ marginTop }) =>
      marginTop &&
      css`
        margin-top: ${marginTop}px;
      `}
`;

export const ConsignContainer = style.View`
  margin-top: 12px;
`
