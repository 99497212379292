import React, { useMemo, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Title16 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import { Colors } from "@constants";
import { GlobalState, ModalActions } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { useDevices } from "@hooks";

import { version as projectVersion } from "../../../configuration/projectVersion";
interface IProps {
  forwardTestID?: string;
}

export const DeleteAccountView: React.FC<IProps> = React.memo(
  ({ forwardTestID }) => {
    const dispatch = useDispatch();
    const [isOver, setIsHover] = useState<boolean>(false);
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    //This line is used to force the component rerender with the right language when changed
    useSelector((state: GlobalState) => state.auth?.userInfo?.language);

    const onPressDelete = () => {
      dispatch(ModalActions.setOpen(true));
    };
    return (
      <>
        <View style={styles.container}>
          <Title16 style={{}}>{I18n.t("profile.wantLeaveUs")}</Title16>
          <Pressable
            testID={forwardTestID}
            // @ts-ignore
            onHoverIn={() => setIsHover(true)}
            onHoverOut={() => setIsHover(false)}
            onPress={() => onPressDelete()}
          >
            <Title16
              testID={forwardTestID}
              style={[styles.deleteText, isOver && styles.hoverStyle]}
            >
              {I18n.t("profile.deleteMyAccout")}
            </Title16>
          </Pressable>
        </View>
        <View style={styles.container}>
          <Title16 style={{}}>v{projectVersion}</Title16>
        </View>
      </>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: isMobile ? "column" : "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    deleteText: {
      marginLeft: isMobile ? 0 : 4,
      color: Colors.redError,
    },
    hoverStyle: {
      textDecorationLine: "underline",
    },
  });
