import React from "react";
import { I18n } from "react-redux-i18n";

interface IProps {
  width?: number;
  height?: number;
}

export const Modify: React.FC<IProps> = ({ width = 30, height = 30 }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("confirmationPage.edit")}</title>
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Profil-compte_Default_Holding_HoldingBadge_Profils-Copy"
        transform="translate(-913.000000, -584.000000)"
      >
        <g id="icone-modifier" transform="translate(913.000000, 584.000000)">
          <circle
            id="Oval"
            stroke="#090909"
            stroke-width="2"
            cx="15"
            cy="15"
            r="14"
          ></circle>
          <g id="mode_black_24dp">
            <polygon id="Path" points="0 0 30 0 30 30 0 30"></polygon>
            <path
              d="M19.9649701,7.75 C19.7566657,7.75 19.5400292,7.83332176 19.3817178,7.99163311 L17.8569296,9.51642133 L20.9814956,12.6409874 L22.5062838,11.1161991 C22.8312387,10.7912443 22.8312387,10.2663172 22.5062838,9.94136231 L20.5565546,7.99163311 C20.3899111,7.82498958 20.1816067,7.75 19.9649701,7.75 Z M16.9653868,10.4079642 L7.75,19.6233509 L7.75,22.747917 L10.874566,22.747917 L20.0899528,13.5325302 L16.9653868,10.4079642 Z"
              id="Shape"
              fill="#000000"
              fill-rule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
