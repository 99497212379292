import React, { useMemo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { Colors, Spacing } from "@constants";
import { Icon, IconName, Label } from "@atomic";
interface IProps {
  title: string;
  iconName: IconName;
  style?: ViewStyle;
  forwardTestID?: string;
  children?: React.ReactNode;
  totalOrders: number;
  isMobile?: boolean;
}

export const LabelIconRadius: React.FC<IProps> = React.memo(
  ({
    style,
    forwardTestID,
    title,
    iconName,
    totalOrders,
    isMobile = false,
  }) => {
    const styles = useMemo(() => _styles(isMobile), [isMobile]);
    return (
      <View testID={forwardTestID} style={[styles.container, style]}>
        <Label title={title} />
        <Icon name={iconName} color={Colors.white} size={16} />
        {isMobile && <Label title={totalOrders.toString()} />}
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      height: isMobile ? 24 : Spacing.L,
      paddingHorizontal: 7,
      backgroundColor: Colors.middleGreen,
      borderRadius: 15,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  });
