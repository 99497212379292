import {
  UpdateLanguageCommand,
  IUpdateUserLanguageResult,
  UpdateOptinsCommand,
  IUpdateUserResult,
  UpdateVirtualTicketCommand,
  IUpdateVirtualTicketResult,
} from "@foodi/core";
import { ThunkResult } from "@redux";
import { displayToastError } from "@utils";

const ThunkActionCreators = {
  updateLanguage: (
    params: UpdateLanguageCommand
  ): ThunkResult<Promise<IUpdateUserLanguageResult>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { updateLanguage } = getDependencies();
      const response = await updateLanguage.execute(params);
      return Promise.resolve(response);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  updateOptins: (
    params: UpdateOptinsCommand
  ): ThunkResult<Promise<IUpdateUserResult>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { updateOptins } = getDependencies();
      const response = await updateOptins.execute(params);
      return Promise.resolve(response);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  updateVirtualTicket: (
    params: UpdateVirtualTicketCommand
  ): ThunkResult<Promise<IUpdateVirtualTicketResult>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { updateVirtualTicket } = getDependencies();
      const response = await updateVirtualTicket.execute(params);
      return Promise.resolve(response);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
};

export { ThunkActionCreators as ProfileThunks };
