import React, { useCallback, useEffect, useState, useMemo } from "react";
import { NavigationProp, useIsFocused } from "@react-navigation/native";
import { Pressable, View, StyleSheet, ActivityIndicator } from "react-native";
import { I18n, setLocale } from "react-redux-i18n";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  FloatTextInput,
  Checkbox,
  AccountCreationTemplate,
  Button,
  PasswordRulesMessage,
} from "@atomic";
import {
  CheckBoxContainer,
  Text,
  Container,
  LoginDataContainer,
  NameContainer,
  RowCheckBoxContainer,
  Row,
} from "./Step1.style";
import { Title18, Title22 } from "@stylesheets";
import { TestIDs } from "@utils";
import {
  AccountThunks,
  AccountActions,
  IValidationPassRules,
  Step1ViewModel,
  PASSWORD_RULES,
  AuthThunks,
} from "@modules";
import { AccountScreens } from "@modules/account/navigation";
import { CheckEmailResponse } from "@foodi/core";
import { useDevices } from "@hooks";
import { GlobalState } from "@redux";
import { Information } from "@assets";
import { Colors } from "@constants";

interface IProps {
  navigation: NavigationProp<any>;
}

export const Step1: React.FC<IProps> = React.memo(({ navigation }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const [isMobile] = useDevices();
  const styles = useMemo(() => _styles(isMobile), [isMobile]);

  const Title: React.FC<any> = isMobile ? Title18 : Title22;
  const receiveCommunicationsText = isMobile
    ? I18n.t("account.receiveCommunicationsMobile")
    : I18n.t("account.receiveCommunications");
  const CGUText = isMobile
    ? I18n.t("account.cguMobile")
    : I18n.t("account.cgu");
  const ageText = isMobile
    ? I18n.t("account.ageMobile")
    : I18n.t("account.age");

  const { userInfoStep1 } =
    useSelector((state: GlobalState) => state?.account) || {};

  // User Infos data
  const [firstName, setFirstName] = useState(userInfoStep1?.firstName || "");
  const [lastName, setLastName] = useState(userInfoStep1?.lastName || "");
  const [email, setEmail] = useState(userInfoStep1?.email || "");
  const [password, setPassword] = useState("");
  const [checkBoxComm, setCheckBoxComm] = useState(
    !!userInfoStep1?.checkBoxComm
  );

  // Validation control
  const [checkBoxCGU, setCheckBoxCGU] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const [passwordRulesInvalid, setPasswordRulesInvalid] = useState(false);
  const [passwordRules, setPasswordRules] = useState<IValidationPassRules>(
    Step1ViewModel.validationPassRules
  );

  const [isSSODomain, setSSODomain] = useState<boolean | undefined>(undefined);
  const [isSSOCheckLoading, setSSOCheckLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    dispatch(AccountActions.setUserInfoStep1(null));
  }, []);

  useEffect(() => {
    if (isFocused) {
      dispatch(AccountActions.setCurrentStepIndex(1));
    }
  }, [isFocused]);

  const checkSSODomain = useCallback(
    _.debounce(async (isEmailValid: boolean, email: string) => {
      if (isEmailValid) {
        setSSOCheckLoading(true);
        const { isSSO } = await validateFields(email);
        setSSODomain(isSSO);
        setSSOCheckLoading(false);
      }
    }, 300),
    []
  );

  const handleEmailInput = (value: string) => {
    setEmail(value);
    setSSODomain(undefined);
    if (value.trim() === email.trim()) return;
    const isEmailValid = Step1ViewModel.validateEmail(value.trim());
    setEmailIsInvalid(!isEmailValid);
    setEmailAlreadyExists(false);
  };

  const checkSSOEmail = () => {
    const isEmailValid = Step1ViewModel.validateEmail(email.trim());
    checkSSODomain(isEmailValid, email);
  };

  const handlePasswordInput = (value: string) => {
    setPassword(value);
    setPasswordRulesInvalid(false);

    const updateValidationPassRulesData = Step1ViewModel.updateValidationPassRulesData(
      passwordRules,
      value
    );
    setPasswordRules(updateValidationPassRulesData);
  };

  const handleCGU = () => {
    window.open(window.config.CGU, "_blank")?.focus();
  };

  const handlePrivacyPolicy = () => {
    window.open(window.config.PUD, "_blank")?.focus();
  };

  const validateFields = async (email: string) => {
    let haveErrors = false;
    let isSSO = false;
    await dispatch(
      AccountThunks.checkEmail({
        email,
      })
    )
      //@ts-ignore
      .then((res: CheckEmailResponse) => {
        const userExist = res?.userExist || false;
        isSSO = !!res.SAML;
        if (userExist) {
          haveErrors = true;
          setEmailAlreadyExists(true);
        }
      })
      .catch(() => (haveErrors = true));

    return { haveErrors, isSSO };
  };

  const resetErrors = () => {
    setEmailIsInvalid(false);
    setEmailAlreadyExists(false);
    setPasswordRulesInvalid(false);
  };

  const handleContinue = async () => {
    resetErrors();
    setButtonDisabled(true);
    setLoading(true);

    try {
      const { haveErrors } = await validateFields(email);
      if (haveErrors) throw Error;

      dispatch(
        AccountActions.setUserInfoStep1({
          firstName,
          lastName,
          email,
          password,
          checkBoxComm,
        })
      );

      if (isSSODomain) {
        dispatch(AuthThunks.loginSSO(email));
        return;
      }
      navigation.navigate(AccountScreens.STEP_TWO_SCREEN);
    } finally {
      setButtonDisabled(false);
      setLoading(false);
    }
  };

  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language || state.i18n?.locale
  );
  useEffect(() => {
    dispatch(setLocale(userLanguage));
  }, [userLanguage]);

  return (
    <AccountCreationTemplate navigation={navigation}>
      <Container isMobile={isMobile}>
        <NameContainer>
          <FloatTextInput
            value={firstName}
            onChangeValue={(value) => setFirstName(value)}
            textPlaceHolder={I18n.t("account.firstName")}
          />
          <FloatTextInput
            forwardTestID={TestIDs.account.texts.lastNameInput}
            errorForwardTestID={TestIDs.account.texts.errorFieldLastname}
            value={lastName}
            onChangeValue={(value) => setLastName(value)}
            textPlaceHolder={`${I18n.t("account.lastName")}*`}
          />
        </NameContainer>
        <LoginDataContainer isMobile={isMobile}>
          <Title isBold isBlack>
            {I18n.t("account.connectYou")}
          </Title>
          <FloatTextInput
            forwardTestID={TestIDs.account.texts.emailInput}
            errorForwardTestID={TestIDs.account.texts.errorFieldEmail}
            value={email}
            onChangeValue={(value) => handleEmailInput(value)}
            textPlaceHolder={`${I18n.t("account.email")}*`}
            hasError={emailIsInvalid || emailAlreadyExists}
            errorMessage={
              emailIsInvalid
                ? I18n.t("account.emailInvalid")
                : I18n.t("account.emailAlreadyExists")
            }
            onBlur={checkSSOEmail}
          />
          {isSSOCheckLoading ? (
            <ActivityIndicator color={Colors.foodiBlack} size={50} />
          ) : isSSODomain === true ? (
            <Row>
              <View>
                <Information />
              </View>
              <Text style={styles.unavailableText}>
                {I18n.t("account.ssoMessage")}
              </Text>
            </Row>
          ) : isSSODomain === false ? (
            <>
              <View>
                <FloatTextInput
                  forwardTestID={TestIDs.account.texts.passwordInput}
                  value={password}
                  onChangeValue={(value) => handlePasswordInput(value)}
                  textPlaceHolder={`${I18n.t("account.password")}*`}
                  secureTextEntry
                />
              </View>
              <View style={styles.passRulesContainer}>
                {(password.length !== 0 || passwordRulesInvalid) && (
                  <PasswordRulesMessage
                    passCharacterRules={passwordRules.passCharacterRules}
                    passLowerCaseRules={passwordRules.passLowerCaseRules}
                    passNumberRules={passwordRules.passNumberRules}
                    passUpperCaseRules={passwordRules.passUpperCaseRules}
                    passwordRules={PASSWORD_RULES}
                    isErrorMessage={passwordRulesInvalid}
                  />
                )}
              </View>
            </>
          ) : null}
        </LoginDataContainer>
        <CheckBoxContainer isMobile={isMobile}>
          <RowCheckBoxContainer>
            <Checkbox
              forwardTestID={TestIDs.account.actions.pressReceiveNotifications}
              onPress={() => setCheckBoxComm(!checkBoxComm)}
              checked={checkBoxComm}
            />
            <Text>{receiveCommunicationsText}</Text>
          </RowCheckBoxContainer>
          <RowCheckBoxContainer>
            <Checkbox
              forwardTestID={TestIDs.account.actions.pressCGU}
              onPress={() => setCheckBoxCGU(!checkBoxCGU)}
              checked={checkBoxCGU}
            />
            <Pressable
              testID={TestIDs.account.actions.seeCGU}
              onPress={handleCGU}
            >
              <Text>
                {I18n.t("account.iDeclare")}
                <Text isUnderline>{CGUText}</Text>
                <Text>{I18n.t("account.and")}</Text>
                <Text isBold>{ageText}</Text>
                *.
              </Text>
            </Pressable>
          </RowCheckBoxContainer>
        </CheckBoxContainer>
        <Button
          forwardTestID={TestIDs.components.Button.views.container}
          onPress={handleContinue}
          disabled={
            buttonDisabled ||
            emailIsInvalid ||
            emailAlreadyExists ||
            !checkBoxCGU ||
            lastName === "" ||
            email === "" ||
            (!isSSODomain &&
              (password === "" ||
                passwordRulesInvalid ||
                passwordRules.hasErrors))
          }
          label={I18n.t("account.continue")}
          loading={loading}
        />
        <Row>
          <Text>*{I18n.t("account.require")}</Text>
        </Row>
        <Row>
          <Text>{I18n.t("account.accessTo")}</Text>
          <Pressable
            testID={TestIDs.account.actions.pressPrivacyPolicy}
            onPress={handlePrivacyPolicy}
          >
            <Text isUnderline>{I18n.t("account.privacyPolicy")}</Text>
          </Pressable>
        </Row>
      </Container>
    </AccountCreationTemplate>
  );
});

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    unavailableText: {
      marginLeft: 6,
    },
    passRulesContainer: {
      width: isMobile ? "90vw" : "auto",
    },
  });
