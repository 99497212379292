import { HoldingView } from "@foodi/core";
import { HoldingThunks } from "@modules";
import { getDecodedId } from "@utils";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import { Dispatch } from "redux";

export class HoldingViewModel {
  constructor(private dispatch: Dispatch<any>, private isMobile: boolean) {}

  isActiveHoldingView(holdingId?: string, holdingViewId?: string) {
    const id = getDecodedId(holdingViewId)?.split?.(":")?.[1];
    return id === holdingId;
  }

  limitWordsSizeForMobile(word?: string) {
    if (this.isMobile && word && word.length > 8)
      return `${word.substring(0, 6)}...`;
    return word;
  }

  getHoldingViewRowText(
    hv?: HoldingView,
    isBadgeRequired?: boolean,
    firstName?: string
  ) {
    if (!hv) {
      return `${I18n.t("account.alias")} | ${I18n.t(
        "account.establishment"
      )} | ${I18n.t("account.badge")}`;
    }
    if (isBadgeRequired)
      return `${
        this.limitWordsSizeForMobile(hv.alias) ||
        `[${firstName}]` ||
        `[${I18n.t("account.alias")}]`
      } | ${
        this.limitWordsSizeForMobile(hv.holding?.name) ||
        `[${I18n.t("account.establishment")}]`
      } | ${
        this.limitWordsSizeForMobile(hv?.guest?.supportSerialNumber) ||
        this.limitWordsSizeForMobile(hv?.guest?.serialNumber) ||
        `[${I18n.t("account.badge")}]`
      }`;

    return `${
      this.limitWordsSizeForMobile(hv.alias) ||
      `[${firstName}]` ||
      `[${I18n.t("account.alias")}]`
    } | ${
      this.limitWordsSizeForMobile(hv.holding?.name) ||
      `[${I18n.t("account.establishment")}]`
    }`;
  }

  async getSortedHoldingViews(accessToken: string, holdingId?: string) {
    //@ts-ignore
    const holdingViews: HoldingView[] = await this.dispatch(
      HoldingThunks.getUserHoldingView({ token: accessToken })
    );
    // sorts the first position with the current holding view
    return holdingViews.sort((a: HoldingView, b: HoldingView) => {
      const aId = getDecodedId(a.id)?.split?.(":")?.[1];
      const bId = getDecodedId(b.id)?.split?.(":")?.[1];
      return aId === holdingId ? -1 : bId === holdingId ? 1 : 0;
    });
  }
}
