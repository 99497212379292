import { Warning } from "@assets";
import React, { memo } from "react";
import { I18n } from "react-redux-i18n";
import {
  ErrorContainer,
  LowBalanceDescription,
  ProductDescription,
  Row,
  SmallDescription,
} from "./Cart.style";
import { CartSummaryAdmissionAndPrice } from "@foodi/core";
interface ICartError {
  checkEdenredFunds: boolean;
  edenredBalance: number;
  quotation: CartSummaryAdmissionAndPrice | undefined;
  quotationError: any;
  checkBadgeFunds: boolean | undefined;
  checkRechargeBadgeLater: boolean | undefined;
  noBalanceError: boolean;
}
export const CartErrors = memo(
  ({
    checkEdenredFunds,
    edenredBalance,
    quotation,
    quotationError,
    checkBadgeFunds,
    checkRechargeBadgeLater,
    noBalanceError,
  }: ICartError) => {
    return (
      <>
        {checkEdenredFunds && (
          <Row>
            <LowBalanceDescription>
              {I18n.t("restaurantDetail.cart.insufficientBalance")}
            </LowBalanceDescription>
            <LowBalanceDescription>{edenredBalance}€</LowBalanceDescription>
          </Row>
        )}
        {!quotation && (
          <ProductDescription fullWidth>
            {I18n.t("restaurantDetail.cart.calculateAdmissionSubsidy")}
          </ProductDescription>
        )}
        {quotationError && (
          <ErrorContainer>
            <Warning />
            <SmallDescription hasMargin>
              {I18n.t("restaurantDetail.cart.quotationError")}
            </SmallDescription>
          </ErrorContainer>
        )}
        {(checkBadgeFunds || noBalanceError) && (
          <ErrorContainer>
            <Warning />
            <SmallDescription hasMargin>
              {I18n.t("restaurantDetail.cart.insufficientBadgeFunds")}
            </SmallDescription>
          </ErrorContainer>
        )}
        {!checkBadgeFunds && checkRechargeBadgeLater && (
          <ErrorContainer>
            <Warning />
            <SmallDescription hasMargin>
              {I18n.t("restaurantDetail.cart.rechargeBadgeLater")}
            </SmallDescription>
          </ErrorContainer>
        )}
      </>
    );
  }
);
