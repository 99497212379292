import _ from "lodash";

export interface IValidationPassRules {
  passCharacterRules: boolean;
  passUpperCaseRules: boolean;
  passLowerCaseRules: boolean;
  passNumberRules: boolean;
  hasErrors: boolean;
}

export const PASSWORD_RULES = {
  characters: 8,
  uppercase: 1,
  lowercase: 1,
  number: 1,
};

export class Step1ViewModel {
  public static validationPassRules: IValidationPassRules = 
    { 
      passCharacterRules: false,
      passUpperCaseRules: false,
      passLowerCaseRules: false, 
      passNumberRules: false,
      hasErrors: false,
   };

  public static updateValidationPassRulesData(
    _rulesData: IValidationPassRules,
    password: string,
  ): IValidationPassRules {

    const updatedRules = _rulesData;
    updatedRules.hasErrors = false;

    if (password.length >= PASSWORD_RULES.characters) {
      updatedRules.passCharacterRules = true;
    } else {
      updatedRules.passCharacterRules = false;
      updatedRules.hasErrors = true;
    };

    if ((password.match(/[A-Z]/g) || []).length >= PASSWORD_RULES.uppercase) {
      updatedRules.passUpperCaseRules = true;
    } else{
      updatedRules.passUpperCaseRules = false;
      updatedRules.hasErrors = true;
    };

    if ((password.match(/[a-z]/g) || []).length >= PASSWORD_RULES.lowercase){
      updatedRules.passLowerCaseRules = true;
    } else{
      updatedRules.passLowerCaseRules = false;
      updatedRules.hasErrors = true;
    };

    if ((password.match(/[0-9]/g) || []).length >= PASSWORD_RULES.number){
      updatedRules.passNumberRules = true;
    } else{
      updatedRules.passNumberRules = false;
      updatedRules.hasErrors = true;
    };

    return updatedRules;
  }

  public static validateEmail(email: string): boolean {
    const regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = regExp.exec(email.toLowerCase().trim());

    return !!result && result[0] !== null && email !=="";
  }
}
