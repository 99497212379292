import {
  AccountValidationInfo,
  CompleteProfile,
  DefaultBanner,
  ExternalServicesHome,
  GetApp,
  GetAppMobile,
  LoginInfo,
  News,
  OnScreenCheck,
  ScreenWithFooter,
  WelcomingSentence,
} from "@atomic";
import { RestaurantCardFilters } from "@atomic";
import {
  ILanguage,
  INode,
  IPos,
  MarketingCard,
  OfferTemplateType,
  OfferTemplateWithdrawalType,
} from "@foodi/core";
import {
  AccountThunks,
  MarketingCardThunks,
  AuthThunks,
  AuthActions,
  PosType,
  PointOfSaleThunks,
  HoldingThunks,
  HomeScreens,
  AccountScreens,
  PointOfSaleActions,
} from "@modules";
import { NavigationProp } from "@react-navigation/native";
import { useDevices, useHideZenDesk, useWindowSize } from "@hooks";
import {
  BrandThemeThunks,
  ForceInfoAppUpdateActions,
  GetAppMobileActions,
  GlobalState,
  HeaderActions,
  LoaderActions,
} from "@redux";
import {
  getCloudImageUrl,
  getExpirationTokenInfoFromExpireIn,
  isAccountActivated,
  hasAccessTokenExpired,
} from "@utils";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { View, Image, StyleSheet, PixelRatio, Dimensions } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { I18n, setLocale } from "react-redux-i18n";
import _ from "lodash";
import { getApolloClient } from "../../../../apollo";

interface IProps {
  navigation: NavigationProp<any>;
}

interface IButtonProps {
  label: string;
  onPress: () => void;
  testID?: string;
}

export const HomeScreen: React.FC<IProps> = ({ navigation }) => {
  const dispatch = useDispatch();
  const [isMobile] = useDevices();
  const [currentHeight] = useWindowSize();

  const styles = useMemo(() => _styles(isMobile, currentHeight), [
    isMobile,
    currentHeight,
  ]);
  const [pointsOfSale, setPointsOfSale] = useState<INode<IPos>[] | undefined>(
    undefined
  );
  const [newMarketingCards, setNewMarketingCards] = useState<MarketingCard[]>();
  const { showGetAppMobile } = useSelector(
    (state: GlobalState) => state.getAppMobile
  );

  // selectors
  const { heroBackImage } = useSelector(
    (state: GlobalState) => state.brandTheme.brandTheme
  );
  const userInfo = useSelector((state: GlobalState) => state.auth?.userInfo);
  const isLogin = useSelector((state: GlobalState) => state.auth?.isLogin);
  const forceInfoAppUpdate = useSelector(
    (state: GlobalState) => state.forceInfoAppUpdate.forceUpdate
  );
  const accessToken = useSelector(
    (state: GlobalState) => state.auth?.authToken?.accessToken
  );
  const { holdingName } =
    useSelector((state: GlobalState) => state?.auth?.userInfo) ?? {};

  const mercanetStatus = useSelector(
    (state: GlobalState) => state.transactions?.mercanetStatus
  );
  const externalsServices =
    useSelector(
      (state: GlobalState) => state.marketingCard.externalsServices
    ) || [];

  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language || state.i18n?.locale
  );

  const isAccountValidated = !!isAccountActivated(accessToken);
  const hasTokenExpired = hasAccessTokenExpired(accessToken);

  const getBrandThemeDebounce = useCallback(_.debounce(getBrandTheme, 100), []);

  const isExternalLinksActive =
    !!externalsServices?.length &&
    !externalsServices.every((service) => !service.active || !service.content);

  useEffect(() => {
    if (!isLogin || isAccountValidated || hasTokenExpired) return;
    getHomeData();
  }, [isLogin, hasTokenExpired]);

  useEffect(() => {
    if (hasTokenExpired) return;
    if (isLogin && forceInfoAppUpdate) {
      getHomeData();
    }
  }, [forceInfoAppUpdate, hasTokenExpired]);

  useHideZenDesk({ condition: isMobile && showGetAppMobile });

  const getHomeData = async () => {
    try {
      dispatch(LoaderActions.setLoading(true));
      await Promise.all([getUserInfo(), handlePointsOfSale()]);
    } catch (e) {
      dispatch(LoaderActions.setLoading(false));
    } finally {
      dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(false));
      dispatch(PointOfSaleActions.setFullCartStatus(false));
      dispatch(PointOfSaleActions.setMiniCartStatus(true));
    }
  };

  useEffect(() => {
    if (isAccountValidated || hasTokenExpired) return;
    if (userInfo?.id && isLogin && accessToken) {
      if (!mercanetStatus) dispatch(LoaderActions.setLoading(true));
      handleBalance(userInfo.id);
      handleBrand(userInfo.id);
      dispatch(setLocale(userInfo.language ?? ILanguage.fr));
    } else {
      dispatch(setLocale(userLanguage));
    }
    userInfo && getBrandThemeDebounce(userInfo.brandName ?? "");
    dispatch(LoaderActions.setLoading(false));
  }, [userInfo, userLanguage, hasTokenExpired]);

  /* istanbul ignore next */
  React.useEffect(() => {
    if (isLogin) return;
    const unsubscribe = navigation.addListener("focus", () => {
      dispatch(
        AuthThunks.retrieveTokens(async (authToken: any) => {
          dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(true));
          if (authToken.state === "sso") {
            navigation?.navigate(HomeScreens.ACCOUNT_STACK, {
              screen: AccountScreens.STEP_TWO_SCREEN,
              params: {
                accessToken: authToken.accessToken,
              },
            });
            return;
          }

          const expirationTokenInfo = getExpirationTokenInfoFromExpireIn(
            authToken.expiresIn
          );
          dispatch(
            AuthActions.setAuthToken({
              accessToken: authToken.accessToken ?? "",
              refreshToken: authToken.refreshToken ?? "",
              expiresIn: Number(authToken.expiresIn),
              ...expirationTokenInfo,
            })
          );
          if (!isAccountActivated(authToken.accessToken ?? "")) {
            dispatch(LoaderActions.setLoading(true));
            const userExist = await getUserInfo();
            if (userExist !== undefined) {
              dispatch(AuthActions.setLoginStatus(true));
              dispatch(LoaderActions.setLoading(false));
              dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(false));
            } else {
              navigation?.navigate(HomeScreens.ACCOUNT_STACK, {
                screen: AccountScreens.STEP_ONE_SCREEN,
                params: {
                  accessToken: authToken.accessToken,
                },
              });
              dispatch(LoaderActions.setLoading(false));
              dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(false));
              return;
            }
          }
          dispatch(GetAppMobileActions.setActive(true));
          dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(false));
        })
      );
    });

    return unsubscribe;
  }, [navigation]);

  const getPointsOfSale = (type: string) => {
    return dispatch(
      PointOfSaleThunks.getPointsOfSale({
        type,
        offerTemplateType: `${OfferTemplateType.CLICK_COLLECT},${OfferTemplateType.TABLE_SERVICE},${OfferTemplateType.BOOKING_SERVICE}`,
        offerTemplateWithdrawalType: `${OfferTemplateWithdrawalType.CONNECTED_LOCKERS},${OfferTemplateWithdrawalType.POS_AT_SITE},${OfferTemplateWithdrawalType.POS_TAKE_AWAY},${OfferTemplateWithdrawalType.TABLE_SERVICE},${OfferTemplateWithdrawalType.POS_CLICK_SERVE},${OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT},${OfferTemplateWithdrawalType.CLICK_AND_PICK_UP}`,
      })
    );
  };

  const handlePointsOfSale = async () => {
    if (!mercanetStatus) {
      dispatch(LoaderActions.setLoading(true));
    }

    const result = await getPointsOfSale(`${PosType.SELF},${PosType.CAFETERIA},${PosType.BRASSERIE},${PosType.OTHER}`);

    dispatch(LoaderActions.setLoading(false));

    const pointsOfSaleList: INode<IPos>[] = (result as any)?.data;

    if (!!pointsOfSaleList?.length) {
      setPointsOfSale(pointsOfSaleList);
    }
  };

  const handleBalance = (id: string) => {
    dispatch(
      AccountThunks.getBalance({
        id,
      })
    );
  };
  const getUserInfo = async () => {
    try {
      const res = await dispatch(AuthThunks.getUserInfo());

      //@ts-ignore
      if (res?.hardLinked) {
        const res = await dispatch(HoldingThunks.migrateToHoldingView());

        //@ts-ignore
        if (res) {
          await getApolloClient().cache.reset();

          //@ts-ignore
          navigation?.push(HomeScreens.HOME_SCREEN);
        }
      }
      return res;
    } catch (e) {}
  };

  function getBrandTheme(holding_name: string) {
    dispatch(
      BrandThemeThunks.getBrandTheme({
        application_id: "foodiweb",
        holding_name: holding_name.toLowerCase(),
      })
    );
  }

  const handleBrand = (id: string) => {
    dispatch(
      MarketingCardThunks.getBrand({
        id,
      })
      //@ts-ignore
    ).then((result) => {
      handleNewMarketingCards(result?.id);
    });
  };

  const handleNewMarketingCards = (idHolding: string) => {
    if (idHolding !== undefined) {
      dispatch(
        MarketingCardThunks.getPublishedMarketingCardsNew({
          idHolding,
          type: "ARTICLE,IMPORTANT_MESSAGE",
        })
        //@ts-ignore
      ).then((res) => {
        setNewMarketingCards(res.marketingCardsNew);
      });
    }
  };

  const heroBackImageUri = getCloudImageUrl(
    heroBackImage,
    "width",
    String(PixelRatio.getPixelSizeForLayoutSize(Dimensions.get("window").width))
  );

  const handleGetAppMobile = () => {
    dispatch(GetAppMobileActions.setActive(false));
  };

  const HomePageContent: React.FC = () => (
    <>
      {isAccountValidated ? (
        <AccountValidationInfo />
      ) : (
        <>
          {!isLogin || (isLogin && !holdingName) ? (
            <DefaultBanner
              isLogin={isLogin}
              isMobile={isMobile}
              username={_.capitalize(userInfo?.firstName?.toLowerCase())}
            />
          ) : (
            <Image
              style={styles.image}
              source={{
                uri: heroBackImageUri,
                cache: "only-if-cached",
              }}
            />
          )}
          <OnScreenCheck
            handleIsVisible={(value) =>
              dispatch(HeaderActions.setHeaderOpacity(!value))
            }
          />
          {isLogin &&
            (holdingName ? (
              <WelcomingSentence
                firstLabel={I18n.t("homepage.welcomingSentence.greeting")}
                isLogged={isLogin}
                userName={_.capitalize(userInfo?.firstName?.toLowerCase())}
                secondLabel={I18n.t("homepage.welcomingSentence.desire")}
                fallbackLabel={I18n.t("homepage.welcomingSentence.welcome")}
              />
            ) : (
              <CompleteProfile
                subtitle={I18n.t(
                  "homepage.welcomingSentence.profileIncompleteDesc"
                )}
                navigation={navigation}
              />
            ))}
          {!isLogin && <LoginInfo navigation={navigation} />}
          {isLogin && pointsOfSale && (
            <RestaurantCardFilters
              restaurantCardsList={pointsOfSale}
              navigation={navigation}
            />
          )}
          {isLogin && isMobile && isExternalLinksActive && (
            <ExternalServicesHome
              services={externalsServices}
              isMobile={isMobile}
            />
          )}
          {isLogin && !!newMarketingCards?.length && (
            <News news={newMarketingCards} navigation={navigation} />
          )}
          {!isMobile && <GetApp />}
        </>
      )}
    </>
  );

  return (
    <View style={styles.mainContainer}>
      <View style={{ flex: 1 }}>
        <ScreenWithFooter navigation={navigation}>
          <View style={styles.container}>
            <HomePageContent />
          </View>
        </ScreenWithFooter>
      </View>
      {isMobile && showGetAppMobile && (
        <GetAppMobile closeGetApp={handleGetAppMobile} />
      )}
    </View>
  );
};

const _styles = (isMobile: boolean, currentHeight?: number) =>
  StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: "white",
    },
    image: {
      width: "100%",
      height: isMobile ? 215 : 366,
    },
    mainContainer: {
      height: `${currentHeight}px`,
    },
  });
