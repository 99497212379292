import { Colors } from "@constants";
import style from "styled-components/native";
import { JEST } from "@utils";

export const Container = style.View<{
  isMobile: boolean;
  isNotSticky?: boolean;
  handleOpacity?: boolean;

}>`
    position: ${({ isNotSticky, handleOpacity }) =>
      /* istanbul ignore next */
      JEST || isNotSticky ? "relative" : "fixed"};
    z-index: 1;
    width: 100%;
    height: 60px;
    background-color: ${({ handleOpacity }) =>
      handleOpacity ? Colors.foodiBlack : Colors.foodiBlackOpacity(0.67)};
    padding: 20px ${({ isMobile }) => (isMobile ? "18" : "150")}px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const RightSide = style.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const BalanceContainer = style.Pressable<{ isMobile: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: ${({ isMobile }) => (isMobile ? "20" : "30")}px;
    min-width: 100px;
`;

export const Amount = style.Text<{ isMobile: boolean, isHover: boolean }>`
    font-size: ${({ isMobile }) => (isMobile ? "16" : "18")}px;
    color: ${Colors.white};
    margin-left: 5px;
    font-family: "manrope";
    font-weight: ${({ isHover }) => (isHover ? "bolder" : "normal")};
`;

export const ConnectionContainer = style.Pressable<{ isMobile: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: ${({ isMobile }) => (isMobile ? "20" : "30")}px;
`;

export const UserName = style.Text<{ isHover: boolean }>`
    font-size: 18px;
    color: ${Colors.white};
    margin-left: 5px;
    font-family: "manrope";
    font-weight: ${({ isHover }) => (isHover ? "bolder" : "normal")};
`;

export const LogoIcon = style.Image<{ isMobile: boolean }>`
    height:  ${({ isMobile }) => (isMobile ? "30" : "40")}px;
    width:  ${({ isMobile }) => (isMobile ? "90" : "140")}px;
    margin-top: 8px
`;
