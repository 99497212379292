import { IOptinCode, IUserOptin } from "@foodi/core";
import _ from "lodash";

export class OptinsViewModel {
  static initOptins(
    allOptins: { [key in IOptinCode]: boolean },
    selectedOptins: IUserOptin[]
  ) {
    const _allOptins = _.cloneDeep(allOptins);
    selectedOptins.forEach((optin) => (_allOptins[optin.code] = true));
    _allOptins.FOODI_TOS = true;
    return _allOptins;
  }

  static getSelectedOptins(
    allOptins: { [key in IOptinCode]: boolean }
  ): IOptinCode[] {
    return Object.entries<any>(allOptins)
      .filter(([code, value]) => value)
      .map(([code, value]: any) => code)
      .flat();
  }
}
