import React, { useState } from "react";
import {
  Row,
  OrderText,
  DescriptionText,
  BlockContainer,
  BlockTitle,
  ProductDescription,
  ProductDescriptionContainer,
  Title,
  ContainerDescription,
  IconContainer,
  CancelContainer,
  Action,
  ConsignContainer,
  ModalContainer,
  GoBackText,
} from "./ConfirmationPage.style";
import { ActivityIndicator, StyleSheet } from "react-native";
import { QuotationSummary } from "@atomic/organisms/Cart/QuotationSummary";
import { Receipt, Title16, Title13 } from "@stylesheets";
import {
  OrderViewModel,
  POSScreens,
  HomeScreens,
  PointOfSaleActions,
  ConfirmationPageActions,
  ComingSoonViewModel,
  cancelableOrderStates,
} from "@modules";
import {
  IPaymentTypes,
  OfferTemplateWithdrawalType as WithdrawalType,
  OrderItem,
  OrderState,
  PaymentMethod,
} from "@foodi/core";
import { Colors, Spacing } from "@constants";
import { getLabelFromState, getDecodedId, getLabelArticleBaking } from "@utils";
import { useDispatch, useSelector } from "react-redux";
import { NavigationProp } from "@react-navigation/native";
import { GlobalState, ModalActions } from "@redux";
import { I18n } from "react-redux-i18n";
import { CloseClickOutsideTemplate, ModalTemplate, Tag } from "@atomic";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import {
  Column,
  WarningMessage,
  WarningMessageContainer,
} from "@atomic/organisms/Cart/Cart.style";
import { Information, VisibilityBlack, WarningOrange } from "@assets";

interface IProps {
  order: any;
  date: string;
  cancelDate: string;
  navigation?: NavigationProp<any>;
  idPos: number;
}

export const ClickAndCollectContent: React.FC<IProps> = React.memo(
  ({ order, date, cancelDate, navigation, idPos }) => {
    const dispatch = useDispatch();
    const orderVM = new OrderViewModel(dispatch, "");
    const comingSoonViewModel = new ComingSoonViewModel();

    const [loading, setLoading] = useState<boolean>(false);
    const [receipt] = useState<string | null>(null);
    const [hasContainer, setHasContainer] = useState<boolean>(false);

    const currentDate = Date.now();
    const dateCancelDate = new Date(order?.cancelableUntil).valueOf();
    const canceledState = order?.state === OrderState.CANCELLED;
    const refusedState = order?.state === OrderState.REFUSED;
    const isReadyState = order?.state === OrderState.READY;
    const withdrawalType: WithdrawalType | undefined =
      order?.offer?.offerTemplate?.withdrawalType;
    const isSeatClick = withdrawalType === WithdrawalType.POS_CLICK_SERVE;
    const isInstantCC = withdrawalType === WithdrawalType.INSTANT_CLICK_COLLECT;
    const isTableService = withdrawalType === WithdrawalType.TABLE_SERVICE;
    const isClickPickup = withdrawalType === WithdrawalType.CLICK_AND_PICK_UP;
    const isCancelableState = cancelableOrderStates.includes(order?.state);
    const isInAdvanceOrder = comingSoonViewModel.isInAdvanceOrder(order);
    const isInAdvanceEdenredOrder =
      order?.paymentMethod === PaymentMethod.Edenred && isInAdvanceOrder;

    const idCashSystem = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.idCashSystem
    );

    const { isSchool } = useSelector(
      (state: GlobalState) => state.auth?.userInfo || {}
    );

    let canCancel =
      isCancelableState &&
      !(isTableService && idCashSystem === "1") &&
      (isSeatClick ||
        isInstantCC ||
        isTableService ||
        (dateCancelDate > currentDate && order?.cancelableUntil !== null)) &&
      !isInAdvanceEdenredOrder;

    if (isClickPickup && (dateCancelDate > currentDate && order?.cancelableUntil !== null) && !isInAdvanceEdenredOrder) {
      canCancel = order?.offer?.published
    }

    const tableNumber: number | undefined = order?.tableNumber;

    const [isMobile] = useDevices();

    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );

    const id = getDecodedId(order?.id);

    const handleReceipt = () => {
      // TODO: complete when we have the link between an order and a transaction, transactionVM.handleReceipt(receipt, setReceipt, dispatch, id);
    };
    const handleCancel = () => {
      dispatch(ModalActions.setOpen(true));
    };

    const handleCloseModal = () => {
      dispatch(ModalActions.setOpen(false));
    };

    const handleCancelOrder = async () => {
      if (order && canCancel) {
        setLoading(true);

        try {
          const res = await orderVM.cancelOrder({
            idOrder: order?.id,
            newState: OrderState.CANCELLED,
            paymentMethod: order?.paymentMethod,
          });

          if (res) {
            setLoading(false);
          }
        } catch (err) {}

        dispatch(PointOfSaleActions.setNewOrder(true));
        dispatch(ModalActions.setOpen(false));

        dispatch(
          ConfirmationPageActions.setConfirmationPageParams({
            id: id?.split(":")?.[1],
            orderId: order?.id,
            isFirstTime: false,
            idPos: -1,
            offerSlot: null,
          })
        );

        navigation?.navigate(HomeScreens.POINT_OF_SALE_STACK, {
          screen: POSScreens.CONFIRMATION_PAGE,
        });
      }
    };

    const customOrder =
      order?.paymentMethod === IPaymentTypes.OnSite
        ? {
            admission: order?.admission,
            subsidies: order?.subsidies,
            fee: order?.subsidies,
          }
        : order;

    const infoPayOnSite = isSchool
      ? I18n.t("restaurantDetail.cart.payOnSiteSchoolInfo")
      : I18n.t("restaurantDetail.cart.payOnSiteInfo");

    const orderId =
      withdrawalType === WithdrawalType.CONNECTED_LOCKERS
        ? id?.split(":")?.[1]
        : order?.idWithdrawal;

    return (
      <>
        <Row testID={TestIDs.home.confirmationPage.views.CCContainer}>
          {canceledState && (
            <Title
              testID={TestIDs.home.confirmationPage.views.titleCanceled}
              isMobile={isMobile}
            >
              {I18n.t("confirmationPage.yourOrderCanceled")}
            </Title>
          )}
        </Row>
        <Row>
          <OrderText isMobile={isMobile}>{`${I18n.t(
            "confirmationPage.order"
          )} #${orderId}  `}</OrderText>
          <OrderText
            bold
            canceled={canceledState || refusedState}
            isMobile={isMobile}
          >
            {I18n.t(`homepage.comingSoon.${getLabelFromState(order?.state)}`)}
          </OrderText>
        </Row>
        {isInstantCC && (
          <Row maxWidth={800} isMobile={isMobile}>
            <DescriptionText isMobile={isMobile}>
              {I18n.t("confirmationPage.instantCCInfo")}
            </DescriptionText>
          </Row>
        )}
        {!canceledState &&
          (isSeatClick || isTableService ? (
            <>
              {isReadyState && (
                <DescriptionText isMobile={isMobile}>
                  {I18n.t("confirmationPage.dishServed")}
                </DescriptionText>
              )}
              <Row>
                <DescriptionText isMobile={isMobile}>
                  {I18n.t("confirmationPage.tableNumber")} :{" "}
                </DescriptionText>
                <DescriptionText bold isMobile={isMobile}>
                  {tableNumber}
                </DescriptionText>
              </Row>
            </>
          ) : (
            <>
              <DescriptionText isMobile={isMobile}>
                {I18n.t("confirmationPage.orderInformation")}
              </DescriptionText>
              <Row>
                <DescriptionText isMobile={isMobile}>
                  {I18n.t("confirmationPage.withdrawalWindow")} :{" "}
                </DescriptionText>
                <DescriptionText bold isMobile={isMobile}>
                  {isClickPickup
                    ? `${date + " - " + order?.pickupPoint?.withdrawalSchedule}`
                    : date}
                </DescriptionText>
              </Row>
              <Row>
                <DescriptionText isMobile={isMobile}>
                  {I18n.t("confirmationPage.pickupLocation")} :{" "}
                </DescriptionText>
                <DescriptionText bold isMobile={isMobile}>
                  {isClickPickup
                    ? `${order?.pickupPoint?.name} ${
                        order?.pickupPoint?.description
                          ? "- " + order?.pickupPoint?.description
                          : ""
                      }`
                    : order?.withdrawLocation}
                </DescriptionText>
              </Row>
            </>
          ))}
        <BlockContainer isMobile={isMobile}>
          <BlockTitle>{I18n.t("confirmationPage.yourOrder")}</BlockTitle>
          {order?.orderItems?.map((p: OrderItem) => {
            if (!hasContainer && p.containerLabelWhenAdded) {
              setHasContainer(true);
            }

            return (
              <>
                <Row key={p.id}>
                  <ProductDescriptionContainer>
                    <ProductDescription
                      marginBottom={p.containerLabelWhenAdded ? 3 : 12}
                      marginRight={5}
                    >
                      {p.quantity}x
                    </ProductDescription>
                    <Column>
                      <ProductDescription>
                        {p.labelWhenAdded}
                      </ProductDescription>
                      {p.containerLabelWhenAdded && (
                        <ContainerDescription
                          isMobile={isMobile}
                          numberOfLines={2}
                        >
                          {I18n.t("restaurantDetail.cart.container")}{" "}
                          {p.containerLabelWhenAdded}
                        </ContainerDescription>
                      )}
                    </Column>
                  </ProductDescriptionContainer>
                  <Column>
                    <ProductDescription
                      marginBottom={p.containerLabelWhenAdded ? 3 : 12}
                      alignSelf="flex-end"
                    >
                      {p.priceWhenAdded?.amount?.replace(".", ",")}€
                    </ProductDescription>
                    {p.containerPriceWhenAdded && (
                      <ProductDescription alignSelf="flex-end">
                        {p.containerPriceWhenAdded?.amount?.replace(".", ",")}€
                      </ProductDescription>
                    )}
                  </Column>
                </Row>
                {!!p.chosenBaking && (
                  <Row>
                    <ProductDescription>
                      <Tag
                        isSelected
                        text={I18n.t(
                          `restaurantDetail.articleBaking.${getLabelArticleBaking(
                            p.chosenBaking
                          )}`
                        )}
                      />
                    </ProductDescription>
                  </Row>
                )}
              </>
            );
          })}
          {order && (
            <QuotationSummary
              paymentMethod={order?.paymentMethod}
              quotation={customOrder}
              isSchool={order?.isSchool}
            />
          )}
          <Row>
            <ProductDescription bold hasNoMaxWidth>
              {I18n.t("restaurantDetail.cart.total")}
              {order?.paymentMethod === IPaymentTypes.OnSite &&
                ` - ${I18n.t("confirmationPage.paymentOnSite")}`}
            </ProductDescription>
            <ProductDescription bold>
              {order?.totalPrice?.amount?.replace(".", ",")}€
            </ProductDescription>
          </Row>
          {(order?.paymentMethod === IPaymentTypes.OnSite ||
            order?.paymentMethod === IPaymentTypes.Edenred) && (
            <WarningMessageContainer marginTop={0}>
              <WarningOrange />
              <WarningMessage>
                {order?.paymentMethod === IPaymentTypes.OnSite
                  ? infoPayOnSite
                  : I18n.t("restaurantDetail.cart.noSubventions")}
              </WarningMessage>
            </WarningMessageContainer>
          )}
          {isInAdvanceOrder && (
            <Row>
              <Information style={{ marginTop: 2 }} width={"40px"} />
              <ProductDescription hasNoMaxWidth marginLeft={8}>
                {I18n.t("confirmationPage.orderInAdvanceMessage")}
              </ProductDescription>
            </Row>
          )}

          {/* DO NOT delete this commented code, this will be needed when we have a direct conexion between orders and transaction receipts */}
          {/* <PressableRow onPress={handleReceipt}>
            <ProductDescription isLink bold underline>
              {I18n.t("confirmationPage.yourReceipt")}
            </ProductDescription>
            <IconContainer>
              <VisibilityBlack />
            </IconContainer>
          </PressableRow> */}
        </BlockContainer>
        {order?.comment && order.comment.length > 0 && (
          <BlockContainer
            isMobile={isMobile}
            testID={TestIDs.home.confirmationPage.views.commentContainer}
          >
            <BlockTitle>{I18n.t("confirmationPage.comment")}</BlockTitle>
            <Title16 style={styles.comment}>{order.comment}</Title16>
            <Title16 bold color={Colors.gray1}>
              {I18n.t("confirmationPage.commentNotice")}
            </Title16>
          </BlockContainer>
        )}
        {canCancel && (
          <CancelContainer
            testID={TestIDs.home.confirmationPage.views.cancelContainer}
            isMobile={isMobile}
          >
            {isSeatClick || isInstantCC || isTableService ? (
              <Title16
                testID={TestIDs.home.confirmationPage.views.cancelSCSOrderText}
              >
                {I18n.t("confirmationPage.cancelSCSOrder")}
              </Title16>
            ) : (
              <>
                {isClickPickup ? (
                  <Title16>
                    {I18n.t("confirmationPage.cancelClickAndPickup")}
                  </Title16>
                ) : (
                  <>
                    <Title16>
                      {I18n.t("confirmationPage.cancelOrderInfo")}
                    </Title16>
                    <Title16>{cancelDate}</Title16>
                  </>
                )} 
              </>
            )}

            <Action
              testID={TestIDs.home.confirmationPage.actions.clickCancellation}
              hasBorder
              marginTop={10}
              onPress={handleCancel}
            >
              <BlockTitle>{I18n.t("confirmationPage.cancel")}</BlockTitle>
            </Action>
          </CancelContainer>
        )}
        {hasContainer && (
          <ConsignContainer>
            <Title13 color={Colors.foodiBlack}>
              {I18n.t("restaurantDetail.container.infoMessage")}
            </Title13>
          </ConsignContainer>
        )}
        {receipt && (
          <ModalTemplate>
            <CloseClickOutsideTemplate handleClose={handleCloseModal}>
              <Receipt dangerouslySetInnerHTML={{ __html: receipt }} />
            </CloseClickOutsideTemplate>
          </ModalTemplate>
        )}
        <ModalTemplate
          forwardTestID={
            TestIDs.home.confirmationPage.views.confirmCancellation
          }
          forwardCloseTestID={
            TestIDs.home.confirmationPage.actions.backCancellation
          }
          style={{ width: 414 }}
          closeXButtonStyle={styles.closeXButtonStyle}
        >
          <ModalContainer>
            <CloseClickOutsideTemplate handleClose={handleCloseModal}>
              <Row>
                <IconContainer>
                  <WarningOrange />
                </IconContainer>
                <GoBackText>
                  {I18n.t("confirmationPage.cancelConfirmation")}
                </GoBackText>
              </Row>
              <Action
                testID={TestIDs.home.confirmationPage.actions.cancelOrder}
                marginTop={34}
                onPress={handleCancelOrder}
                justifyContent={loading}
                backgroundColor={
                  brandTheme.buttonActionColors?.backgroundColor ||
                  Colors.foodiDefault
                }
              >
                {loading ? (
                  <>
                    <ActivityIndicator
                      style={styles.loadingStyle}
                      color={
                        brandTheme.buttonActionColors?.textColor ||
                        Colors.foodiBlack
                      }
                    />
                    <BlockTitle hide>
                      {I18n.t("confirmationPage.cancelOrder")}
                    </BlockTitle>
                  </>
                ) : (
                  <BlockTitle
                    color={
                      brandTheme.buttonActionColors?.textColor ||
                      Colors.foodiBlack
                    }
                  >
                    {I18n.t("confirmationPage.cancelOrder")}
                  </BlockTitle>
                )}
              </Action>
            </CloseClickOutsideTemplate>
          </ModalContainer>
        </ModalTemplate>
      </>
    );
  }
);

const styles = StyleSheet.create({
  loadingStyle: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  closeXButtonStyle: {
    top: -15,
    right: -5,
  },
  comment: {
    marginVertical: Spacing.XS,
  },
});
