export const allergensMapper = {
  CELERY: 'Celery',
  EGG: 'Egg',
  FISH: 'Fish',
  GLUTEN: 'Wheat',
  LUPIN: 'Lupin',
  MILK: 'Milk',
  MOLLUSC: 'Mollusc',
  MUSTARD: 'Mustrad',
  NUT: 'Nut',
  PEANUT: 'Peanut',
  SESAME: 'Sesame',
  SHELLFISH: 'Sellfish',
  SOYA: 'Soya',
  SULFITE: 'Sulfite',
  ALLERGEN_FREE: 'allergenFree',
  INFO_INCOMPLETE: 'infoIncomplete',
  INFO_NOT_DISCLOSED: 'infoNotDisclosed'
};
