import React, { useMemo, useState } from "react";
import { formatAmount, IAmount, IPosType } from "@foodi/core";
import { Pressable, StyleSheet, View, ViewStyle } from "react-native";
import { Receipt, Title13, Title16 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import moment from "moment";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import { VisibilityBlack } from "@assets";
import { useDevices } from "@hooks";
import {
  BalanceScreens,
  TransactionsViewModel,
  TransactionTypes,
} from "@modules";
import { useDispatch } from "react-redux";
import { CloseClickOutsideTemplate, ModalTemplate } from "@atomic";
import { NavigationProp } from "@react-navigation/native";

interface IProps {
  title: string;
  transactionDate: string;
  transactionType: string;
  type?: IPosType;
  total: IAmount;
  style?: ViewStyle;
  forwardTestID?: string;
  id: string;
  navigation: NavigationProp<any>;
}

export const TransactionRow: React.FC<IProps> = React.memo(
  ({
    forwardTestID,
    title,
    transactionDate,
    total,
    id,
    navigation,
    transactionType,
  }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const [receipt, setReceipt] = useState<string | undefined>("");

    const amount = parseFloat(total?.amount ?? "0");
    const isRecharge = !title;
    let formattedAmount = isRecharge ? "+" : "";
    formattedAmount += `${formatAmount(Math.abs(amount), "€")}`;

    const transactionVM = new TransactionsViewModel();

    const handleReceipt = async () => {
      const result = await transactionVM.getHandleReceipt(
        receipt || "",
        setReceipt,
        dispatch,
        id
      );
      if (_.isEmpty(result))
        navigation.navigate(BalanceScreens.ERROR_RECEIPT, {
          errorType: "errorHandler.errorReceipt",
        });
    };

    const handleClose = () => {
      setReceipt(undefined);
    };

    return (
      <>
        <Pressable
          testID={forwardTestID}
          style={styles.container}
          onPress={handleReceipt}
          disabled={!title}
        >
          <View>
            <View style={styles.rowStyle}>
              <View style={styles.infoDiv}>
                <Title16
                  style={styles.title}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}
                >
                  {transactionType === TransactionTypes.ORDER
                    ? title
                    : _.upperFirst(I18n.t("refill.recharge"))}
                </Title16>
                <View style={styles.rowStyle2}>
                  <Title13 style={styles.date}>
                    {_.upperFirst(
                      moment(new Date(transactionDate)).format(
                        "DD/MM/yyyy  HH:mm"
                      )
                    )}
                  </Title13>
                  <VisibilityBlack />
                </View>
              </View>
            </View>
          </View>
          <Title16 style={[styles.title, isRecharge && [styles.recharge]]}>
            {formattedAmount}
          </Title16>
        </Pressable>
        <ModalTemplate
          isOpen={!!receipt}
          handleClose={handleClose}
          style={styles.receiptDiv}
        >
          <CloseClickOutsideTemplate handleClose={handleClose}>
            {receipt && (
              <Receipt dangerouslySetInnerHTML={{ __html: receipt }} />
            )}
          </CloseClickOutsideTemplate>
        </ModalTemplate>
      </>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      marginTop: isMobile ? Spacing.M : Spacing.L,
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    rowStyle: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    rowStyle2: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    infoDiv: {
      marginLeft: 0,
      justifyContent: "center",
      alignItems: "flex-start",
    },
    title: {
      fontWeight: "600",
    },
    date: {
      color: Colors.foodiBlack,
      marginRight: 10,
    },
    recharge: {
      color: Colors.middleGreen,
    },
    receiptDiv: {
      width: isMobile ? "100%" : 520,
      maxHeight: isMobile ? "100%" : "95vh",
    },
  });
