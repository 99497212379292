import React, { useEffect } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Title16, Title18, Title22, Title36 } from "@stylesheets";
import { AccountCreationTemplate } from "@atomic";
import { NavigationProp, useIsFocused } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { AccountActions, HomeScreens } from "@modules";
import { CheckCircle, CheckCircleMobile } from "@assets";
import { I18n, setLocale } from "react-redux-i18n";
import { Spacing } from "@constants";
import { GlobalState } from "@redux";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";

interface IProps {
  navigation: NavigationProp<any>;
}

export const Step3: React.FC<IProps> = React.memo(({ navigation }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const [isMobile] = useDevices();

  const Title: React.FC<any> = isMobile ? Title22 : Title36;

  const { firstName } =
    useSelector((state: GlobalState) => state.account.userInfoStep1) ?? {};

  useEffect(() => {
    if (isFocused) {
      dispatch(AccountActions.setCurrentStepIndex(3));
    }
  }, [isFocused]);

  const goToHomePage = () => {
    dispatch(AccountActions.setUserInfoStep1(null));
    navigation.navigate(HomeScreens.HOME_SCREEN);
  };

  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language || state.i18n?.locale
  );
  useEffect(() => {
    dispatch(setLocale(userLanguage));
  }, [userLanguage]);

  return (
    <AccountCreationTemplate navigation={navigation}>
      <View style={styles.container}>
        {isMobile ? <CheckCircleMobile /> : <CheckCircle />}

        <>
          <Title isBlack style={styles.welcome}>
            {I18n.t("account.welcome", { firstName })}
          </Title>
          <Title18>{I18n.t("account.emailSent")}</Title18>
          <Title22 isBlack style={styles.seeYouSoon}>
            {I18n.t("account.seeYouSoon")}
          </Title22>
          <TouchableOpacity
            testID={TestIDs.account.actions.goBackHomeScreen}
            onPress={goToHomePage}
          >
            <Title16 style={styles.backHome}>
              {I18n.t("account.backHome")}
            </Title16>
          </TouchableOpacity>
        </>
      </View>
    </AccountCreationTemplate>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  welcome: {
    marginTop: 24,
  },
  seeYouSoon: {
    marginTop: 10,
    fontFamily: "manrope-regular",
    letterSpacing: 0,
  },
  backHome: {
    marginTop: 50,
    padding: Spacing.M,
    textDecorationLine: "underline",
  },
});
