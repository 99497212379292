import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import {
  BalanceScreen,
  SucceedRefill,
  MercanetLoadingScreen,
  ErrorReceipt,
} from "../screens";
import { BalanceScreens } from "./BalanceScreens";

export type BalanceStackParamList = {
  [BalanceScreens.BALANCE_MAIN_SCREEN]: undefined;
  [BalanceScreens.SUCCEED_REFILL_SCREEN]: undefined;
  [BalanceScreens.MERCANET_LOADING]: undefined;
  [BalanceScreens.ERROR_RECEIPT]: undefined;
};

const Stack = createStackNavigator<BalanceStackParamList>();

export const BalanceNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name={BalanceScreens.BALANCE_MAIN_SCREEN}
        key="balanceScreen"
        component={BalanceScreen}
      />
      <Stack.Screen
        name={BalanceScreens.SUCCEED_REFILL_SCREEN}
        key="succeedRefillScreen"
        component={SucceedRefill}
      />
      <Stack.Screen
        name={BalanceScreens.MERCANET_LOADING}
        key="mercanetLoadingScreen"
        component={MercanetLoadingScreen}
      />
      <Stack.Screen
        name={BalanceScreens.ERROR_RECEIPT}
        key="errorReceiptScreen"
        component={ErrorReceipt}
      />
    </Stack.Navigator>
  );
};
